/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";
import { t } from "i18next";

import SimilarGroups from "../common/SimilarGroups";
import ViewGalleryImageModal from "../Modals/ViewGalleryImageModal";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ActivityAssests/images/blank-champion.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const Gallery = () => {
  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const { isApproved } = useContext(GlobalIndraInfo);

  const glowCount = [1, 2]; // for glow placeholder

  // initials for group details
  const [groupGalleryData, setGroupGalleryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); //for glow placeholder
  const [viewImageUrl, setViewImageUrl] = useState("");

  const getGroupGallery = async () => {
    setIsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GROUP_GALLERY +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          if (response.data.gallery) {
            setGroupGalleryData(response.data.gallery);
          } else {
            setGroupGalleryData([]);
          }
        } else {
          setGroupGalleryData([]);
        }
      }
    } catch (error) {}
  };

  // function view image
  const ViewImageHandler = (gallery) => {
    setViewImageUrl(url.SERVER_URL + gallery.path);
  };

  useEffect(() => {
    if (params.id) {
      getGroupGallery();
    }
  }, [params.id]);

  return (
    <>
      <div className="tab-pane fade show active" id="evnt_gallery">
        {isLoading ? (
          <div className="training_outer">
            <div className="course_detailspnl">
              {/* <div className="contnt_hdng">
                <span
                  className="placeholder rounded-3 col-2"
                  style={{ height: "22px" }}
                ></span>
              </div> */}
              <div className="portfolio_outer gallery_outer">
                {glowCount.map((count) => {
                  return (
                    <div className="mx-1" key={count}>
                      <span
                        className="placeholder d-block rounded-3"
                        style={{ width: "400px", height: "250px" }}
                      ></span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="training_outer">
            {groupGalleryData.length === 0 ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("Photos are not available at this moment.")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                {isApproved ||
                userInfo.role.slug === "ADMIN" ||
                userInfo.role.slug === "SUPER_ADMIN" ? (
                  <div className="col-lg-12">
                    <div className="course_detailspnl">
                      {/* <div className="contnt_hdng tab_Hdng">
                    <h4>Gallery</h4>
                  </div> */}
                      <div className="portfolio_outer gallery_outer group_gallery_outer">
                        {groupGalleryData.map((gallery, index) => {
                          return (
                            <div className="portfolio_gallery_item" key={index}>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#view_group_gallery_image_modal"
                                onClick={() => ViewImageHandler(gallery)}
                              >
                                <img
                                  src={url.SERVER_URL + gallery.path}
                                  alt=""
                                />
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("You don't have privilege")}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <ViewGalleryImageModal viewImageUrl={viewImageUrl} />
    </>
  );
};

export default Gallery;

const ChatLeftPanelPlaceholder = () => {
  return (
    <div className="chat_usrRow placeglow_chatouter placeholder-glow">
      <div className="user_placeHoledr">
        <div className="default_usr">
          <img src="/images/default-user.png" alt="" />
        </div>
        <div className="default_usr_name">
          <span className="placeholder placeholder-sm col-10 rounded-3"></span>
          <span className="placeholder placeholder-sm col-6 rounded-3"></span>
        </div>
      </div>
      <div className="glow_status">
        <span className="placeholder placeholder-sm col-3 rounded-3"></span>
      </div>
    </div>
  );
};

export default ChatLeftPanelPlaceholder;

export const ecosystemSlug = "INDRA";

export const ckEditorConfig = (placeholderText = "") => {
  return {
    toolbar: [
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
    ],
    placeholder: placeholderText,
    shouldNotGroupWhenFull: false,
  };
};

export const reactSelectStyle = (
  padding = "0px",
  borderRadius = "0px",
  bgRequired = false,
  border = "none"
) => {
  return {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      margin: "2px",
      fontFamily: "sans-serif",
      border,
      backgroundColor: "#Fff",
      height: 50,
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };
};

/* eslint-disable */
import { useState, useEffect } from "react";
import { io } from "socket.io-client";

import { getData, putData_v2 } from "utils/Gateway"; // import methods from gatway for API call
import * as url from "helpers/url_helper"; // import all URLs from url_helper

import ConversationLeftPanel from "../leftPanel/ConversationLeftPanel";
import ConversationRightPanel from "../RightPanel/ConversationRightPanel";
import AllContactModal from "../Modals/AllContactModal";
import GroupModal from "../Modals/GroupModal";
import CreateNewGroupModal from "../Modals/CreateNewGroupModal";

import { playAudioTone } from "helpers/Common/CommonHelper";

const ConversationBody = () => {
  const socket = io(url.SERVER_URL);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  const [isChatPanelLoading, setIsChatPanelLoading] = useState(false);
  const [chatList, setChatList] = useState([]);

  const [defaultScreen, setDefaultScreen] = useState("welcome"); // welcome

  const [selectedConversationId, setSelectedConversationId] = useState(null); //it can be user account id or group id
  const [conversationName, setConversationName] = useState("");
  const [selectedChatLogo, setSelectedChatLogo] = useState("");
  const [selectedChatEmail, setselectedChatEmail] = useState("");
  const [selectedChatRoomId, setSelectedChatRoomId] = useState(null);
  const [selectedChatPhone, setSelectedChatPhone] = useState("");
  const [selectedChatAddress, setSelectedChatAddress] = useState("");
  const [selectedChatCompany, setSelectedChatCompany] = useState("");
  const [groupModName, setgroupModName] = useState("");
  const [groupModImage, setgroupModImage] = useState("");
  const [groupMembers, setgroupMembers] = useState([]);
  const [groupFiles, setgroupFiles] = useState([]);
  const [groupModId, setgroupModId] = useState(null);
  const [groupMemberString, setgroupMemberString] = useState("");

  const [reloadChatPanelList, setReloadChatPanelList] = useState(false);
  const [reloadChatRightPanel, setReloadChatRightPanel] = useState(false);

  const [selectedChatContactId, setselectedChatContactId] = useState(null); //state for remove require response and update require response
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [isClosingResponse, setisClosingResponse] = useState(false);

  const [afterLeaveGroup, setAfterLeaveGroup] = useState(false);

  const [isUnreadActive, setisUnreadActive] = useState(false);
  const [unreadMessages, setunreadMessages] = useState([]);

  //function for get all contact list
  const getAllContacts = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_ALL_CONTACTS +
        `?token=${token}&modulefrom=frontend`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setAllUserContacts(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all group records for mapping in group modal
  const getAllGroups = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_GROUP_LIST_FOR_CHAT + `?token=${token}`;

      console.log("url of group list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in group list------->", response);

      if (response.status) {
        setAllGroups(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all chats
  const getAllUserChats = async (isLoading = false) => {
    try {
      isLoading && setIsChatPanelLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CHAT_LIST + `?token=${token}`;

      // console.log("url of chat list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsChatPanelLoading(false);

      if (response.status) {
        setChatList(response.data);

        const unreadChats =
          response.data.length > 0
            ? response.data.filter((chat) => !chat.islastemessageseen)
            : [];

        if (unreadChats.length > 0) {
          setisUnreadActive(true);
          setunreadMessages(unreadChats);
        } else {
          setisUnreadActive(false);
          setunreadMessages([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select contact for chat
  const onSelectContact = (contact) => {
    // console.log(contact);
    document.body.classList.add("chatmsg_open");
    setDefaultScreen("contact");
    setSelectedConversationId(contact.userid);
    setselectedChatEmail(contact.email);
    setSelectedChatRoomId(contact._id);

    updateChatStatusHandler(contact._id, contact.userid);
  };

  //function for select group for chat
  const onGroupSelection = (group) => {
    // console.log(group);
    document.body.classList.add("chatmsg_open");
    setDefaultScreen("group");
    setSelectedConversationId(group._id);
    setSelectedChatRoomId(group._id);
    updateChatStatusHandler(group._id, group._id);
  };

  //update chat status
  const updateChatStatusHandler = async (
    conversationChatId,
    conversationId
  ) => {
    document.title = "Conversation"; // Reset to default title

    try {
      let chatStatusData = {
        group: null,
        contactid: null,
        personid: null,
      };

      if (conversationChatId.toString() === conversationId.toString()) {
        chatStatusData.group = conversationId;
      } else {
        chatStatusData.contactid = conversationChatId;
        chatStatusData.personid = conversationId;
      }

      let requestURL =
        url.API_BASE_URL + url.API_UPDATE_TRACKERS + `?token=${token}`;

      const response = await putData_v2(requestURL, chatStatusData);

      // console.log(response);

      if (response.status) {
        setTimeout(() => {
          getAllUserChats(false);
        }, 500);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for remove require response
  const removeRequireResponseHandler = async (
    contact = {},
    moduleName = "contact"
  ) => {
    try {
      setisClosingResponse(true);

      let chatData = {
        chatpersonid: contact.userid,
      };

      let requestURL =
        url.API_BASE_URL + url.API_REMOVE_REQUIRE_RESPONSE + `?token=${token}`;

      const response = await putData(requestURL, chatData);

      console.log(response);

      setisClosingResponse(false);

      if (response.status) {
        if (selectedChatContactId && selectedChatContactId === contact._id) {
          setReloadChatPanelList(true);
        }

        getAllContacts();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let intervalId;
    let index = 0;

    const updateTitle = () => {
      if (index < unreadMessages.length) {
        const contactName = unreadMessages[index].name;

        document.title = `${contactName} sent you message`;

        index++;
      } else {
        index = 0;
        // clearInterval(intervalId);
        document.title = "Conversation"; // Reset to default title
      }
    };

    if (isUnreadActive && unreadMessages.length > 0) {
      intervalId = setInterval(updateTitle, 1000);
    }

    return () => {
      clearInterval(intervalId); // Cleanup on component unmount
    };
  }, [unreadMessages, isUnreadActive]);

  //this use effect use for socket purpose.After sending message the state will be updated as true
  useEffect(() => {
    if (reloadChatPanelList) {
      getAllUserChats();
      setReloadChatPanelList(false);
    }
  }, [reloadChatPanelList]);

  useEffect(() => {
    getAllContacts();
    getAllGroups();
    getAllUserChats(true);
  }, []);

  //this use effect use for refresh list after user leave group
  useEffect(() => {
    if (afterLeaveGroup) {
      document.body.classList.remove("chatmsg_open");
      setDefaultScreen("welcome");
      setSelectedConversationId(null);
      setSelectedChatRoomId(null);

      getAllGroups();
      getAllUserChats(false);

      setAfterLeaveGroup(false);
    }
  }, [afterLeaveGroup]);

  //this use effect use for socket purpose.After sending message the state will be updated as true
  useEffect(() => {
    socket.on("chatleftpanelreload", (data = []) => {
      if (data) {
        if (data.includes(userInfo._id.toString())) {
          setTimeout(() => {
            getAllUserChats(false);
            if (selectedConversationId) {
              playAudioTone();
            }
          }, 500);
        }
      }
    });

    socket.on("seentickreload", (data) => {
      if (data) {
        if (data.includes(userInfo._id.toString())) {
          setTimeout(() => {
            getAllUserChats(false);
          }, 500);
        }
      }
    });

    socket.on("afteraddleftpanelreload", (data = []) => {
      if (data) {
        if (data.includes(userInfo._id.toString())) {
          setTimeout(() => {
            getAllGroups();
            getAllUserChats(false);
          }, 500);
        }
      }
    });

    return () => {
      socket.off("chatlistreload"); // Remove the listener
      socket.disconnect(); // Disconnect the socket
    };
  }, [socket]);

  return (
    <section className="chat_conversations">
      <div className="chat_pnlOuter">
        <ConversationLeftPanel
          socket={socket}
          isChatPanelLoading={isChatPanelLoading}
          getAllUserChats={getAllUserChats}
          chatList={chatList}
          onSelectContact={onSelectContact}
          onGroupSelection={onGroupSelection}
          selectedChatIndex={selectedChatIndex}
          isClosingResponse={isClosingResponse}
          setSelectedChatIndex={setSelectedChatIndex}
          removeRequireResponseHandler={removeRequireResponseHandler}
        />

        <ConversationRightPanel
          socket={socket}
          defaultScreen={defaultScreen}
          selectedConversationId={selectedConversationId}
          selectedChatEmail={selectedChatEmail}
          selectedChatRoomId={selectedChatRoomId}
          reloadChatRightPanel={reloadChatRightPanel}
          setReloadChatPanelList={setReloadChatPanelList}
          allUserContacts={allUserContacts}
          setAfterLeaveGroup={setAfterLeaveGroup}
          setisUnreadActive={setisUnreadActive}
        />
      </div>

      {/* all modals render here  */}
      <AllContactModal
        allUserContacts={allUserContacts}
        onSelectContact={onSelectContact}
      />

      <GroupModal allGroups={allGroups} onGroupSelection={onGroupSelection} />

      <CreateNewGroupModal
        allUserContacts={allUserContacts}
        afterModalClose={onGroupSelection}
      />
    </section>
  );
};

export default ConversationBody;

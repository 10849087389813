import { Link } from "react-router-dom";

import * as url from "helpers/url_helper"; // import all URLs from url_helper

const ProfilePanel = (props) => {
  const { image, name, surName, email, phone, position, tags, address } = props;

  return (
    <div className="profile_pnlArea">
      <div className="profile_hdngTop">
        <h3>Profile</h3>
        <span
          className="profile_crossbtn"
          onClick={() => {
            document.body.classList.remove("profile_menuPnl_open");
          }}
        >
          <i className="material-icons-round">close</i>
        </span>
      </div>
      <div className="profile_innr_body">
        <div className="profile_innrScroll">
          <div className="profile_info_row">
            <div className="profile_picPnl">
              <div className="profile_img">
                <figure>
                  <img
                    src={
                      image === ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + image
                    }
                    alt=""
                  />
                </figure>
              </div>
              <div className="profile_textPnl">
                <h4>
                  {name} {surName}
                </h4>
                <h5>{position}</h5>
              </div>
            </div>
            {/* <div className="tag_msg">
              <Link to="#">
                <i className="material-icons">label</i>
                <span>{tags}</span>
              </Link>
            </div> */}
          </div>
          <div className="profile_info_row">
            <div className="profile_details_row">
              <div className="info_icon">
                <i className="material-icons-round">email </i>
                <span>Mail</span>
              </div>
              <p>
                <Link to="#">{email}</Link>
              </p>
            </div>
          </div>
          <div className="profile_info_row">
            <div className="profile_details_row">
              <div className="info_icon">
                <i className="material-icons-round">phone_in_talk </i>
                <span>Phone</span>
              </div>
              <p>
                <Link to="#">{phone}</Link>
              </p>
            </div>
          </div>
          <div className="profile_info_row">
            <div className="profile_details_row">
              <div className="info_icon">
                <i className="material-icons-round">place </i>
                <span>Address</span>
              </div>
              <p>{address}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePanel;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// for translation
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const GroupModal = ({ allGroups = [], onGroupSelection = () => {} }) => {
  const { t } = useTranslation();

  // useState to hold value from search field
  const [searchValue, setSearchValue] = useState("");

  const showFilteredGroups =
    searchValue.length > 0 || searchValue !== ""
      ? allGroups.filter((group) =>
          group.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : allGroups;

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade"
        id="group_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* ---------- Modal Header starts here ---------- */}
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">{t("Add Group to Conversation")}</h5>
              {/* ---------- Button to hide Modal here ---------- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setSearchValue("")}
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>
            {/* ---------- Modal Header ends here ---------- */}

            {/* ---------- Modal Body starts here ---------- */}
            <div className="modal-body">
              {/* -------------------- Searchbar starts here -------------------- */}
              <div className="group_srchfill">
                <input
                  type="text"
                  value={searchValue}
                  className="form-control"
                  placeholder={t("Search existing group")}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* -------------------- Searchbar ends here -------------------- */}
              {allGroups.length === 0 ? null : (
                <div className="group_innrScroll">
                  {showFilteredGroups.map((group, index) => {
                    return (
                      <div
                        className="chat_usrRow group_msg_row"
                        key={index}
                        onClick={() => onGroupSelection(group)}
                      >
                        <Link to="#" data-bs-dismiss="modal">
                          <div className="usr_lftPnl">
                            {group.image === "" ? (
                              <div className="chatting_user">
                                {group.groupmemberlist
                                  .slice(0, 3)
                                  .map((member, index) => {
                                    return (
                                      <figure className="group_usr" key={index}>
                                        <img
                                          src={
                                            member.groupmemberimage === ""
                                              ? "/images/default-user.png"
                                              : url.SERVER_URL +
                                                member.groupmemberimage
                                          }
                                          alt=""
                                        />
                                      </figure>
                                    );
                                  })}

                                {group.groupmemberlist.length > 3 ? (
                                  <span className="group_usr plus_usr">
                                    {group.groupmemberlist.length - 3}
                                    <em>+</em>
                                  </span>
                                ) : null}
                              </div>
                            ) : (
                              <div className="chatting_user">
                                <img
                                  className="rounded-circle"
                                  src={url.SERVER_URL + group.image}
                                  alt=""
                                />
                              </div>
                            )}

                            <div className="user_name">
                              <h3>{group.name}</h3>
                              <p>{group.groupmembernames}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="group_btnOuter">
                <ul>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="blue_btn"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#group_people_mdl"
                    >
                      <i className="material-icons-round"> add_circle </i>
                      <span>{t("Create New")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* ---------- Modal Body ends here ---------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupModal;

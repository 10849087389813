import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation(); // Language translation imports starts here

  return (
    <div className="chat_rightpnl">
      <div className="welcome_screnBx">
        <div className="welcome_innrPnl">
          <div className="welcome_graphics">
            <img src="/images/welcome_graphics.png" alt="welcome" />
          </div>
          <div className="welcome_txtpnl">
            <h2>{t("Welcome to Indra Chat")}</h2>
            <p>
              {t(
                "Start messaging and send email to your connections in a single platform"
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

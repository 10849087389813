/* eslint-disable */
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppMenu = () => {
  const { t } = useTranslation(); //Language translation imports starts here

  // function for toggle hamburger menu
  const toggleMenu = () => {
    document.body.classList.toggle("menu_open");
    document.body.classList.toggle("append_menu");
  };

  return (
    <div className="hamberger_menuPnl">
      <div className="pro_back_btn">
        <Link
          to="#"
          onClick={() => {
            document.body.classList.toggle("hamberger_menuOpen");
          }}
        >
          <i className="material-icons-round"> arrow_back </i>
        </Link>
      </div>
      <div className="hamberger_menuInnr">
        <ul>
          <li>
            <Link to="/champions" onClick={toggleMenu}>
              Champions
            </Link>
          </li>
          <li>
            <Link to="/groups" onClick={toggleMenu}>
              {t("Groups")}
            </Link>
          </li>
          <li>
            <Link to="/activity" onClick={toggleMenu}>
              {t("Forums")}
            </Link>
          </li>
          <li>
            <Link to="/conversation" onClick={toggleMenu}>
              Chat
            </Link>
          </li>
          <li>
            <Link to="/events" onClick={toggleMenu}>
              {t("Events")}
            </Link>
          </li>

          <li>
            <Link to="/challenges" onClick={toggleMenu}>
              {t("Challenges")}
            </Link>
          </li>
          <li>
            <Link to="/courses" onClick={toggleMenu}>
              {t("Courses")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppMenu;

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// importing the images here
import Shopify from "assets/ChallengesAssets/svg/shopify.svg";

const HomeChallengeLists = () => {
  // for routing
  const history = useHistory();

  const { t } = useTranslation();

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  // initial useState for static JSON data to display the Challenges
  const [jobsData, setJobsData] = useState([]);

  //glow number count
  const glowCount = [1, 2, 3];

  // saving user token here
  const token = localStorage.getItem("token");

  const dummyChallengeData = [
    {
      id: 1,
      hideButton: true,
    },
    {
      id: 2,
      hideButton: true,
    },
    {
      id: 3,
      hideButton: true,
    },
    {
      id: 4,
      hideButton: true,
    },
  ];

  // function to call the API and get all the courses
  const getChallengesListData = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_VIEW_ALL_JOBS +
      `?token=${token}&modulefrom=frontend&start=0&limit=10`;

    try {
      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        if (response.data.length <= 3) {
          let challengeData = response.data;
          let newChallengeArr = challengeData.concat(dummyChallengeData);
          setJobsData(newChallengeArr);
        } else {
          setJobsData(response.data.slice(0, 10));
        }
      }
    } catch (error) {}
  };

  // function to go to the challenge details page
  const goToChallengeDetails = (e, id) => {
    e.preventDefault();
    // redirecting the user to the details page
    history.push(`/challenges/details/${id}`);
  };

  // useEffect from here
  useEffect(() => {
    getChallengesListData();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="challenges_sec pd_30">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>{t("Best Challenges")}</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="/challenges" className="border_btn ylwbrdr_btn">
              {t("See All")}
            </Link>
          </div>
        </div>
        {isLoading ? (
          <div className="slide_Outer">
            <div className="challenges_slider slider_innr">
              <Carousel ssr responsive={responsive}>
                {glowCount.map((count) => {
                  return (
                    <div className="slide_Item mb-4" key={count}>
                      <div className="challenges_item">
                        <div className="challenge_top_head">
                          <div className="challenge_iconOuter">
                            <span
                              className="placeholder rounded-circle me-2"
                              style={{ width: "22px", height: "22px" }}
                            ></span>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "50px" }}
                            ></span>
                          </div>
                          <div className="bookmark_iconOuter">
                            <div className="chappions_rating">
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "40px" }}
                              ></span>
                            </div>
                            <div className="bookmark_icon">
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "16px", height: "20px" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div className="club_nameLocation">
                          <span
                            className="placeholder d-block rounded-2 col-6 mb-2"
                            style={{ height: "20px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 col-4 mb-2"
                            style={{ height: "18px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 col-4"
                            style={{ height: "16px" }}
                          ></span>
                        </div>
                        <div className="experts_skill d-flex flex-wrap">
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </div>
                        <div className="work_details">
                          <div className="d-flex justify-content-between">
                            <span
                              className="placeholder d-block rounded-2 col-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span
                              className="placeholder d-block rounded-2 col-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span
                              className="placeholder d-block rounded-2"
                              style={{ width: "20px", height: "20px" }}
                            ></span>
                          </div>
                        </div>
                        <div className="details_btn">
                          <span
                            className="placeholder d-block rounded-2 col-12"
                            style={{ height: "50px" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        ) : (
          <div className="slide_Outer">
            {jobsData.length > 0 && (
              <div className="challenges_slider slider_innr">
                <Carousel ssr showDots responsive={responsive}>
                  {jobsData.map((job, index) => {
                    return (
                      <div className="slide_Item mb-4" key={index}>
                        {job.hideButton ? (
                          <div className="challenges_item">
                            <div className="challenge_top_head">
                              <div className="challenge_iconOuter">
                                <span
                                  className="placeholder rounded-circle me-2"
                                  style={{ width: "22px", height: "22px" }}
                                ></span>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "50px" }}
                                ></span>
                              </div>
                              <div className="bookmark_iconOuter">
                                <div className="chappions_rating">
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "40px" }}
                                  ></span>
                                </div>
                                <div className="bookmark_icon">
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "16px", height: "20px" }}
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <div className="club_nameLocation">
                              <span
                                className="placeholder d-block rounded-2 col-6 mb-2"
                                style={{ height: "20px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-2 col-4 mb-2"
                                style={{ height: "18px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-2 col-4"
                                style={{ height: "16px" }}
                              ></span>
                            </div>
                            <div className="experts_skill d-flex flex-wrap">
                              <span
                                className="placeholder d-block rounded-2 me-2 mb-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-2 me-2 mb-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-2 me-2 mb-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-2 me-2 mb-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-2 me-2 mb-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </div>
                            <div className="work_details">
                              <div className="d-flex justify-content-between">
                                <span
                                  className="placeholder d-block rounded-2 col-3"
                                  style={{ height: "20px" }}
                                ></span>
                                <span
                                  className="placeholder d-block rounded-2 col-3"
                                  style={{ height: "20px" }}
                                ></span>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "20px", height: "20px" }}
                                ></span>
                              </div>
                            </div>
                            <div className="details_btn">
                              <span
                                className="placeholder d-block rounded-2 col-12"
                                style={{ height: "50px" }}
                              ></span>
                            </div>
                          </div>
                        ) : (
                          <div className="challenges_item">
                            <div className="challenge_top_head">
                              <div className="challenge_iconOuter">
                                <i className="material-icons-outlined challenge_icon">
                                  business
                                </i>
                                <span className="challenge_icon_text">
                                  {job.companyname}
                                </span>
                              </div>

                              {/* --------- Job Rating section starts --------- */}
                              <div className="bookmark_iconOuter">
                                <div className="chappions_rating">
                                  <Link to="#">
                                    <i className="material-icons-outlined">
                                      star
                                    </i>
                                    <span>{job.totalratings}</span>
                                    <em className="mx-1">
                                      ({job.totalreviews})
                                    </em>
                                  </Link>
                                </div>

                                {/* --------- Bookmark icon starts here --------- */}
                                {/* <div className="bookmark_icon">
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        bookmark_border
                                      </i>
                                    </Link>
                                  </div> */}
                                {/* --------- Bookmark icon ends here --------- */}
                              </div>
                              {/* --------- Job Rating section ends --------- */}
                            </div>

                            {/* --------- Job Name & Price section --------- */}
                            <div className="club_nameLocation">
                              <h3>{job.title}</h3>
                              <h4>
                                €{" "}
                                {job.minsalary !== "" || job.maxsalary !== 0
                                  ? job.minsalary
                                  : t("private")}
                              </h4>
                              <div className="locate_text">
                                <h5>
                                  <i className="material-icons-outlined">
                                    place
                                  </i>
                                  <span>
                                    {" "}
                                    {job.location !== ""
                                      ? job.location
                                      : t("Undisclosed")}
                                  </span>
                                </h5>
                              </div>
                            </div>

                            {/* --------- Job Skills section --------- */}
                            <div className="experts_skill">
                              {job.requiredskilllist &&
                              job.requiredskilllist.length > 0 ? (
                                <ul>
                                  {job.requiredskilllist
                                    .slice(0, 5)
                                    .map((skill, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">
                                            <span>{skill.name}</span>
                                          </Link>
                                        </li>
                                      );
                                    })}

                                  {job.requiredskilllist.length <= 5 ? null : (
                                    <li>
                                      <Link to="#" className="skills_count">
                                        +{job.requiredskilllist.length - 5}{" "}
                                        {t("more")}
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              ) : null}
                            </div>

                            {/* --------- Job Type, Experience & Location section --------- */}
                            <div className="work_details">
                              <ul>
                                <li className="job_type">{job.jobtype}</li>
                                <li>
                                  {t("Experience")}:{" "}
                                  {job.experienceyear !== ""
                                    ? job.experienceyear + "+ years"
                                    : " Undisclosed"}
                                </li>

                                {/* --------- Signal icon starts here --------- */}
                                <li>
                                  {job.remotejob == "no" ||
                                  job.remotejob == "" ||
                                  job.remotejob == "0" ? null : (
                                    <span className="material-icons-outlined">
                                      home_work
                                    </span>
                                  )}
                                </li>
                                {/* --------- Signal icon ends here --------- */}
                              </ul>
                            </div>

                            {/* --------- Job Details button section --------- */}
                            <div className="details_btn">
                              <Link
                                to={`/challenges/details/${job._id}`}
                                className="basic_btn ylw_btn"
                              >
                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeChallengeLists;

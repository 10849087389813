/* eslint-disable */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postData, postData_v2 } from "utils/Gateway";
import * as url from "helpers/url_helper";

const ForgotPassModal = (props) => {
  const [email, setEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation(); //for translation

  //function for validate user
  const forgotPassHandler = async () => {
    if (email == "") {
      setEmailWarning(true);
    } else {
      setEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (email.match(mailformat)) {
        setNotValidEMail(false);

        try {
          setIsValidating(true);
          let emailData = {
            email,
          };

          console.log("email data", emailData);

          let requestUrl = url.API_BASE_URL + url.API_FORGOT_PASSWORD;

          console.log("request url", requestUrl);

          const response = await postData_v2(requestUrl, emailData);

          setIsValidating(false);

          console.log("response", response);

          if (response.status) {
            setErrorMessage("");

            let forgotModal = document.querySelector("#forgot_password_modal");
            let modal = bootstrap.Modal.getInstance(forgotModal);
            modal.hide();
            props.displayEmailModal(true);
            resetAll();
          } else {
            setErrorMessage(response.message);
          }
        } catch (error) {}
      } else {
        setNotValidEMail(true);
      }
    }
  };

  //function for reset all date
  const resetAll = () => {
    setEmail("");
    setEmailWarning(false);
    setNotValidEMail(false);
    setErrorMessage("");
  };

  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="forgot_password_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">task_alt</span>
              </div>
              <div className="question_success_hdng">
                <h2 className="fw-bold">
                  {t("Please add your email to send the verification code.")}
                </h2>
                <input
                  type="email"
                  className="registration_code form-control mt-4 mb-2 rounded-0 shadow-none py-2"
                  placeholder={t("Email")}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorMessage("");
                  }}
                />
                {emailWarning && (
                  <p className="text-danger">*{t("Enter email")}</p>
                )}
                {notValidEMail && (
                  <p className="text-danger">*{t("Enter valid email")}</p>
                )}
                {errorMessage !== "" && (
                  <p className="text-danger">*{errorMessage}</p>
                )}
              </div>
              <div className="group_btnOuter px-0">
                <ul>
                  <li className="w_100">
                    <button
                      className="basic_btn ylw_btn"
                      role="button"
                      onClick={forgotPassHandler}
                      disabled={isValidating ? true : false}
                      style={{
                        cursor: isValidating ? "not-allowed" : "pointer",
                      }}
                    >
                      {isValidating && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      <span>{t("Submit")}</span>
                    </button>
                  </li>
                  <li className="w_100">
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="red_btn"
                      onClick={resetAll}
                    >
                      <span>{t("Cancel")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassModal;

import { useEffect } from "react";

import ConversationBody from "components/ConversationComponents/ConversationBody/ConversationBody";

const Conversation = () => {
  useEffect(() => {
    document.title = "Conversation";
  }, []);

  return <ConversationBody />;
};

export default Conversation;

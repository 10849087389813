/* eslint-disable */
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const SaveSurveySuccessModal = ({ closeModalHandler = () => {} }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="signin_modal job_modal sucess_modal">
      <div className="modal fade show" id="survey_success_mdl">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => {
                  closeModalHandler(true);
                }}
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>

            <div className="modal-body">
              <div className="login_body">
                <div className="success_mdlpic">
                  <img src="/images/success.png" alt="" />
                </div>
                <div className="congrats_text">
                  <h3>{t("Success")}</h3>
                  <h5>{t("Thanks for your valuable review")}.</h5>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-center">
                  <button
                    className="basic_btn ylw_btn"
                    data-bs-dismiss="modal"
                    onClick={closeModalHandler}
                  >
                    {t("Close")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveSurveySuccessModal;

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EmptyMessage = () => {
  const { t } = useTranslation(); //Language translation imports starts here

  return (
    <>
      <div className="empty_addlftPnl">
        <div className="add_textlftPnl">
          <h3>{t("Add Friends")}</h3>
          <p>{t("There's Nobody in your contact invite your friends Now")}.</p>
        </div>
        <div className="add_lftPnl_graphics">
          <img src="/images/add-friend-graphics.png" alt="" />
        </div>
      </div>
      <div className="start_arwbtn">
        <Link to="#">{t("Start Here")}</Link>
        <span className="ditect_arrw">
          <img src="/images/Arrow.png" alt="" />
        </span>
      </div>
    </>
  );
};

export default EmptyMessage;

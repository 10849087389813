/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import {
  getData,
  getData_v2,
  postData,
  putData_v2,
  uploadMultipleFile,
} from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { useTranslation, Trans } from "react-i18next";

// import inages
import defaultUser from "assets/ActivityAssests/images/default-user.png";
import AddTagModalRightPanel from "components/ActivityComponents/ActivityModal/AddTagModalRightPanel";
import downloadFileHandler from "helpers/downloadfile";

const ActivityRightpanel = (props) => {
  // let token = "";
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // saving loggedin user's Id
  const loggedInUserID = localStorage.getItem("userID");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type here..."),
  };
  /*----- end of CKEditor classic editor part----- */

  const postId = props.activityPostId;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [forumTitle, setForumTitle] = useState("");
  const [forumDetails, setForumDetails] = useState("");
  const [forumModeratorId, setForumModeratorId] = useState(null);
  const [forumModeratorName, setForumModeratorName] = useState("");
  const [forumModeratorImage, setForumModeratorImage] = useState("");
  const [forumDate, setForumDate] = useState("");
  const [forumLikesCount, setForumLikesCount] = useState(0);
  const [forumCommentsCount, setForumCommentsCount] = useState(0);
  const [forumTagList, setForumTagList] = useState([]);
  const [forumCategories, setForumCategories] = useState([]);
  const [forumUploadedFiles, setForumUploadedFiles] = useState([]);
  const [forumCommentList, setforumCommentList] = useState([]);

  const [commentMessage, setCommentMessage] = useState("");
  const [loadTopics, setLoadTopics] = useState(false);
  const [isCommentPosting, setIsCommentPosting] = useState(false);
  const [isReplyPosting, setIsReplyPosting] = useState(false);
  const [replyWarning, setReplyWarning] = useState(false);
  const [privilegeNotAvailable, setPrivilegeNotAvailable] = useState(false);

  //initial state for toggle reply box
  const [toggleReplyBox, setToggleReplyBox] = useState(null);
  const [assignClass, setAssignClass] = useState(false);

  // ---------------------------- useState to change color of the icons ---------------------------------------
  const [trophyColor, setTrophyColor] = useState("grey");
  const [isLiked, setIsLiked] = useState(false);
  // ----------------------------------------------------------------------------------------------------------

  // initial state for reply to parent message
  const [replyMessage, setReplyMessage] = useState("");

  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  // initial for reply box
  const [replyBoxIndex, setReplyBoxIndex] = useState(null);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);
  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);

  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //spin count for spinner
  const spinCount = [1, 2, 3, 4, 5, 6, 7];

  // ref for point comment box
  const commentBoxRef = useRef(null);

  // function for load details
  const loadDetails = async (postId) => {
    try {
      const requestURL =
        url.API_BASE_URL +
        url.API_ACTIVITY_DETAILS +
        `/${postId}?token=${token}`;

      const response = await getData_v2(requestURL);

      console.log("response", response);

      if (response.status) {
        if (response.data) {
          setDetailsData(response.data);

          setForumTitle(response.data?.title ?? "");
          setForumDetails(response.data.description ?? "");
          setForumModeratorId(response.data.moderatorid ?? null);
          setForumModeratorName(response.data.moderatorname ?? "");
          setForumModeratorImage(response.data.moderatorimage ?? "");
          setForumDate(response.data.forumdate ?? "");
          setForumLikesCount(response.data.likescount ?? 0);
          setForumCommentsCount(response.data.commentcount ?? 0);
          setForumTagList(response.data.taglist ?? []);
          setForumCategories(response.data.categorylist ?? []);
          setForumUploadedFiles(response.data.uploadedfiles ?? []);
          setforumCommentList(response.data.commentlist ?? []);

          setPrivilegeNotAvailable(false);
        } else {
          setDetailsData(null);
          setPrivilegeNotAvailable(true);
        }
        setLoadTopics(false);
      } else {
        setDetailsData(null);
      }
    } catch (error) {
      setError(error);
      setLoadTopics(false);
    }
  };

  // function for post comment
  const answerToQuestion = async () => {
    if (commentMessage == "") {
      setShowEmptyMessage(true);
      return;
    } else {
      setIsCommentPosting(true);
      setShowEmptyMessage(false);
      try {
        let commentUrl =
          url.API_BASE_URL + url.API_ACTIVITY_POST_ANSWER + `?token=${token}`;

        const messageData = {
          forumid: postId,
          description: `<div>${commentMessage}</div>`,
          uploads: fileIds,
        };

        console.log("messageData >>>", messageData);

        setCommentMessage("");

        setMedia([]);
        setFileIds([]);

        console.log("commentUrl", commentUrl);

        const response = await postData(commentUrl, messageData);

        console.log("messageData response >>>", response);

        if (response.status) {
          loadDetails(postId);
          setIsCommentPosting(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // function for toggling reply box
  const showReplyBox = (indexId) => {
    console.log("index clicked", indexId);

    if (indexId) {
      setReplyBoxIndex(indexId);
    } else {
      setReplyBoxIndex(0);
    }
  };

  // function for reply comments
  const replyToAnswerHandler = async (item, reply = null) => {
    if (replyMessage == "") {
      setReplyWarning(true);
      return;
    } else {
      setReplyWarning(false);
      setIsReplyPosting(true);
      let commentUrl =
        url.API_BASE_URL + url.API_ACTIVITY_REPLY_TO_ANSWER + `?token=${token}`;

      let messageData = {
        forumid: postId,
        description: `<div>${replyMessage}</div>`,
        parentid: item._id,
      };

      if (reply) {
        messageData.replyid = reply._id;
      }

      setReplyMessage("");
      setReplyBoxIndex(null);

      console.log("messageData", messageData);

      try {
        const response = await postData(commentUrl, messageData);

        console.log("response", response);

        if (response.status) {
          loadDetails(postId);
        }
        setIsReplyPosting(false);
      } catch (error) {
        console.log("error:", error.message);
      }
    }
  };

  // function for like topics
  const likePostHandler = async (postId) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITY_LIKE_POST +
      `/${postId}?token=${token}`;

    try {
      const response = await putData_v2(requestURL);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `/${commentId}?token=${token}`;

    try {
      const response = await putData_v2(requestURL);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };

  // function for focus to commentbox
  const commentBoxRefToggle = () => {
    commentBoxRef.current?.scrollIntoView();
  };

  // function for set champion
  const championHandler = async (item) => {
    try {
      let forumData = {
        topcomment: item._id,
        winner: item.userid,
      };

      let requestURL =
        url.API_BASE_URL + url.API_UPDATE_FORUM + `/${postId}?token=${token}`;

      const response = await putData_v2(requestURL, forumData);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete comment
  const deleteComment = async (item) => {
    // Check if the user confirms the deletion
    const confirmDelete = window.confirm(
      t("Are you sure you want to delete this comment?")
    );

    if (!confirmDelete) {
      return; // If the user cancels, do nothing
    }

    let commentData = {
      status: "0",
    };

    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITY_REMOVE_COMMENTS +
      `/${item._id}?token=${token}`;

    try {
      const response = await putData_v2(requestURL, commentData);

      console.log("response", response);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 20971520) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BASE_URL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);

          const file = document.getElementById("inputGroupFile02");

          if (file) {
            file.value = null;
          } else {
            return;
          }

          if (response.status == true) {
            let fileResponseId = response.data.map((data) => {
              return data._id;
            });

            if (fileIds.length == 0) {
              setFileIds(fileResponseId);
            } else {
              let fileArr = fileIds.concat(fileResponseId);
              setFileIds(fileArr);
            }
          }

          // check condition whether file array is empty or not
          if (media.length == 0) {
            setMedia(response.data); // changing state of files array
          } else {
            // changing state of files array with previous file array values
            setMedia((media) => [...media, ...response.data]);
          }
          setIsUploading(false);
        } catch (error) {
          return;
        }
      } else {
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  //function for after modal close
  const afterModalClose = () => {
    setLoadTopics(true);
    loadDetails(postId);
  };

  // useefect for get details by post id
  useEffect(() => {
    if (postId != null) {
      setLoadTopics(true);
      loadDetails(postId);
      setReplyWarning(false);
      setReplyBoxIndex(null);
    } else {
      setDetailsData(null);
    }
  }, [postId]);

  // useeffect for load right panel
  useEffect(() => {
    if (props.loadRightPanel) {
      setLoadTopics(true);
      loadDetails(postId);
      props.disableRightPanelRefresh();
    }
  }, [props.loadRightPanel]);

  return (
    <>
      {loadTopics ? (
        <div className="glow_area">
          <div className="plan p-3 border rounded-3 placeholder-glow">
            <span className="placeholder placeholder-sm col-12 rounded-3 mb-4"></span>
            <div className="user_placeHoledr">
              <div className="default_usr">
                <img src={defaultUser} alt="default-user" />
              </div>
              <div className="default_usr_name">
                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              </div>
            </div>
            <div className="data_btnOuter">
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
            </div>
            <p className="card-text placeholder-glow">
              <span className="placeholder placeholder-sm col-12 rounded-3"></span>
              <span className="placeholder placeholder-sm col-10 rounded-3"></span>
              <span className="placeholder placeholder-sm col-9 rounded-3"></span>
              <span className="placeholder placeholder-sm col-8 rounded-3"></span>
              <span className="placeholder placeholder-sm col-7 rounded-3"></span>
              <span className="placeholder placeholder-sm col-6 rounded-3"></span>
            </p>
          </div>
        </div>
      ) : (
        <div className="activity_rightpnl">
          <div className="activity_backBtn">
            {/* --- back button for mobile version ---- */}
            <Link
              to="#"
              role="button"
              onClick={() => {
                document.body.classList.remove("activity_open");
              }}
            >
              <i className="material-icons-outlined">arrow_back</i>
              <span>{t("Back")}</span>
            </Link>
            {/* --- back button for mobile version end ---- */}
          </div>
          {/* ---- main activity details start here ----- */}
          {detailsData == null ? (
            <div className="glow_area" style={{ marginLeft: "0" }}>
              <div className="plan p-3 border rounded-3 placeholder-glow">
                <div className="activity_rightHdng">
                  {privilegeNotAvailable && (
                    <h3>{t("Not enough privilege to view this post")}</h3>
                  )}
                </div>
                <span className="placeholder placeholder-sm col-12 rounded-3 mb-4"></span>
                <div className="user_placeHoledr">
                  <div className="default_usr">
                    <img src={defaultUser} alt="default-user" />
                  </div>
                  <div className="default_usr_name">
                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  </div>
                </div>
                <div className="data_btnOuter">
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                </div>
                <p className="card-text placeholder-glow">
                  <span className="placeholder placeholder-sm col-12 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-9 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-8 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-7 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </p>
              </div>
            </div>
          ) : (
            <div className="pnl_innr">
              <div className="activity_rightItem">
                {/* ---- post title ---- */}
                <div className="activity_rightHdng">
                  <h3>{forumTitle}</h3>
                </div>
                {/* ---- post title end ---- */}

                {/* ---- post owner with image and date start ----- */}
                <div className="activity_userOuter">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img
                        src={
                          forumModeratorImage !== ""
                            ? url.SERVER_URL + forumModeratorImage
                            : defaultUser
                        }
                        alt="owner"
                      />
                    </div>

                    <div className="activty_usrname">
                      <h4>{forumModeratorName}</h4>
                    </div>
                  </div>
                  <div className="activity_date">
                    <i className="material-icons-round">calendar_today</i>
                    <span>{forumDate}</span>
                  </div>
                </div>
                {/* ---- post owner with image and date end ----- */}
                <div className="winers_likeOuter">
                  <div className="likecmnts_pnl">
                    <div className="form_share">
                      <Link
                        to="#"
                        role="button"
                        onClick={() => likePostHandler(postId)}
                      >
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>{t("Likes")}</em>
                        <span>{forumLikesCount}</span>
                      </Link>
                      <Link to="#" role="button" onClick={commentBoxRefToggle}>
                        <i className="material-icons-outlined">chat</i>
                        <em>{t("Comment")}</em>

                        <span>{forumCommentsCount}</span>
                      </Link>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#forum_rightpanel_tag_mdl"
                      >
                        <i className="material-icons-outlined">label</i>
                        <em>{t("Tag")}</em>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="winner_user">
                <div className="activty_usrname">
                  <h5>Winner</h5>
                </div>
                <div className="user_activty">
                  <img src="images/expert2.png" alt="" />
                </div>
              </div> */}
                </div>

                {/* ----- activit files ---- */}

                <div className="d-flex">
                  {forumUploadedFiles.map((file, index) => {
                    const fileExtension = file.name.split(".").pop();

                    const filePath = url.SERVER_URL + file.path;

                    const downloadableFile = {
                      ...file,
                      path: url.SERVER_URL + file.path,
                    };

                    return (
                      <div
                        className="post-media"
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => downloadFileHandler(downloadableFile)}
                      >
                        {fileExtension === "jpg" ||
                        fileExtension === "JPG" ||
                        fileExtension === "png" ||
                        fileExtension === "jpeg" ? (
                          <img
                            src={filePath}
                            alt="docs"
                            className="rounded"
                            height="50"
                            width="50"
                          />
                        ) : (
                          <div>
                            {fileExtension == "pdf" ? (
                              <img
                                src={PDF}
                                alt="docs"
                                className="rounded"
                                height="50"
                                width="50"
                              />
                            ) : (
                              <div>
                                {fileExtension == "xlsx" ||
                                fileExtension == "xls" ? (
                                  <img
                                    src={XLS}
                                    alt="docs"
                                    className="rounded"
                                    height="50"
                                    width="50"
                                  />
                                ) : (
                                  <div>
                                    {fileExtension == "docx" ||
                                    fileExtension == "txt" ||
                                    fileExtension == "doc" ? (
                                      <img
                                        src={DOCS}
                                        alt="docs"
                                        className="rounded"
                                        height="50"
                                        width="50"
                                      />
                                    ) : (
                                      <div>
                                        {fileExtension == "mp4" ||
                                        fileExtension == "webm" ||
                                        fileExtension == "ogv" ? (
                                          <video
                                            controls
                                            width="150"
                                            height="150"
                                          >
                                            <source
                                              src={filePath}
                                              type={`video/${file.name
                                                .split(".")
                                                .pop()}`}
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* ----- post tags start ---- */}

              {forumTagList.length == 0 ? null : (
                <div className="activity_rightItem post-tags">
                  <div className="activity_tagsRow">
                    <h4>{t("Tags")}</h4>

                    <div className="experts_skill activity_skill">
                      <ul>
                        {forumTagList.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{item.title}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {/* ----- post tags end ---- */}

              {/* ----- post category start ---- */}
              {forumCategories.length == 0 ? null : (
                <div className="activity_rightItem post-categories">
                  <div className="activity_tagsRow">
                    <h4>{t("Categories")}</h4>
                    <div className="experts_skill activity_skill">
                      <ul>
                        {forumCategories.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{item.name}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {/* ----- post category end ---- */}

              {/* ----- post descritption start ---- */}
              <div className="activity_rightItem">
                <div
                  dangerouslySetInnerHTML={{
                    __html: forumDetails,
                  }}
                  className="activity_para"
                ></div>
              </div>

              {/* ----- post descritption end ---- */}
              <div ref={commentBoxRef} name="scrollRefDiv" />

              {/* ----- post reply box start ---- */}
              <div className="activity_rightItem">
                <div className="activity_commnets">
                  <h3>
                    {t("Share Your Idea")}{" "}
                    {showEmptyMessage && (
                      <span className="text-danger mx-2">
                        * {t("Please add comment")}
                      </span>
                    )}
                  </h3>{" "}
                  <div className="activity_tagsRow">
                    {/* ----- loading area for file uploading ----- */}
                    {isUploading && (
                      <div
                        className="d-flex align-items-center"
                        style={{ backgroundColor: "#044954" }}
                      >
                        <p className="text-white fw-bold">
                          {t("Uploading Files")}
                        </p>
                        {spinCount.map((count) => {
                          return (
                            <div
                              key={count}
                              className="spinner-grow mx-1 text-white"
                              style={{ height: "6px", width: "6px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* ----- loading area for file uploading end ----- */}
                  </div>
                  {/* ----- file upload ------ */}
                  {fileIsBig ? (
                    <div className="activity_tagsRow">
                      <h6 className="text-white bg-danger">
                        * {t("Uploaded File Size must be lower than 20 mb")}
                      </h6>
                    </div>
                  ) : null}
                  <div className="activity_tagsRow">
                    <h4>
                      {t("File Upload")} (
                      <i>
                        {t(
                          " image / text / pdf / doc / excel / video-mp4,webm,ogv Max: 20MB"
                        )}
                      </i>
                      ){" "}
                    </h4>

                    <div className="activity_tagsRow row col-12">
                      {/* ----- Map the uploaded files for upload with comment   ----- */}
                      {media.map((file, index) => {
                        const fileExtension = file.name.split(".").pop();

                        const filePath = url.SERVER_URL + file.path;

                        const downloadableFile = {
                          ...file,
                          path: url.SERVER_URL + file.path,
                        };

                        return (
                          <div
                            key={index}
                            className="col-auto d-flex flex-column align-items-center gap-1 mb-2"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              downloadFileHandler(downloadableFile)
                            }
                          >
                            {/* <button
                                type="button"
                                className="close close-btn"
                                aria-label="Close"
                                onClick={() => closeFileHandler(index)}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button> */}
                            {fileExtension == "mp4" ||
                            fileExtension == "webm" ||
                            fileExtension == "ogv" ? (
                              <video controls width="150" height="150">
                                <source
                                  src={filePath}
                                  type={`video/${file.name.split(".").pop()}`}
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}

                            {fileExtension === "jpg" ||
                            fileExtension === "JPG" ||
                            fileExtension === "png" ||
                            fileExtension === "jpeg" ||
                            fileExtension === "webp" ? (
                              <img
                                src={filePath}
                                alt="ImageFile"
                                className="rounded"
                                height="80"
                                width="80"
                              />
                            ) : null}
                            {fileExtension == "docx" ||
                            fileExtension == "txt" ||
                            fileExtension == "doc" ? (
                              <img
                                src={DOCS}
                                alt="docs"
                                className="rounded"
                                height="80"
                                width="80"
                              />
                            ) : null}

                            {fileExtension == "pdf" ? (
                              <img
                                src={PDF}
                                alt="docs"
                                className="rounded"
                                height="80"
                                width="80"
                              />
                            ) : null}
                            {fileExtension == "xlsx" ||
                            fileExtension == "xls" ? (
                              <img
                                src={XLS}
                                alt="docs"
                                className="rounded"
                                height="80"
                                width="80"
                              />
                            ) : null}

                            <p className="">
                              <span>{file.name.slice(0, 15)}</span>
                            </p>
                          </div>
                        );
                      })}
                    </div>

                    <div className="reply_fill">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile02"
                        onChange={multipleFileUploadHandler}
                        multiple
                      />
                    </div>
                  </div>
                  <div className="form_commnts_area">
                    <CKEditor
                      className="form-control"
                      editor={ClassicEditor}
                      config={editorConfig}
                      data={commentMessage ? commentMessage : ""}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "100px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        let editContent = editor.getData();
                        setCommentMessage(editContent);
                      }}
                    />

                    <div className="furmcmnt_btn">
                      <button
                        className="cmnt_btn"
                        onClick={answerToQuestion}
                        // disabled={isCommentPosting ? true : false}
                        // style={{
                        //   cursor: isCommentPosting ? "not-allowed" : "pointer",
                        // }}
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* ----- post reply box end ---- */}

              {/* ----- post comments start ---- */}

              {forumCommentList.map((comment, index) => {
                return (
                  <div className="activity_rightItem" key={index}>
                    <div className="chat_likeBx">
                      <div className="activity_para">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: comment.description,
                          }}
                          className="activity_para"
                        ></div>
                      </div>

                      <div className="d-flex mt-2">
                        {comment.uploads.map((file, index) => {
                          const fileExtension = file.name.split(".").pop();

                          const filePath = url.SERVER_URL + file.path;

                          const downloadableFile = {
                            ...file,
                            path: url.SERVER_URL + file.path,
                          };

                          return (
                            <div
                              className="post-media"
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() => {
                                downloadFileHandler(downloadableFile);
                              }}
                            >
                              {fileExtension === "jpg" ||
                              fileExtension == "png" ||
                              fileExtension == "jpeg" ||
                              fileExtension == "webp" ? (
                                <img
                                  src={filePath}
                                  alt="img"
                                  className="rounded"
                                  height="50"
                                  width="50"
                                />
                              ) : fileExtension == "pdf" ? (
                                <img
                                  src={PDF}
                                  alt="docs"
                                  className="rounded"
                                  height="50"
                                  width="50"
                                />
                              ) : fileExtension === "xlsx" ||
                                fileExtension === "xls" ? (
                                <img
                                  src={XLS}
                                  alt="docs"
                                  className="rounded"
                                  height="50"
                                  width="50"
                                />
                              ) : fileExtension == "docx" ||
                                fileExtension == "txt" ||
                                fileExtension == "doc" ? (
                                <img
                                  src={DOCS}
                                  alt="docs"
                                  className="rounded"
                                  height="50"
                                  width="50"
                                />
                              ) : fileExtension == "mp4" ||
                                fileExtension == "webm" ||
                                fileExtension == "ogv" ? (
                                <video controls width="150" height="150">
                                  <source
                                    src={url.SERVER_URL + file.path}
                                    type={`video/${fileExtension}`}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>

                      <div className="likecmnts_pnl">
                        <div className="cmnts_usr">
                          <img
                            src={
                              comment.commentownerimage !== ""
                                ? url.SERVER_URL + comment.commentownerimage
                                : defaultUser
                            }
                            alt=""
                          />
                        </div>
                        <div className="cmnts_usertext">
                          <h3>{comment.commentownername}</h3>
                          <div className="form_share">
                            <Link
                              to="#"
                              role="button"
                              onClick={() => likeCommentHandler(comment._id)}
                            >
                              <i className="material-icons-outlined">
                                thumb_up
                              </i>
                              <em>{t("Likes")}</em>
                              <span>{comment.likescount}</span>
                            </Link>

                            <Link
                              to="#"
                              role="button"
                              onClick={() => showReplyBox(index)}
                            >
                              <i className="material-icons-outlined">chat</i>
                              <em>{t("Comment")}</em>
                              <span>{comment.replycount}</span>
                            </Link>

                            {(forumModeratorId &&
                              forumModeratorId.toString() ===
                                loggedInUserID.toString()) ||
                            userInfo.role.slug === "ADMIN" ||
                            userInfo.role.slug === "SUPER_ADMIN" ? (
                              <Link
                                role="button"
                                to="#"
                                onClick={() => deleteComment(comment)}
                              >
                                <i className="material-icons-outlined">
                                  delete
                                </i>
                                <em>{t("Remove")}</em>
                              </Link>
                            ) : null}

                            {(forumModeratorId &&
                              forumModeratorId.toString() ===
                                loggedInUserID.toString()) ||
                            userInfo.role.slug === "ADMIN" ||
                            userInfo.role.slug === "SUPER_ADMIN" ? (
                              <Link
                                to="#"
                                className={
                                  comment.winner ? "activewinner" : "nowinner"
                                }
                                onClick={() => {
                                  championHandler(comment);
                                }}
                              >
                                <i className="material-icons-round">
                                  emoji_events
                                </i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    {replyBoxIndex == index ? (
                      <div className="activity_comntsRow">
                        <div className="input_fill">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Reply to comment")}
                            name="replyMessage"
                            value={replyMessage}
                            onChange={(e) => setReplyMessage(e.target.value)}
                          />
                        </div>

                        <button
                          className="cmnt_btn"
                          onClick={() => replyToAnswerHandler(comment)}
                          disabled={isReplyPosting ? true : false}
                          style={{
                            cursor: isReplyPosting ? "not-allowed" : "pointer",
                          }}
                        >
                          <span className="material-icons-outlined">send</span>
                        </button>
                      </div>
                    ) : null}

                    {replyWarning && (
                      <p className="text-danger">
                        * {t("Please leave a reply")}
                      </p>
                    )}

                    {comment.replylist.map((subcomment, index2) => {
                      return (
                        <div className="reply_qutation_inr" key={index2}>
                          <h6 className="mb-2 mt-4">
                            {subcomment.commentdate} {subcomment.commenttime}
                          </h6>
                          <div className="quote_replybx bg-white p-3 rounded-5">
                            {subcomment.replyid ? (
                              <div className="activity_usrPnl mb-2">
                                <i className="material-icons-outlined">reply</i>
                                <div className="user_activty">
                                  <img
                                    src={
                                      subcomment.repliedcommentownerimage !== ""
                                        ? url.SERVER_URL +
                                          subcomment.repliedcommentownerimage
                                        : defaultUser
                                    }
                                    alt=""
                                    className="mCS_img_loaded"
                                  />
                                </div>
                                <div className="activty_usrname">
                                  <h4 className="text-muted">
                                    {subcomment.repliedcommentownername}
                                  </h4>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {subcomment.replyid ? (
                              <p
                                className="text-muted mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: subcomment.repliedcomment,
                                }}
                              ></p>
                            ) : null}

                            <div className="quote_msg p-3 rouded-5 mb-3">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: subcomment.description,
                                }}
                              ></p>
                            </div>

                            <div className="activity_usrPnl reply_usr_row align-items-start">
                              <div className="user_activty">
                                <img
                                  src={
                                    subcomment.commentownerimage !== ""
                                      ? url.SERVER_URL +
                                        subcomment.commentownerimage
                                      : defaultUser
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                className="activty_usrname"
                                style={{ width: "100%" }}
                              >
                                <h4 className="fw-bold mb-2">
                                  {subcomment.commentownername}
                                </h4>
                                <div className="form_share">
                                  <div className="chat_likeBx">
                                    <Link
                                      to="#"
                                      role="button"
                                      onClick={() =>
                                        likeCommentHandler(subcomment._id)
                                      }
                                    >
                                      <i className="material-icons-outlined">
                                        thumb_up
                                      </i>
                                      <em>{t("Likes")}</em>
                                      <span>{subcomment.likescount}</span>
                                    </Link>

                                    <Link
                                      to="#"
                                      role="button"
                                      onClick={() =>
                                        showReplyBox(subcomment._id)
                                      }
                                    >
                                      <i className="material-icons-outlined">
                                        chat
                                      </i>
                                      <em>{t("Reply")}</em>
                                      <span>
                                        {subcomment.replytocommentcount}
                                      </span>
                                    </Link>

                                    {userInfo.role.slug === "ADMIN" ||
                                    userInfo.role.slug === "SUPER_ADMIN" ? (
                                      <Link
                                        role="button"
                                        to="#"
                                        onClick={() =>
                                          deleteComment(subcomment)
                                        }
                                      >
                                        <i className="material-icons-outlined">
                                          delete
                                        </i>
                                        <em>{t("Remove")}</em>
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>

                                {replyBoxIndex &&
                                replyBoxIndex.toString() ==
                                  subcomment._id.toString() ? (
                                  <div className="activity_comntsRow">
                                    <div className="input_fill">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Reply to comment")}
                                        name="replyMessage"
                                        value={replyMessage}
                                        onChange={(e) =>
                                          setReplyMessage(e.target.value)
                                        }
                                      />
                                    </div>

                                    <button
                                      className="cmnt_btn"
                                      onClick={() =>
                                        replyToAnswerHandler(
                                          comment,
                                          subcomment
                                        )
                                      }
                                      disabled={isReplyPosting ? true : false}
                                      style={{
                                        cursor: isReplyPosting
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    >
                                      <span className="material-icons-outlined">
                                        send
                                      </span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {/* ----- post comments end ---- */}
            </div>
          )}
          {/* ---- main activity details start end ----- */}
        </div>
      )}
      <AddTagModalRightPanel
        afterModalClose={afterModalClose}
        postId={postId}
      />
    </>
  );
};

export default ActivityRightpanel;

import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as url from "helpers/url_helper"; // import all URLs from url_helper

const AllContactModal = ({
  allUserContacts = [],
  onSelectContact = () => {},
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  const showFilteredContacts =
    searchValue.length > 0 || searchValue !== ""
      ? allUserContacts.filter((contact) =>
          contact.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : allUserContacts;

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade"
        id="start_newchat"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="start_newchatLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">
                {t("Add Contact to Conversation")}
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>

            <div className="modal-body">
              <div className="group_srchfill add_fill d-flex align-items-center py-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Search added contact")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>

              <div className="group_innrScroll">
                {allUserContacts.length === 0 ? (
                  <div className="empty_addlftPnl">
                    <div className="add_textlftPnl">
                      <h3>{t("Add Friends")}</h3>
                      <p>
                        {t(
                          "There's Nobody in your contact invite your friends Now"
                        )}
                        .
                      </p>
                    </div>
                  </div>
                ) : (
                  showFilteredContacts.map((contact, index) => {
                    return (
                      <div className="chat_usrRow" key={index}>
                        <Link
                          to="#"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            onSelectContact(contact);
                          }}
                        >
                          <div className="usr_lftPnl">
                            <div className="chatting_user">
                              <img
                                src={
                                  contact.image === ""
                                    ? "/images/default-user.png"
                                    : url.SERVER_URL + contact.image
                                }
                                alt=""
                              />
                            </div>
                            <div className="user_name">
                              <h3>{contact.name}</h3>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllContactModal;

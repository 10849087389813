/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postData_v2, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */
// importing context API here
import { GlobalIndraInfo } from "App";
//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import ThanksReviewChampionModal from "../modals/ThanksReviewChampionModal";

const Review = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const starCount = [1, 2, 3, 4, 5];

  const { t } = useTranslation();

  // get info from context
  const { isChampionConnect, getConnectionStatus, setReloadChampionDetails } =
    useContext(GlobalIndraInfo);

  // 👇️ get ID from url
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState("1");

  // Intitial useState to save all the review from the API
  const [reviewsList, setReviewsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //select star rating
  const starRatingHandler = (value) => {
    setstarRating(value);
  };

  // Function to submit the review to the API.
  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    setCommentError(false);
    setRatingError(false);
    if (comments == "" || starRating == 0) {
      if (comments == "") {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
      if (starRating == 0) {
        setRatingError(true);
      } else {
        setRatingError(false);
      }
    } else {
      setIsSubmitting(true);
      try {
        let requestURL =
          url.API_BASE_URL +
          url.API_SUBMIT_REVIEW +
          `?token=${token}&moduleslug=appuser`;

        let reviewData = {
          comment: comments,
          rating: starRating,
          appuserid: params.id,
        };

        const response = await postData_v2(requestURL, reviewData);

        if (response.status) {
          resetHandler();
          let myModal = new bootstrap.Modal(
            document.getElementById("champion_review_success_modal")
          );
          myModal.show();
          setIsSubmitting(false);
        }
      } catch (error) {}
    }
  };

  // Function to get all the review data from API
  const getReviewsLists = async (id) => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}` +
      `&moduleslug=appuser` +
      `&moduleid=${id}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          setReviewsList(response.data);
        } else {
          setReviewsList([]);
        }
      }
    } catch (error) {}
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setstarRating("1");
    setComments("");
  };

  // function for calling API after closing thank you modal
  const afterThanksReviewClose = () => {
    setReloadChampionDetails(true);
    getReviewsLists(params.id);
  };

  // useEffect starts here
  useEffect(() => {
    getReviewsLists(params.id);
  }, [params.id]);

  return (
    <>
      <div className="tab-pane fade show active" id="feedbacks">
        {isLoading ? (
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>
            <div className="customer_feedbacks">
              <div className="recomend_row">
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-1 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="rating_star">
                      <span
                        className="placeholder rounded-2 mt-3"
                        style={{ width: "88px", height: "22px" }}
                      ></span>
                    </div>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="feedback_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: " 35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: " 35px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="post_time">
                      <span
                        className="placeholder placeholder-sm rounded-2"
                        style={{ width: "80px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recomend_row">
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-1 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="rating_star">
                      <span
                        className="placeholder rounded-2 mt-3"
                        style={{ width: "88px", height: "22px" }}
                      ></span>
                    </div>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="feedback_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="post_time">
                      <span
                        className="placeholder placeholder-sm rounded-2"
                        style={{ width: " 80px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recomend_row">
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-1 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="rating_star">
                      <span
                        className="placeholder rounded-2 mt-3"
                        style={{ width: "88px", height: "22px" }}
                      ></span>
                    </div>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="feedback_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="post_time">
                      <span
                        className="placeholder placeholder-sm rounded-2"
                        style={{ width: "80px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="course_detailspnl">
            {/* <div className="contnt_hdng">
              <h4>{t("All Reviews")}</h4>
            </div> */}

            {/* ---- review box ---- */}
            {isChampionConnect && (
              <div className="form_commnts_area commnts_newbx">
                <div className="course_rvw_row">
                  <div className="rating_hdng">
                    <h5>{t("Give Ratings")}</h5>
                  </div>
                  <ul>
                    {starCount.map((star, index) => (
                      <li
                        key={index}
                        className={
                          star.toString() <= starRating.toString()
                            ? "active_star"
                            : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => starRatingHandler(star.toString())}
                        >
                          <span className="material-icons">star</span>
                        </Link>
                      </li>
                    ))}
                  </ul>

                  {ratingError && (
                    <p className="text-danger mb-3">* Please give rating</p>
                  )}
                </div>
                <div className="commnts_send_innr">
                  <div className="rating_hdng">
                    <h5>{t("Give Comments")}</h5>
                  </div>
                  <div className="commnts_sendArea">
                    <textarea
                      className="form-control"
                      placeholder={t("Write your comments here")}
                      value={comments != "" ? comments : ""}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                    <div className="furmcmnt_btn">
                      <button
                        className="cmnt_btn"
                        onClick={(e) => reviewSubmitHandler(e)}
                        disabled={isSubmitting ? true : false}
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "pointer",
                        }}
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                  {commentError && (
                    <p className="text-danger mt-2">* Please add comment</p>
                  )}
                </div>
              </div>
            )}
            {/* ---- review box end ---- */}

            {/* ------- All reviews list section (course like) starts here ------- */}
            {reviewsList.length === 0 ? (
              <div className="empty_lessonsArea">
                <div className="empty_lessonpic">
                  <img src={blankLesson} alt="blanklesson" />
                </div>
                <div className="empty_lessons_text">
                  <h2>{t("Sorry....!")}</h2>
                  <p>{t("No reviews available for this")} champion.</p>
                  {/* {params.id == userInfo.id ? null : (
                    <div className="join_btn">
                      <Link
                        to="#"
                        className="basic_btn ylw_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#contact_owner"
                      >
                        Connect
                      </Link>
                    </div>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="review_bxOuter">
                <div className="row">
                  {reviewsList.map((review, index) => {
                    return (
                      <div className="col-lg-6" key={index}>
                        <div className="review_bxItem">
                          <div className="forum_cmntsRow">
                            <div className="team_userOuter forum_usrOuter">
                              {/* -------- User image section starts here -------- */}
                              <div className="team_usr_pic">
                                <img
                                  src={
                                    review.image != ""
                                      ? url.SERVER_URL + review.image
                                      : defaultUser
                                  }
                                  alt="UserProfilePic"
                                />
                              </div>
                              {/* -------- User image section ends here -------- */}

                              {/* -------- User details & comments section starts here -------- */}
                              <div className="team_usrTxt">
                                <div className="time_text">
                                  <h5>{review.createddate}</h5>
                                </div>
                                <h3>{review.username}</h3>
                                <h6>{review.useremail}</h6>
                                <div className="comnt_rating cstomer_tvw">
                                  <ul>
                                    {_.times(review.rating, (i) => (
                                      <li className="active_star" key={i}>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            star
                                          </i>
                                        </Link>
                                      </li>
                                    ))}
                                    {/* {_.times(5 - review.globalrating, (i) => (
                                    <li key={i}>
                                      <Link to="#">
                                        <i className="material-icons-outlined">
                                          star
                                        </i>
                                      </Link>
                                    </li>
                                  ))} */}
                                  </ul>
                                </div>
                                <div className="forum_para">
                                  <p>{review.comment}</p>
                                </div>

                                {/* ------- Like & comment button section starts here ------- */}
                                {/* <div className="form_share">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>Likes</em>
                                  <span>2</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    chat{" "}
                                  </i>
                                  <em>Comment</em>
                                  <span>3</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    share{" "}
                                  </i>
                                </Link>
                              </div> */}
                                {/* ------- Like & comment button section endss here ------- */}
                              </div>
                              {/* -------- User details & comments section ends here -------- */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* ------- All reviews list section (course like) ends here ------- */}
          </div>
        )}
      </div>
      <ThanksReviewChampionModal
        afterThanksReviewClose={afterThanksReviewClose}
      />
    </>
  );
};

export default Review;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/HomeAssets/Images/default-user.png";

const WinnerTab = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  // 👇️ get ID from url
  const params = useParams();

  const glowCount = [1, 2, 3, 4, 5, 6, 7, 8]; //for glow placeholder

  const [winnerCadidates, setWinnerCadidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_CHALLENGES_DETAILS_APPLIED_TAB +
      `?token=${token}` +
      `&challenge=${id}`;

    try {
      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          const filteredResult = response.data.filter((winner) => {
            return winner.iswinner;
          });
          setWinnerCadidates(filteredResult);
        }
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="course_detailspnl">
          {/* <div className="contnt_hdng">
            <span
              className="placeholder rounded-3 col-1"
              style={{ height: "22px" }}
            ></span>
          </div> */}
          <div className="team_outer">
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-3 col-sm-6" key={count}>
                    <div className="expertbx_item members_teamItem">
                      <div className="membrs_teamOuter">
                        <div className="team_membrs_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </div>
                        <div className="membrs_team_details flex-fill">
                          <span
                            className="placeholder d-block rounded-3 col-8 mb-3"
                            style={{ height: "20px" }}
                          ></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="course_detailspnl">
          {winnerCadidates.length === 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>
                      {t(
                        "Winning candidates are not available at this moment."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* <div className="contnt_hdng">
                <h4>Winner</h4>
              </div> */}
              <div className="team_outer">
                <div className="row">
                  {winnerCadidates.map((candidate, index) => {
                    return (
                      <div className="col-lg-3 col-sm-6" key={index}>
                        <div className="expertbx_item members_teamItem">
                          <div className="membrs_teamOuter">
                            <div className="team_membrs_pic">
                              <img
                                src={
                                  candidate.candidateimage !== ""
                                    ? url.SERVER_URL + candidate.candidateimage
                                    : defaultUser
                                }
                                alt="user"
                              />
                            </div>
                            <div className="membrs_team_details">
                              <div className="team_name">
                                <h3>{candidate.cvcandidatename}</h3>
                                <h5>{candidate.candidateposition}</h5>
                              </div>
                              <div className="team_rating star_color d-flex">
                                <h3>
                                  {" "}
                                  <i className="material-icons-outlined me-1">
                                    star
                                  </i>{" "}
                                  <span>{candidate.totalratings}</span>
                                </h3>

                                <h6>
                                  {candidate.totalreviews}
                                  <span>({t("reviews")})</span>
                                </h6>
                              </div>
                              <div className="team_address_value">
                                <h3>
                                  {candidate.candidatelocation}{" "}
                                  <span>€{candidate.price}</span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WinnerTab;

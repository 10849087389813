// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

import chatTone from "assets/ConversationAssests/audio/chat_tone03.mp3";

export const getCurrentDateInString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getAllCategories = async (parentSlug) => {
  try {
    let requestUrl =
      url.API_BASE_URL + url.API_GET_CATEGORIES + `?ecosystemslug=INDRA`;

    if (parentSlug && parentSlug !== "") {
      requestUrl += `&slug=${parentSlug}`;
    }

    const response = await getData(requestUrl);

    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const truncateHTML = (html, maxLength) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html"); // Parse the HTML content
  const textContent = doc.body.textContent || ""; // Extract text content
  return textContent.length > maxLength
    ? textContent.slice(0, maxLength) + "..."
    : textContent;
};

export const getFormattedDate = (dateString = "") => {
  if (dateString !== "") {
    const parts = dateString.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate; // Output: 17/10/2018
  } else {
    return "";
  }
};

export const formatTimeAgo = (timestamp) => {
  const now = new Date();
  const messageTime = new Date(timestamp);
  const difference = (now - messageTime) / 1000; // Difference in seconds

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Start of today
  const yesterday = new Date(today); // Start of yesterday
  yesterday.setDate(yesterday.getDate() - 1);

  if (difference < 60) {
    return Math.round(difference) + "s ago";
  } else if (difference >= 60 && difference < 3600) {
    const minutes = Math.floor(difference / 60);
    const seconds = Math.round(difference % 60);
    if (seconds === 0) {
      return minutes + "m ago";
    } else {
      return minutes + "m " + seconds + "s ago";
    }
  } else if (difference >= 3600 && difference < 86400 && messageTime >= today) {
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor((difference % 3600) / 60);
    return hours + "h " + minutes + "m ago";
  } else {
    const formattedDate = messageTime
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false, // 24-hour format
      })
      .replace(",", ""); // Removing the comma

    const [datePart, timePart] = formattedDate.split(" ");
    const [month, day, year] = datePart.split("/");
    const formattedDateTime = `${day}/${month}/${year} ${timePart}`;
    return formattedDateTime.toLowerCase(); // Converting to lower case (pm)
  }
};

export const languageList = [
  {
    flagimageurl: "/images/flag1.svg",
    label: "EN",
    value: "en",
  },
  {
    flagimageurl: "/images/flag2.svg",
    label: "ES",
    value: "es",
  },
  {
    flagimageurl: "/images/flag3.svg",
    label: "CAT",
    value: "cat",
  },
];

export const generateRandomString = (length = 1) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const playAudioTone = () => {
  new Audio(chatTone).play();
};

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

import "react-multi-carousel/lib/styles.css";

// import images
import sponsor1 from "assets/EventsAssets/images/sponsor1.png";
import sponsor2 from "assets/EventsAssets/images/sponsor2.png";
import sponsor3 from "assets/EventsAssets/images/sponsor3.png";
import PDF from "assets/EventsAssets/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
/* -------------------------------------------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import downloadFileHandler from "helpers/downloadfile";
/* -------------------------------------------------------------- */

const About = () => {
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const params = useParams();

  const glowCount = [1, 2, 3, 4];

  /* --------- CSS Styling for this component start --------- */
  // responsive property for carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 590, min: 0 },
      items: 1,
    },
  };
  /* --------- CSS Styling for this component end --------- */

  //initial useState for event details about data
  const [eventAboutData, setEventAboutData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to get all the about data from API
  const getEventBannerData = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL + url.API_EVENT_ABOUT + `/${params.id}?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setEventAboutData(response.data);
        } else {
          setEventAboutData(null);
        }
        setIsLoading(false);
      } else {
        setEventAboutData(null);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    if (params.id) {
      getEventBannerData();
    }
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        // --------------- Glow placeholder starts here ---------------
        <div className="exprt_overviewOuter">
          <div className="course_detailspnl">
            <div className="event_catagoryslide_Outer">
              <div className="evenmt_catagory_slider">
                <Carousel ssr responsive={responsive}>
                  {glowCount.map((count) => {
                    return (
                      <div className="catagory_slideItem" key={count}>
                        <div className="catagory_slideInnr">
                          <div className="catagory_slideHdng">
                            <span className="placeholder placeholder-md rounded-2 col-4"></span>
                          </div>
                          <div className="experts_skill">
                            <ul>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "95px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "110px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "65px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "93px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "115px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "98px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "87px", height: "30px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span className="placeholder placeholder-md rounded-3 col-1"></span>
            </div>
            <div className="results_para">
              <p>
                <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
              </p>
              <p>
                <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
              </p>
            </div>
          </div>
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span className="placeholder placeholder-md rounded-3 col-1"></span>
            </div>
            <div className="sponsor_cmpny">
              <ul>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "42px", height: "42px" }}
                      ></span>
                    </i>
                    <span className="placeholder placeholder-sm rounded-2 col-12"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "42px", height: "42px" }}
                      ></span>
                    </i>
                    <span className="placeholder placeholder-sm rounded-2 col-12"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "42px", height: "42px" }}
                      ></span>
                    </i>
                    <span className="placeholder placeholder-sm rounded-2 col-12"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span className="placeholder placeholder-md rounded-3 col-1"></span>
            </div>
            <div className="upload_area">
              <div className="upload_item">
                <span
                  className="placeholder"
                  style={{ width: "135px", height: "135px" }}
                ></span>
              </div>
              <div className="upload_item">
                <span
                  className="placeholder"
                  style={{ width: "135px", height: "135px" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // --------------- Content starts here ---------------
        <>
          {eventAboutData == null || eventAboutData.length == 0 ? (
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={blankLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>{t("Sorry....!")}</h2>
                <p>{t("No Details available for this event.")}</p>
              </div>
            </div>
          ) : (
            <div className="exprt_overviewOuter">
              {/* ----- slide item section start ----- */}
              <div className="course_detailspnl">
                <div className="event_catagoryslide_Outer">
                  <div className="evenmt_catagory_slider">
                    <Carousel ssr responsive={responsive}>
                      {/* ---------- Category Slider Card starts here ---------- */}
                      {eventAboutData?.categories ? (
                        <div className="catagory_slideItem mb-4">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>{t("Category")}</h3>
                            </div>
                            {eventAboutData?.categories?.length > 0 ? (
                              <div className="experts_skill">
                                <ul>
                                  {eventAboutData?.categories?.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item.label}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {/* ---------- Category Slider Card ends here ---------- */}

                      {/* ---------- Search Slider Card starts here ---------- */}
                      {eventAboutData?.searchings ? (
                        <div className="catagory_slideItem mb-4">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>{t("Search")}</h3>
                            </div>
                            {eventAboutData?.searchings?.length > 0 ? (
                              <div className="experts_skill">
                                <ul>
                                  {eventAboutData?.searchings?.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item.label}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {/* ---------- Search Slider Card ends here ---------- */}

                      {/* ---------- Offers Slider Card starts here ---------- */}
                      {/* {eventAboutData.offering != null ? (
                        <div className="catagory_slideItem mb-4">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>Offers</h3>
                            </div>
                            {eventAboutData.offering.length > 0 ? (
                              <div className="experts_skill">
                                <ul>
                                  {eventAboutData.offering.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null} */}
                      {/* ---------- Offers Slider Card ends here ---------- */}

                      {/* ---------- Interest Slider Card starts here ---------- */}
                      {eventAboutData?.interests ? (
                        <div className="catagory_slideItem mb-4">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>{t("Interest")}</h3>
                            </div>
                            {eventAboutData?.interests?.length > 0 ? (
                              <div className="experts_skill">
                                <ul>
                                  {eventAboutData?.interests?.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item.label}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {/* ---------- Interest Slider Card ends here ---------- */}
                    </Carousel>
                  </div>
                </div>
              </div>
              {/* ----- end of slide item section ----- */}

              {/* ----- event summary start ----- */}
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>{t("Summary")}</h4>
                </div>
                <div
                  className="results_para"
                  dangerouslySetInnerHTML={{
                    __html: eventAboutData.description,
                  }}
                ></div>
              </div>
              {/* ----- event summary end ----- */}

              {/* ----- event sponsor start ----- */}
              {/* <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Sponsors</h4>
                </div>
                <div className="sponsor_cmpny">
                  <ul>
                    <li>
                      <Link to="#">
                        <i>
                          <img src={sponsor1} alt="" />
                        </i>
                        <span>Microsoft</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i>
                          <img src={sponsor2} alt="" />
                        </i>
                        <span>Dropbox</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i>
                          <img src={sponsor3} alt="" />
                        </i>
                        <span>Google</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* ----- event sponsor end ----- */}

              {/* ----- event documents start ----- */}
              {eventAboutData?.uploadedfiles?.length === 0 ? null : (
                <div className="course_detailspnl">
                  <div className="contnt_hdng">
                    <h4>{t("Documents")}</h4>
                  </div>
                  {/* ---- Document section start ---- */}
                  <div className="upload_area">
                    {eventAboutData?.uploadedfiles?.map((document, index) => {
                      return (
                        <div
                          className="upload_item"
                          key={index}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="uploaded_innrBx">
                            <div className="upload_icon">
                              {document.name.split(".").pop() == "pdf" ? (
                                <img src={PDF} alt="pdf" />
                              ) : (
                                <>
                                  {document.name.split(".").pop() == "docx" ||
                                  document.name.split(".").pop() == "txt" ||
                                  document.name.split(".").pop() == "doc" ? (
                                    <img src={DOCS} alt="docs" />
                                  ) : (
                                    <img src={XLS} alt="pdf" />
                                  )}
                                </>
                              )}
                            </div>
                            <div className="upload_txt">
                              {/* <h4>Resume</h4>
                            <h5>1.5mb</h5> */}
                              <Link
                                to="#"
                                className="download-file"
                                onClick={() => {
                                  const downloadedFile = {
                                    ...document,
                                    path: url.SERVER_URL + document.path,
                                  };
                                  downloadFileHandler(downloadedFile);
                                }}
                              >
                                <i className="material-icons d-block mt-2">
                                  file_download
                                </i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* ---- Document section start ---- */}
                </div>
              )}
              {/* ----- event documents end ----- */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default About;

/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

const CourseListArea = () => {
  const { t } = useTranslation();
  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* get function and values from use context */
  const {
    searchQueryValues,
    searchTitle,
    searchSkills,
    searchCategory,
    resetCourseQuery,
    refreshCourseList,
    resetCourseTitleOnly,
    resetCourseSkillsOnly,
    resetCourseCategoryOnly,
    deleteSingleCourseSkill,
    deleteSingleCourseCategory,
    selectedCourseFilterData,
    resetCourseFilterOnly,
    coursePageReset,
    setCoursePageReset,
  } = useContext(GlobalIndraInfo);

  /*---- initial for pagination and pages ----*/
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  const courseListRef = useRef(null);

  // for routing
  const history = useHistory();

  // initial useState to save demo JSON data
  const [courseListData, setCourseListData] = useState([]);

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  //function for toggle eventRef
  const courseRefToggle = () => {
    courseListRef.current?.scrollIntoView();
  };

  // function to call the API and get all the courses
  const getPublishedCourseListData = async () => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_COURSE_LIST +
        `?token=${token}&modulefrom=frontend&start=${page * 12}&limit=12`;

      if (searchTitle != "") {
        requestURL = requestURL + `&filtertitle=${searchTitle}`;
      }

      if (searchSkills.length > 0) {
        requestURL += `&filterskills=${searchSkills
          .map((skill) => skill.value)
          .join(",")}`;
      }

      if (searchCategory.length > 0) {
        requestURL += `&filtercategories=${searchCategory
          .map((category) => category.value)
          .join(",")}`;
      }

      // if (selectedCourseFilterData != null) {
      //   requestURL =
      //     requestURL + `&selecteditem=${selectedCourseFilterData.value}`;
      // }

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setCourseListData(response.data);

        // set last page no from API
        setLastPageNo(response.totalpages);

        /*--- Creating dynamic array for pagination ----*/
        let pageArray = [];

        for (let i = 1; i <= response.totalpages; i++) {
          pageArray.push(i);
        }
        setTotalPages(pageArray);
        /*--- Creating dynamic array for pagination end ----*/
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function to go to the details page
  const goToCourseDetails = (e, id, isTraining) => {
    e.preventDefault();
    if (isTraining) {
      history.push(`/coursetraining/details/${id}`);
    } else {
      history.push(`/course/details/${id}`);
    }
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for reset only champion name
  const resetCourseTitle = () => {
    resetCourseTitleOnly(true);
    setPage(0);
  };

  // function for reset only chanmpion skills
  const resetCourseSkills = () => {
    resetCourseSkillsOnly(true);
    setPage(0);
  };

  // function for reset only champion location
  const resetCourseCategory = () => {
    resetCourseCategoryOnly(true);
    setPage(0);
  };

  // function for delete single skill values
  const deleteSingleSkill = (index) => {
    if (index === 0) {
      setPage(0);
    }
    let deletedSkill = [...searchSkills];
    deletedSkill.splice(index, 1);
    deleteSingleCourseSkill(deletedSkill);
  };

  // function for delete single skill values
  const deleteSingleCategory = (index) => {
    if (index === 0) {
      setPage(0);
    }
    let deletedCategory = [...searchCategory];
    deletedCategory.splice(index, 1);
    deleteSingleCourseCategory(deletedCategory);
  };

  const resetCourseFilter = () => {
    resetCourseFilterOnly(true);
    setPage(0);
  };

  // useEffect for page reset to 1 from here
  useEffect(() => {
    if (coursePageReset) {
      setPage(0);
      setCoursePageReset(false);
    }
  }, [coursePageReset]);

  // useeffect for get course lists using search query
  useEffect(() => {
    if (
      searchTitle != "" ||
      searchSkills.length > 0 ||
      searchCategory.length > 0 ||
      selectedCourseFilterData != null
    ) {
      getPublishedCourseListData();
      searchQueryValues(null);
    } else {
      getPublishedCourseListData();
    }
  }, [
    page,
    searchTitle,
    searchSkills,
    searchCategory,
    selectedCourseFilterData,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div ref={courseListRef} name="scrollChampionRefDiv" />
      <section className="champions_area">
        {/* ------------- Filter Section starts here ------------- */}
        {searchTitle != "" ||
        searchSkills.length > 0 ||
        searchCategory.length > 0 ||
        selectedCourseFilterData != null ? (
          <div id="filter_result" className="mb-4">
            <div className="container">
              <div className="d-flex flex-wrap gap-2">
                {/* ---------- Search Title Section starts here ---------- */}
                {searchTitle != "" ? (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        Name
                      </label>

                      <label>{searchTitle}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetCourseTitle}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                ) : null}

                {/* ---------- Search Skills Section starts here ---------- */}
                {searchSkills.length > 0 ? (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        Skills
                      </label>
                      <ul className="badges d-flex flex-wrap gap-2">
                        {searchSkills.map((data, index) => {
                          return (
                            <li className="d-flex gap-2 border" key={index}>
                              {data.label}{" "}
                              <Link
                                to="#"
                                onClick={() => deleteSingleSkill(index)}
                              >
                                <i className="d-block material-icons">clear</i>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetCourseSkills}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                ) : null}

                {/* ---------- Search category Section starts here ---------- */}
                {searchCategory.length > 0 ? (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        Category
                      </label>
                      <ul className="badges d-flex flex-wrap gap-2">
                        {searchCategory.map((data, index) => {
                          return (
                            <li className="d-flex gap-2 border" key={index}>
                              {data.label}{" "}
                              <Link
                                to="#"
                                onClick={() => deleteSingleCategory(index)}
                              >
                                <i className="d-block material-icons">clear</i>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetCourseCategory}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                ) : null}

                {/* ----- searched event filter value ------- */}

                {selectedCourseFilterData == null ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Type")}
                      </label>
                      <label>{selectedCourseFilterData.label}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetCourseFilter}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {/* ------------- Filter Section ends here ------------- */}
        <div className="container">
          {isLoading ? (
            <div className="champions_outer">
              <div className="row">
                {/* --------- Glow placeholder component starts here --------- */}
                {[1, 2, 3].map((i) => (
                  <div className="col-lg-4 col-sm-6" key={i}>
                    <div className="course_item">
                      <div className="champions_pic">
                        <img src={blankImage} alt="" />
                      </div>
                      <div className="plan p-3 border rounded-3 placeholder-glow">
                        <div className="user_placeHoledr">
                          <div className="default_usr_name">
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          </div>
                        </div>
                        <div className="data_btnOuter">
                          <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                        </div>
                        <p className="data_btnOuter placeholder-glow">
                          <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                        </p>
                        <div className="data_btnOuter">
                          <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* --------- Glow placeholder component ends here --------- */}
              </div>
            </div>
          ) : (
            <div className="champions_outer">
              {courseListData.length > 0 ? (
                <div className="row">
                  {/* ------------ Mapping starts here ------------ */}
                  {courseListData.map((course, index) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={index}>
                        <div className="course_item sameheight_col">
                          {/* ------- Course image section ------ */}
                          <div className="champions_pic">
                            <img
                              src={
                                course.courselogo === ""
                                  ? blankImage
                                  : url.SERVER_URL + course.courselogo
                              }
                              alt="courseBanner"
                            />
                          </div>
                          <div className="champions_textpnl">
                            {/* ------- Course author & rating section ------ */}
                            <div className="course_hdngauthor">
                              <h4>{course.moderatorname}</h4>
                              <div className="chappions_rating">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                  <span>{course.totalratings}</span>
                                  <em>({course.totalreviews})</em>
                                </Link>
                              </div>
                            </div>

                            {/* ------- Course name section ------ */}
                            <div className="leftchampion_text">
                              <h3>{course.title}</h3>
                            </div>

                            {/* ------- Course Categories list section ------ */}
                            <div className="experts_skill">
                              <ul>
                                {course.categorylist.map((item, index) => (
                                  <li key={index}>
                                    <Link to="#">
                                      <span>{item.name}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            {/* ------- Course details section ------ */}
                            <div className="details_btn">
                              <Link
                                to="#"
                                className="basic_btn ylw_btn"
                                onClick={(e) =>
                                  goToCourseDetails(
                                    e,
                                    course._id,
                                    course?.istraining
                                  )
                                }
                              >
                                {userInfo._id.toString() ===
                                  course.moderatorid.toString() ||
                                userInfo.role.slug === "SUPER_ADMIN" ||
                                userInfo.role.slug === "ADMIN" ? (
                                  <i className="material-icons me-3">
                                    done_all
                                  </i>
                                ) : (
                                  <span>
                                    {course.isjoined ? (
                                      <span>
                                        {course.isapproved ? (
                                          <i className="material-icons me-3">
                                            done_all
                                          </i>
                                        ) : (
                                          <i className="material-icons me-3">
                                            done
                                          </i>
                                        )}
                                      </span>
                                    ) : null}{" "}
                                  </span>
                                )}

                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* ------------ Mapping ends here ------------ */}

                  {/* pagination */}
                  <div className="pagination_list">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page !== 0) {
                              nextPage(page - 1);
                              courseRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_back_ios
                          </i>
                        </Link>
                      </li>
                      {totalPages.map((pageNo, index) => {
                        return (
                          <li
                            className={page + 1 === pageNo ? "active" : ""}
                            key={index}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                nextPage(pageNo - 1);
                                courseRefToggle();
                              }}
                            >
                              {pageNo}
                            </Link>
                          </li>
                        );
                      })}

                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page != lastPageNo - 1) {
                              nextPage(page + 1);
                              courseRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_forward_ios
                          </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("Course are not available at this moment.")}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CourseListArea;

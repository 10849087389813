import React from "react";

// importing custom components
import Header from "common/Header";
import Breadcrumb from "components/ContactComponents/Breadcrumb";
import ContactFormLeftPanel from "components/ContactComponents/ContactFormLeftPanel";
import ContactFormRightPanel from "components/ContactComponents/ContactFormRightPanel";
import Footer from "common/Footer";
import SentModal from "common/Modals/SentModal";

const Contact = () => {
  return (
    <>
      <Header />
      <Breadcrumb />
      <section id="contact_wrapper" className="position-relative">
        <div className="container">
          <div className="row">
            <ContactFormLeftPanel />
            <ContactFormRightPanel />
          </div>
        </div>
      </section>
      <Footer />
      <SentModal />
    </>
  );
};

export default Contact;

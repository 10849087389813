/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { postData_v2 } from "utils/Gateway"; // import methods from gatway for API call
import * as url from "helpers/url_helper"; // import all URLs from url_helper

const CreateNewGroupModal = ({
  allUserContacts = [],
  afterModalClose = () => {},
}) => {
  const token = localStorage.getItem("token");

  const [searchValue, setSearchValue] = useState("");

  const showFilteredContacts =
    searchValue.length > 0 || searchValue !== ""
      ? allUserContacts.filter((contact) =>
          contact.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : allUserContacts;

  const [groupTitle, setGroupTitle] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]); // state to keep track of selected contacts
  const [isCreating, setIsCreating] = useState(false);

  const [validation, setvalidation] = useState({
    groupTitleWarning: false,
    groupMemberWarning: false,
  });

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    if (groupTitle == "") {
      setvalidation((prevState) => ({
        ...prevState,
        groupTitleWarning: true,
      }));

      isValid = false;
    }

    if (selectedContacts.length === 0) {
      setvalidation((prevState) => ({
        ...prevState,
        groupMemberWarning: true,
      }));

      isValid = false;
    }

    return isValid;
  };

  //function for select contact with toggle
  const contactSelectionHandler = (contact, index) => {
    setvalidation((prevState) => ({
      ...prevState,
      groupMemberWarning: false,
    }));

    setSelectedContacts((prevSelectedContacts) => {
      const isAlreadySelected = prevSelectedContacts.some(
        (selectedContact) =>
          selectedContact.toString() === contact.userid.toString()
      );

      if (isAlreadySelected) {
        // If already selected, remove it
        return prevSelectedContacts.filter(
          (selectedContact) =>
            selectedContact.toString() !== contact.userid.toString()
        );
      } else {
        // If not selected, add it
        return [...prevSelectedContacts, contact.userid];
      }
    });
  };

  //function for create group
  const createGroupHandler = async () => {
    if (validationHandler()) {
      try {
        setIsCreating(true);

        let chatData = {
          title: groupTitle,
          contactids: selectedContacts,
        };

        console.log(chatData);

        let requestURL =
          url.API_BASE_URL + url.API_CREATE_GROUP_BY_CHAT + `?token=${token}`;

        // console.log(requestURL);

        const response = await postData_v2(requestURL, chatData);

        setIsCreating(false);

        console.log(response);

        if (response.status) {
          resetHandler();
          //hide modal
          let myModal = document.querySelector("#group_people_mdl");
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();
          afterModalClose(response.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setSelectedContacts([]);
    setGroupTitle("");
    setvalidation({
      groupTitleWarning: false,
      groupMemberWarning: false,
    });
  };

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade"
        id="group_people_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              {/* <!-- <h5 className="modal-title">Add to Group</h5>
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span className="material-icons-round">close </span>
                  </button> --> */}
              <div className="enter_group_name">
                <input
                  type="text"
                  className={`form-control text-black px-3 py-2 ${
                    validation.groupTitleWarning ? "border-danger" : ""
                  }`}
                  placeholder="Enter Group Name"
                  value={groupTitle}
                  onChange={(e) => {
                    setGroupTitle(e.target.value);
                    setvalidation((prevState) => ({
                      ...prevState,
                      groupTitleWarning: false,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="modal-body">
              <div className="group_srchfill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for people"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="group_innrScroll">
                {showFilteredContacts.map((contact, index) => {
                  const isSelected = selectedContacts.some(
                    (selectedContact) =>
                      selectedContact.toString() === contact.userid.toString()
                  );

                  return (
                    <div key={index} className="chat_usrRow cursor-pointer">
                      <Link
                        to="#"
                        onClick={() => {
                          contactSelectionHandler(contact, index);
                        }}
                      >
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img
                              src={
                                contact.image === ""
                                  ? "/images/default-user.png"
                                  : url.SERVER_URL + contact.image
                              }
                              alt=""
                            />
                          </div>
                          <div className="user_name">
                            <h3>{contact.name}</h3>
                          </div>
                        </div>
                        {isSelected ? (
                          <div className="members_check blue_bullet">
                            <span></span>
                          </div>
                        ) : null}
                      </Link>
                    </div>
                  );
                })}
              </div>

              {validation.groupTitleWarning && (
                <p className="text-danger m-3">
                  <span className="material-icons-outlined me-1">info</span>
                  Please enter group title
                </p>
              )}

              {validation.groupMemberWarning && (
                <p className="text-danger m-3">
                  <span className="material-icons-outlined me-1">info</span>
                  Please select one member
                </p>
              )}

              <div className="m-3 d-flex align-items-center gap-4 add_group_btn">
                <button
                  className="btn blue_btn w-100 d-flex align-items-center justify-content-center gap-2"
                  onClick={createGroupHandler}
                  disabled={isCreating ? true : false}
                  style={{
                    cursor: isCreating ? "not-allowed" : "pointer",
                  }}
                >
                  {isCreating ? (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <i className="material-icons-round">check_circle</i>
                  )}

                  <span>Done</span>
                </button>

                <button
                  className="btn ornage_btn w-100 d-flex align-items-center justify-content-center gap-2"
                  data-bs-dismiss="modal"
                  onClick={resetHandler}
                >
                  <i className="material-icons-round">cancel</i>
                  <span>Exit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewGroupModal;

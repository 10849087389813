/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";
import { t } from "i18next";

// import Gateway for API fetch call
import { getData, putData_v2 } from "utils/Gateway";

// importing images here
import defaultUser from "../../assets/ActivityAssests/images/default-user.png";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import AddTagsModal from "components/GroupDetailsComponents/Modals/AddTagsModal";
import ThanksJoinGroupModal from "components/GroupDetailsComponents/Modals/ThanksJoinGroupModal";

const GroupDetailsTopSection = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const {
    setGroupName,
    setIsApproved,
    setReviewPermission,
    afterReviewGroup,
    setAfterReviewGroup,
    setReloadMemberTab,
    setReloadGroupForumTab,
    setReloadGroupReviewTab,
  } = useContext(GlobalIndraInfo);

  // initials for group details
  const [groupDetailsData, setGroupDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); //for glow placeholder
  const [isJoining, setIsJoining] = useState(false);
  const [moderatorStatus, setModeratorStatus] = useState(false);

  const getGroupDetails = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GROUP_DETAILS_TOP +
      `/${params.id}?token=${token}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          setGroupDetailsData(response.data);
          setGroupName(response.data.name);
          if (
            response.data.groupmodertorid.toString() === userInfo._id.toString()
          ) {
            setReviewPermission(true);
          } else {
            if (
              response.data.moderatorstatus === "1" &&
              response.data.isapproved
            ) {
              setReviewPermission(true);
              setIsApproved(true);
            } else {
              setReviewPermission(false);
              setIsApproved(false);
            }
          }
        } else {
          setGroupDetailsData(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to join the Event using API
  const joinGroup = async () => {
    try {
      setIsJoining(true);

      let apiData = {
        group: params.id,
        useremail: userInfo.email,
      };

      console.log(apiData);

      let requestURL =
        url.API_BASE_URL + url.API_JOIN_GROUP + `?token=${token}`;

      console.log(requestURL);

      const response = await putData_v2(requestURL, apiData);

      setIsJoining(false);

      console.log(response);

      if (response.status) {
        if (response.data.moderatorstatus === "1") {
          setModeratorStatus(true);
        } else {
          setModeratorStatus(false);
        }
        let myModal = new bootstrap.Modal(
          document.getElementById("group_join_success_modal")
        );
        myModal.show();
      }
    } catch (error) {}
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getGroupDetails();
    window.scrollTo(0, 0);
  };

  // function for calling API after closing thank you modal
  const afterThankYouModalClose = () => {
    setReloadMemberTab(true);
    setReloadGroupForumTab(true);
    setReloadGroupReviewTab(true);
    getGroupDetails();
    window.scrollTo(0, 0);
  };

  // reload from review tab
  useEffect(() => {
    if (afterReviewGroup) {
      getGroupDetails(params.id);
      setAfterReviewGroup(false);
    }
  }, [afterReviewGroup]);

  // url based use effect
  useEffect(() => {
    if (params.id) {
      getGroupDetails();
    }
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="course_details_sec placeholder-glow">
          {/* ---- glow more area start ---- */}
          <div className="container">
            <div className="course_detailsBx">
              <div className="topdetails_left">
                <div className="expertholder_outer">
                  <div className="experts_holdertext flex-fill">
                    <span className="placeholder placeholder-sm rounded-2 col-6 mb-3"></span>
                    <span
                      className="placeholder rounded-3 col-8"
                      style={{ height: "26px" }}
                    ></span>
                    <div className="details_tags expert_details">
                      <span
                        className="placeholder placeholder-md rounded-2 mb-3"
                        style={{ width: "142px" }}
                      ></span>
                    </div>

                    <div className="experts_skill">
                      <ul>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="vdo_link">
                      <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topdetails_right">
                <div className="asign_top">
                  <div className="asign_outer">
                    <div className="asign_item">
                      <span
                        className="placeholder placeholder-md rounded-2 me-3"
                        style={{ width: "120px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="exprt_share traning_share">
                    <ul>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- glow more area end ---- */}
        </section>
      ) : (
        <section className="course_details_sec">
          {groupDetailsData == null ? null : (
            <div className="container">
              <div className="course_detailsBx">
                {/* ----- group details left section start here ----- */}
                <div className="topdetails_left">
                  <div className="expertholder_outer">
                    <div className="experts_holdertext">
                      <h5>{groupDetailsData.title}</h5>
                      <h3>{groupDetailsData.title}</h3>
                      <div className="details_tags expert_details">
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined"> star </i>
                          <span>{groupDetailsData.grouprating}</span>
                          <h6>
                            ({groupDetailsData.reviewcount}
                            <em className="mx-1">{t("reviews")}</em>)
                          </h6>
                        </div>
                      </div>

                      {/* ----- group details category start here ----- */}
                      {groupDetailsData.categorylist ? (
                        <div className="experts_skill">
                          {groupDetailsData.categorylist.length > 0 && (
                            <ul>
                              {groupDetailsData.categorylist.map(
                                (item, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#">
                                        <span>{item.label}</span>
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                        </div>
                      ) : null}
                      {/* ----- group details category end here ----- */}

                      {/* ----- group details members start here ----- */}
                      {groupDetailsData.groupmembers ? (
                        <div className="ambassadors_users grps_user">
                          {groupDetailsData.groupmembers.length > 0 && (
                            <ul>
                              {groupDetailsData.groupmembers.map(
                                (member, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#">
                                        <img
                                          src={
                                            member.userimage === ""
                                              ? defaultUser
                                              : url.SERVER_URL +
                                                member.userimage
                                          }
                                          alt="member"
                                        />
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                              {groupDetailsData.groupmembers.length <=
                              5 ? null : (
                                <li className="group_member_more_text">
                                  + {groupDetailsData.groupmembers.length - 5}{" "}
                                  {t("more")}
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                      ) : null}
                      {/* ----- group details members end here ----- */}

                      {/* ----- group details summary start here ----- */}
                      {/* <div className="training_para">
                        <p>{groupDetailsData.summary}</p>
                      </div> */}
                      {/* ----- group details summary end here ----- */}
                    </div>
                  </div>
                </div>
                {/* ----- group details left section end here ----- */}

                {/* ----- group details right section start here ----- */}
                <div className="topdetails_right d-flex flex-column align-items-end justify-content-between">
                  <div className="asign_top">
                    <div className="asign_outer">
                      <div className="asign_item">
                        <h4>
                          {t("Moderator")}:{" "}
                          <span>{groupDetailsData.moderatorname}</span>
                        </h4>
                      </div>
                    </div>

                    <div className="exprt_share traning_share">
                      <ul>
                        <li>
                          {/* --- add tag modal start here ---- */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#group_add_tag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                          {/* --- add tag modal end here ---- */}
                        </li>
                        {/* <li>
                      <Link to="#">
                        <i className="material-icons-outlined">share</i>
                      </Link>
                    </li> */}
                      </ul>
                    </div>
                  </div>

                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}

                    {groupDetailsData.taglist.length > 0 ? (
                      <ul className="tags_list">
                        {groupDetailsData.taglist
                          .slice(0, 3)
                          .map((tags, index) => {
                            return (
                              <li key={index}>
                                <Link to="#">{tags.title}</Link>
                              </li>
                            );
                          })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {groupDetailsData.taglist.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {groupDetailsData.taglist.length > 0 ? (
                          <ul className="dropdown-menu">
                            {groupDetailsData.taglist
                              .slice(3, groupDetailsData.taglist.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.name}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}

                  {/* ----- Display join date by condition ----- */}
                  {groupDetailsData.groupmodertorid.toString() ===
                  userInfo._id.toString() ? null : (
                    <div className="joining_section">
                      {groupDetailsData.isjoined ? (
                        groupDetailsData.moderatorstatus === "1" ? (
                          <p className="text-end text-white join-event-request">
                            {t("Joined Date")} :{" "}
                            {groupDetailsData.approvedate
                              ? groupDetailsData.approvedate
                              : null}{" "}
                            <br />
                            {groupDetailsData.usergrouprole === "" ? null : (
                              <span>
                                {t("Role :")}
                                {groupDetailsData.usergrouprole}
                              </span>
                            )}
                          </p>
                        ) : (
                          <p className="text-end text-white join-event-request">
                            <i className="material-icons me-2">error</i>
                            {t("Moderator Response Pending")} <br />
                            {t("Request Date")} : {groupDetailsData.requestdate}
                          </p>
                        )
                      ) : null}
                    </div>
                  )}

                  {/* ----- display join button by condition ----- */}

                  {groupDetailsData.groupmodertorid.toString() ===
                    userInfo._id.toString() ||
                  userInfo.role.slug === "ADMIN" ||
                  userInfo.role.slug === "SUPER_ADMIN" ||
                  groupDetailsData.isapproved ? null : groupDetailsData.isjoined ? (
                    groupDetailsData.memberstatus === "0" ? (
                      <div className="join_section">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn w-auto mt-1"
                          onClick={joinGroup}
                          style={{ pointerEvents: isJoining ? "none" : "" }}
                        >
                          {t("Join Now")}
                          {isJoining && (
                            <div
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </Link>
                      </div>
                    ) : null
                  ) : (
                    <div className="join_section">
                      <Link
                        to="#"
                        className="basic_btn ylw_btn w-auto mt-1"
                        onClick={joinGroup}
                        style={{ pointerEvents: isJoining ? "none" : "" }}
                      >
                        {t("Join Now")}
                        {isJoining && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </Link>
                    </div>
                  )}
                </div>
                {/* ----- group details right section end here ----- */}
              </div>
            </div>
          )}
        </section>
      )}
      <AddTagsModal afterModalClose={afterModalClose} />
      <ThanksJoinGroupModal
        afterThankYouModalClose={afterThankYouModalClose}
        moderatorStatus={moderatorStatus}
        groupDetailsData={groupDetailsData}
      />
    </>
  );
};

export default GroupDetailsTopSection;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import helper functions
import * as url from "helpers/url_helper";

// import Gateway
import { getData_v2 } from "utils/Gateway";
import downloadFileHandler from "helpers/downloadfile";
import ShowFaqImageModal from "../Modal/ShowFaqImageModal";

const FaqDetails = () => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { id: selectedFaqId } = useParams();

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  const [tags, settags] = useState([]);
  const [experts, setexperts] = useState([]);
  const [courses, setcourses] = useState([]);
  const [blogPosts, setblogPosts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [displayImagePath, setdisplayImagePath] = useState("");

  //get faq details
  const getFaqDetails = async () => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData_v2(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        const data = response.data;
        settitle(data.question);
        setdetails(data.answer);

        settags(data.taglist);
        setcourses(data.courselist);
        setexperts(data.expertlist);
        setblogPosts(data.postlist);

        setMedia(
          data.uploadedfiles.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedFaqId) {
      getFaqDetails();
    }
  }, [selectedFaqId]);

  return (
    <div>
      {/* Faq details */}
      {isLoading ? (
        <h1 className="placeholder-glow">
          <span className="placeholder col-7"></span>
        </h1>
      ) : (
        <h1>{title}</h1>
      )}

      {isLoading ? (
        <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3 placeholder-glow">
          {" "}
          <li className="">Tags</li>
          {[1, 2].map((tag, index) => {
            return (
              <li key={index} className="tag p-3 rounded-8">
                <span className="placeholder col-12"></span>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
          {tags.length > 0 && <li className="fw-bold">Tags</li>}
          {tags.length > 0 && (
            <span className="material-icons-outlined">sell</span>
          )}
          {tags.map((tag, index) => {
            return (
              <li className="tag px-3 py-2 rounded-8" key={index}>
                {tag.title}
              </li>
            );
          })}
        </ul>
      )}

      {isLoading ? (
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
        </p>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: details }}></p>
      )}

      {media.length > 0 && <h6 className="mb-3 mt-4">Attach File</h6>}

      {/* <!-- ========== Start media Section ========== --> */}
      <div className="d-flex gap-2 flex-wrap">
        {media.map((file, index) => {
          const fileExtension = file.name.split(".").pop();

          return (
            <div className="bg-gray position-relative" key={index}>
              {fileExtension === "jpg" ||
              fileExtension === "png" ||
              fileExtension === "jpeg" ||
              fileExtension === "gif" ||
              fileExtension === "png" ? (
                <figure
                  className="h-100 w-100 position-relative"
                  data-bs-toggle="modal"
                  data-bs-target="#imageModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setdisplayImagePath(file.path);
                  }}
                >
                  <img
                    src={file.path}
                    alt="1737112588-cryp...jpg"
                    style={{ maxWidth: "250px" }}
                  />
                </figure>
              ) : fileExtension === "pdf" ? (
                <>
                  <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                    <img
                      src="/static/media/PDF.536815d4f3debb99d912.jpg"
                      alt="pdf"
                      style={{ width: "100px" }}
                    />
                  </span>
                  <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                    <div className="left_info">
                      <p className="text-dark-primary fs-sm fw-regular">
                        {file.name}
                      </p>
                    </div>
                    <Link
                      to="#"
                      className="d-inline-block text-gray fs-md"
                      onClick={() => {
                        downloadFileHandler(file);
                      }}
                    >
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                    </Link>
                  </div>
                </>
              ) : fileExtension === "xlsx" ? (
                <>
                  <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                    <img
                      src="/static/media/XLS.ac278bfc3ee8d150a35b.jpg"
                      alt="xlsx"
                      style={{ width: "100px" }}
                      onClick={() => {
                        downloadFileHandler(file);
                      }}
                    />
                  </span>
                  <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                    <div className="left_info">
                      <p className="text-dark-primary fs-sm fw-regular">
                        {file.name}
                      </p>
                    </div>
                    <Link to="#" className="d-inline-block text-gray fs-md">
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                    </Link>
                  </div>
                </>
              ) : fileExtension === "doc" || fileExtension === "txt" ? (
                <>
                  <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                    <img
                      src="/static/media/DOC.78f519abd146af852e05.jpg"
                      alt="doc"
                      style={{ width: "100px" }}
                      onClick={() => {
                        downloadFileHandler(file);
                      }}
                    />
                  </span>
                  <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                    <div className="left_info">
                      <p className="text-dark-primary fs-sm fw-regular">
                        {file.name}
                      </p>
                    </div>
                    <Link to="#" className="d-inline-block text-gray fs-md">
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                    </Link>
                  </div>
                </>
              ) : fileExtension === "mp4" || fileExtension === "mov" ? (
                <figure className="h-100 w-100 position-relative">
                  <video
                    controls
                    className="w-100"
                    style={{ maxWidth: "500px" }}
                  >
                    <source src={file.path} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </figure>
              ) : null}
            </div>
          );
        })}
      </div>
      {/* <!-- ========== End media Section ========== --> */}

      {/* related Faqs */}
      <div className="relate-to mt-5">
        {courses.length > 0 && blogPosts.length > 0 && experts.length > 0 ? (
          <h3 className="mb-3">Related to:</h3>
        ) : null}

        {courses.length > 0 && <h6 className="mb-2">Course</h6>}

        {isLoading ? (
          <ul className="placeholder-glow mb-3">
            <li>
              <Link
                to="#"
                target="_blank"
                className="d-flex align-items-center gap-2 placeholder-glow"
              >
                <span className="d-block material-icons-outlined">link</span>
                <span className="placeholder col-6"></span>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                target="_blank"
                className="d-flex align-items-center gap-2 placeholder-glow"
              >
                <span className="d-block material-icons-outlined">link</span>
                <span className="placeholder col-6"></span>
              </Link>
            </li>
          </ul>
        ) : (
          <ul className="mb-3">
            {courses.map((course, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/course/details/${course._id}`}
                    target="_blank"
                    className="d-flex align-items-center gap-2"
                  >
                    <span className="d-block material-icons-outlined">
                      link
                    </span>
                    <span className="d-block text-decoration-underline">
                      {course.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}

        {blogPosts.length > 0 && <h6 className="mb-2">Post</h6>}

        {isLoading ? (
          <ul className="placeholder-glow mb-3">
            <li>
              <Link
                to="#"
                target="_blank"
                className="d-flex align-items-center gap-2 placeholder-glow"
              >
                <span className="d-block material-icons-outlined">link</span>
                <span className="placeholder col-6"></span>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                target="_blank"
                className="d-flex align-items-center gap-2 placeholder-glow"
              >
                <span className="d-block material-icons-outlined">link</span>
                <span className="placeholder col-6"></span>
              </Link>
            </li>
          </ul>
        ) : (
          <ul className="mb-3">
            {blogPosts.map((post, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/blog/${post.slug}`}
                    className="d-flex align-items-center gap-2"
                    target="_blank"
                  >
                    <span className="d-block material-icons-outlined">
                      link
                    </span>
                    <span className="d-block text-decoration-underline">
                      {post.name}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}

        {experts.length > 0 && <h6 className="mb-2">Experts</h6>}

        {isLoading ? (
          <ul className="placeholder-glow mb-3">
            <li>
              <Link
                to="#"
                target="_blank"
                className="d-flex align-items-center gap-2 placeholder-glow"
              >
                <span className="d-block material-icons-outlined">link</span>
                <span className="placeholder col-6"></span>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                target="_blank"
                className="d-flex align-items-center gap-2 placeholder-glow"
              >
                <span className="d-block material-icons-outlined">link</span>
                <span className="placeholder col-6"></span>
              </Link>
            </li>
          </ul>
        ) : (
          <ul>
            {experts.map((expert, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/champions/details/${expert._id}`}
                    target="_blank"
                    className="d-flex align-items-center gap-2"
                  >
                    <span className="d-block material-icons-outlined">
                      link
                    </span>
                    <span className="d-block text-decoration-underline">
                      {expert.name ?? expert.email} {expert.surname ?? ""}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <ShowFaqImageModal
        displayImagePath={displayImagePath}
        setdisplayImagePath={setdisplayImagePath}
      />
    </div>
  );
};

export default FaqDetails;

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const HomeCourseLists = () => {
  const [courseLists, setCourseLists] = useState([]); // initial for course lists
  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  const dummyCourseList = [
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 1,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 2,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 3,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 4,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
  ];

  const { t } = useTranslation();
  // for routing
  const history = useHistory();

  const glowCount = [1, 2, 3];

  // saving user token here
  const token = localStorage.getItem("token");

  //resonsive properties for react multi carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // function to get all course lists
  const getAllCourseLists = async () => {
    setIsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_COURSE_LIST +
        `?token=${token}&modulefrom=frontend` +
        `&start=${0}&limit=10`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          if (response.data.length <= 3) {
            setCourseLists([...response.data, ...dummyCourseList]);
          } else {
            setCourseLists(response.data.slice(0, 10));
          }
        } else {
          setCourseLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function to go to the details page
  const goToCourseDetails = (e, id, isTraining = false) => {
    e.preventDefault();
    if (isTraining) {
      history.push(`/coursetraining/details/${id}`);
    } else {
      history.push(`/course/details/${id}`);
    }
  };

  // useeeffect for get all course lists
  useEffect(() => {
    getAllCourseLists();
  }, []);

  return (
    <section className="course_area">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>{t("Best Courses")}</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="/courses" className="border_btn ylwbrdr_btn">
              {t("See All")}
            </Link>
          </div>
        </div>
        {/* check the the loading condition */}
        {isLoading ? (
          <div className="slide_Outer">
            <div className="course_slider slider_innr">
              <Carousel ssr responsive={responsive}>
                {glowCount.map((count) => {
                  return (
                    <div className="slide_Item mb-4" key={count}>
                      <div className="course_item">
                        <div className="champions_pic">
                          <img src={blankImage} alt="blankImage" />
                        </div>
                        <div className="plan p-3 border rounded-3 placeholder-glow">
                          <div className="user_placeHoledr">
                            <div className="default_usr_name">
                              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            </div>
                          </div>
                          <div className="data_btnOuter">
                            <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                          </div>
                          <p className="data_btnOuter placeholder-glow">
                            <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          </p>
                          <div className="data_btnOuter">
                            <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        ) : (
          <div className="slide_Outer">
            {/* check the the courselists condition */}
            {courseLists.length > 0 && (
              <div className="course_slider slider_innr">
                {/* check the the courselists condition greater then 10 or not */}

                <Carousel ssr showDots responsive={responsive}>
                  {courseLists.map((course, index) => {
                    return (
                      <div className="slide_Item mb-4" key={index}>
                        {course.hideButton ? (
                          <div className="course_item">
                            <div className="champions_pic">
                              <img src={blankImage} alt="blankImage" />
                            </div>
                            <div className="plan p-3 border rounded-3 placeholder-glow">
                              <div className="user_placeHoledr">
                                <div className="default_usr_name">
                                  <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                  <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                </div>
                              </div>
                              <div className="data_btnOuter">
                                <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                              </div>
                              <p className="data_btnOuter placeholder-glow">
                                <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                              </p>

                              <div className="data_btnOuter">
                                <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                              </div>

                              <div className="details_btn">
                                <button
                                  to="#"
                                  disabled={true}
                                  className="placeholder basic_btn ylw_btn"
                                >
                                  <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="course_item">
                            <div className="champions_pic">
                              <img
                                src={
                                  course.courselogo === ""
                                    ? blankImage
                                    : url.SERVER_URL + course.courselogo
                                }
                                alt=""
                              />
                            </div>
                            <div className="champions_textpnl">
                              <div className="course_hdngauthor">
                                <h4>{course.moderator}</h4>
                                <div className="chappions_rating">
                                  <Link to="#">
                                    <i className="material-icons-outlined">
                                      star
                                    </i>
                                    <span>{course.totalratings}</span>
                                    <em>({course.totalreviews})</em>
                                  </Link>
                                </div>
                              </div>
                              <div className="leftchampion_text">
                                <h3>{course.title}</h3>
                              </div>
                              <div className="experts_skill">
                                {course.categorylist.length === 0 ? null : (
                                  <ul>
                                    {course.categorylist.map((item, index) => (
                                      <li key={index}>
                                        <Link to="#">
                                          <span>{item.name}</span>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>

                              <div className="details_btn">
                                <Link
                                  to="#"
                                  className="basic_btn ylw_btn"
                                  onClick={(e) =>
                                    goToCourseDetails(e, course._id, false)
                                  }
                                >
                                  {t("See Details")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeCourseLists;

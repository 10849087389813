/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// import images here
import coursePic1 from "assets/EventsAssets/images/course-pic1.jpg";
import coursePic2 from "assets/EventsAssets/images/course-pic2.jpg";
import coursePic3 from "assets/EventsAssets/images/course-pic3.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const EventsListArea = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    searchEventsList,
    eventTitle,
    eventCity,
    eventCategory,
    refreshEventList,
    refreshEventsLists,
    resetEventTitleOnly,
    resetEventCategoryOnly,
    resetEventLocationOnly,
    deleteSingleEvent,
    eventFilterValue,
    resetEventFilterOnly,
    eventPageReset,
    setEventPageReset,
  } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  const eventListRef = useRef(null);

  // array for counting glow rows
  const glowCount = [1, 2, 3];

  // initial useState for API data
  const [eventsData, setEventsData] = useState([]);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(0);
  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  // function for all events lists
  const getEventsLists = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_EVENT_LIST +
        `?token=${token}&modulefrom=frontend&start=${page * 12}&limit=12`;

      if (eventTitle != "") {
        requestURL = requestURL + `&filtertitle=${eventTitle}`;
      }

      if (eventCity != "") {
        requestURL = requestURL + `&filteraddress=${eventCity}`;
      }

      if (eventCategory !== null && eventCategory.length > 0) {
        const categories = eventCategory.map((category) => category.value);
        requestURL += `&filtercategory=${categories.join(",")}`;
      }

      if (eventFilterValue != null) {
        requestURL = requestURL + `&selectedfilter=${eventFilterValue.value}`;
      }

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setEventsData(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/
        } else {
          setEventsData([]);
        }
        setIsLoading(false);
      } else {
        setEventsData([]);
      }
    } catch (error) {}
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for toggle eventRef
  const eventRefToggle = () => {
    eventListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //function reset event title
  const resetEventTitle = () => {
    resetEventTitleOnly(true);
    setPage(0);
  };

  //function for reset event category only
  const resetEventCategory = () => {
    resetEventCategoryOnly(true);
    setPage(0);
  };

  //function for reset event location only
  const resetEventLocation = () => {
    resetEventLocationOnly(true);
    setPage(0);
  };

  //function for reset event filter value only
  const resetEventFilter = () => {
    resetEventFilterOnly(true);
    setPage(0);
  };

  //function for delete single event category
  const deleteSingleValue = (index) => {
    if (index === 0) {
      setPage(0);
    }
    let deletedEventCategory = [...eventCategory];
    deletedEventCategory.splice(index, 1);
    deleteSingleEvent(deletedEventCategory);
  };

  useEffect(() => {
    if (eventPageReset) {
      setPage(0);
      setEventPageReset(false);
    }
  }, [eventPageReset]);

  //use Effect for search event query
  useEffect(() => {
    if (
      eventTitle != "" ||
      eventCity != "" ||
      eventCategory.length > 0 ||
      eventFilterValue != null
    ) {
      getEventsLists();
      searchEventsList(null);
    } else {
      getEventsLists();
    }
  }, [page, eventTitle, eventCity, eventCategory, eventFilterValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div ref={eventListRef} name="scrollEventRefDiv" />
      <section className="champions_area">
        {/* ------------- Filter Section starts here ------------- */}

        {eventTitle != "" ||
        eventCategory.length > 0 ||
        eventCity != "" ||
        eventFilterValue != null ? (
          <div id="filter_result" className="mb-4">
            <div className="container">
              <div className="d-flex flex-wrap gap-2">
                {/* ---------- Search Title Section starts here ---------- */}

                {eventTitle == "" ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Name")}
                      </label>
                      {/* <input
                  type="text"
                  // value="Victor Herrero"
                  className="form-control shadow-none rounded-0"
                /> */}
                      <label>{eventTitle}</label>
                      <Link to="#" className="cancel" onClick={resetEventTitle}>
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ---------- Search Skills Section starts here ---------- */}

                {eventCategory.length == 0 ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Category")}
                      </label>
                      <ul className="badges d-flex flex-wrap gap-2">
                        {eventCategory.map((eventNames, index) => {
                          return (
                            <li className="d-flex gap-2 border" key={index}>
                              {eventNames.label}
                              <Link
                                to="#"
                                onClick={() => deleteSingleValue(index)}
                              >
                                <i className="d-block material-icons">clear</i>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetEventCategory}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ---------- Search Location Section starts here ---------- */}

                {eventCity == "" ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Location")}
                      </label>
                      <label>{eventCity}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetEventLocation}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ----- searched event filter value ------- */}
                {eventFilterValue == null ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Type")}
                      </label>
                      <label>{eventFilterValue.label}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetEventFilter}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {/* ------------- Filter Section ends here ------------- */}

        <div className="container">
          {isLoading ? (
            <div className="champions_outer">
              {/* ----------------- Glow placeholder starts here ----------------- */}
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-4 col-sm-6" key={count}>
                      <div className="events_itemBx">
                        <div className="champions_pic">
                          <span
                            className="placeholder col-12"
                            style={{ height: "197px" }}
                          ></span>
                        </div>
                        <div className="champions_textpnl">
                          <div className="events_dateBx">
                            <span className="placeholder col-12 h-100 mt-1"></span>
                          </div>
                          <div className="events_hdngauthor">
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                          </div>
                          <div className="leftchampion_text">
                            <span
                              className="placeholder rounded-2 col-6 mt-2"
                              style={{ height: "20px" }}
                            ></span>
                          </div>
                          <div className="experts_skill">
                            <ul>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                          <div className="details_btn">
                            <span
                              className="placeholder rounded-3 col-12"
                              style={{ height: "50px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* ----------------- Glow placeholder ends here ----------------- */}
            </div>
          ) : (
            <div className="champions_outer">
              {eventsData.length == 0 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={blankLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>{t("Sorry....!")}</h2>
                        <p>{t("Events are not available for this moment.")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {/* ------------ Mapping starts here ------------ */}
                  {eventsData.map((event, index) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={index}>
                        <div className="events_itemBx">
                          <div className="champions_pic">
                            <img
                              src={
                                event.eventlogo === ""
                                  ? coursePic1
                                  : url.SERVER_URL + event.eventlogo
                              }
                              alt=""
                            />
                          </div>
                          <div className="champions_textpnl">
                            <div className="events_dateBx">
                              <h3>{event.startday}</h3>
                              <h5>
                                {event.startmonth}{" "}
                                <span>{event.startyear}</span>
                              </h5>
                            </div>
                            <div className="events_hdngauthor">
                              <h4>
                                {event.address !== ""
                                  ? event.address
                                  : t("undisclosed")}
                              </h4>
                              <div className="evnts_enroll">
                                <i className="material-icons-outlined">
                                  {" "}
                                  people{" "}
                                </i>
                                <span>
                                  {event.eventmembercount}
                                  {t("Enrolled")}
                                </span>
                              </div>
                            </div>
                            <div className="leftchampion_text">
                              <h3>
                                {event.title !== ""
                                  ? event.title
                                  : t("undisclosed")}
                              </h3>
                            </div>
                            <div className="experts_skill">
                              <ul>
                                {event.eventcategorylist.map(
                                  (category, index) => (
                                    <li key={index}>
                                      <Link to="#">
                                        <span>{category.name}</span>
                                      </Link>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>

                            <div className="details_btn">
                              <Link
                                to={`/events/details/${event._id}`}
                                className="basic_btn ylw_btn"
                              >
                                {event.moderatorid.toString() ===
                                  userInfo._id.toString() ||
                                userInfo.role.slug === "SUPER_ADMIN" ||
                                userInfo.role.slug === "ADMIN" ? (
                                  <i className="material-icons me-3">
                                    done_all
                                  </i>
                                ) : (
                                  <span>
                                    {event.isjoined ? (
                                      <span>
                                        {event.isapproved ? (
                                          <i className="material-icons me-3">
                                            done_all
                                          </i>
                                        ) : (
                                          <i className="material-icons me-3">
                                            done
                                          </i>
                                        )}
                                      </span>
                                    ) : null}
                                  </span>
                                )}{" "}
                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* ------------ Mapping ends here ------------ */}

                  {/* ------------ Pagination starts here ------------ */}
                  {eventsData.length === 0 ? null : (
                    <div className="pagination_list">
                      <ul>
                        <li>
                          <Link
                            to="#"
                            onClick={() => {
                              if (page !== 0) {
                                nextPage(page - 1);
                                eventRefToggle();
                              }
                            }}
                          >
                            <i className="material-icons-outlined">
                              arrow_back_ios
                            </i>
                          </Link>
                        </li>
                        {totalPages.map((pageNo) => {
                          return (
                            <li
                              className={page + 1 === pageNo ? "active" : ""}
                              key={pageNo}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  nextPage(pageNo - 1);
                                  eventRefToggle();
                                }}
                              >
                                {pageNo}
                              </Link>
                            </li>
                          );
                        })}

                        <li>
                          <Link
                            to="#"
                            onClick={() => {
                              if (page != lastPageNo - 1) {
                                nextPage(page + 1);
                                eventRefToggle();
                              }
                            }}
                          >
                            <i className="material-icons-outlined">
                              arrow_forward_ios
                            </i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                  {/* ------------ Pagination ends here ------------ */}
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default EventsListArea;

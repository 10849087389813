/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      {" "}
      {/* // remember to give it position:absolute */}
      <button
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => previous()}
      />
      <button
        onClick={() => {
          // goToSlide(currentSlide - 1);
          previous();
        }}
        className="prevSlide btn btn-warning"
      >
        <span className="material-icons-outlined">arrow_back_ios</span>
      </button>
      <button
        onClick={() => {
          // goToSlide(currentSlide + 1);
          next();
        }}
        className="nextSlide btn btn-warning mx-1"
      >
        <span className="material-icons-outlined">arrow_forward_ios</span>
      </button>
    </div>
  );
};

const ProcessTab = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  const glowCount = [1, 2, 3, 4]; //for glow placeholder

  const processResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  //all initials
  const [processLists, setProcessLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProcessData = async (id) => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_CHALLENGES_DETAILS_PROCESS +
        `?token=${token}&challenge=${id}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setProcessLists(response.data);
      }
    } catch (error) {}
  };

  //use effect start
  useEffect(() => {
    getProcessData(params.id);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="course_detailspnl">
          {/* <div className="contnt_hdng">
            <span
              className="placeholder rounded-3 col-1"
              style={{ height: "22px" }}
            ></span>
          </div> */}
          <div className="details_processOuter">
            <div className="details_procesItem">
              {/* <div className="proces_hdng">
                <span
                  className="placeholder rounded-3 col-2"
                  style={{ height: "18px" }}
                ></span>
              </div> */}
              <div className="team_outer">
                <div className="row">
                  {glowCount.map((count) => {
                    return (
                      <div className="col-lg-3 col-sm-6" key={count}>
                        <div className="expertbx_item members_teamItem">
                          <div className="membrs_teamOuter">
                            <div className="team_membrs_pic">
                              <span
                                className="placeholder rounded-circle"
                                style={{ width: "40px", height: "40px" }}
                              ></span>
                            </div>
                            <div className="membrs_team_details flex-fill">
                              <span
                                className="placeholder d-block rounded-3 col-8 mb-3"
                                style={{ height: "20px" }}
                              ></span>
                              <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                              <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                              <span className="placeholder placeholder-sm d-block rounded-3 col-8"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className="details_procesItem">
              <div className="proces_hdng">
                <span
                  className="placeholder rounded-3 col-2"
                  style={{ height: "18px" }}
                ></span>
              </div>
              <div className="team_outer">
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <div className="expertbx_item members_teamItem">
                      <div className="membrs_teamOuter">
                        <div className="team_membrs_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </div>
                        <div className="membrs_team_details flex-fill">
                          <span
                            className="placeholder d-block rounded-3 col-8 mb-3"
                            style={{ height: "20px" }}
                          ></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="expertbx_item members_teamItem">
                      <div className="membrs_teamOuter">
                        <div className="team_membrs_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </div>
                        <div className="membrs_team_details flex-fill">
                          <span
                            className="placeholder d-block rounded-3 col-8 mb-3"
                            style={{ height: "20px" }}
                          ></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                          <span className="placeholder placeholder-sm d-block rounded-3 col-8"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <div>
          {processLists.length === 0 ? (
            <div className="course_detailspnl">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("Process are not available for this job.")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="course_detailspnl">
              {/* <div className="contnt_hdng">
                <h4>Process</h4>
              </div> */}
              <div className="process-details-container">
                <div id="process_slider">
                  <Carousel
                    //ssr
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup />}
                    responsive={processResponsive}
                  >
                    {processLists.map((process, index) => {
                      return (
                        <div className="process-category px-2" key={index}>
                          <div className="single-category p-3 bg-gray-600">
                            <h6 className="fw-bold mb-3">{process.title}</h6>

                            {process?.challengeresponselist?.length === 0 ? (
                              <div className="single-process expertbx_item members_teamItem rounded-0 border-0 mb-2">
                                <div className="membrs_teamOuter">
                                  <div className="team_membrs_pic">
                                    <div className="team_membrs_pic">
                                      <span
                                        className="placeholder rounded-circle"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="membrs_team_details flex-fill">
                                    <span
                                      className="placeholder d-block rounded-3 col-8 mb-3 mt-1"
                                      style={{ height: "15px" }}
                                    ></span>
                                    <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                                    <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="single-process expertbx_item members_teamItem rounded-0 border-0 mb-2">
                                {process?.challengeresponselist?.map(
                                  (candidate, index2) => {
                                    return (
                                      <div
                                        className="membrs_teamOuter"
                                        key={index2}
                                      >
                                        <div className="team_membrs_pic">
                                          <img
                                            src={
                                              candidate.image !== ""
                                                ? url.SERVER_URL +
                                                  candidate.image
                                                : defaultUser
                                            }
                                            alt="Bertha Sandoval"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="membrs_team_details">
                                          <div className="team_name">
                                            <h3>
                                              {candidate.name}{" "}
                                              {candidate.surname}
                                            </h3>
                                            <h5>{candidate.position}</h5>
                                          </div>
                                          <div className="team_address_value">
                                            <h3>
                                              {candidate.location}{" "}
                                              <span>€{candidate.price}</span>
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProcessTab;

/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import methods from gatway for API call
import { getData_v2, postData_v2 } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

import { ecosystemSlug } from "Config/Config";
import SaveSurveyModal from "./Modals/SaveSurveyModal";
import SaveSurveySuccessModal from "./Modals/SaveSurveySuccessModal";

const FeedbackMainContainer = () => {
  const params = useParams(); //survey invite id

  //left panel and right panel requirements
  const [surveyInviteDetailsLoading, setSurveyInviteDetailsLoading] =
    useState(false);
  const [surveyInviteeImage, setSurveyInviteeImage] = useState("");
  const [surveyInviteeName, setSurveyInviteeName] = useState("");
  const [surveyInviteeMemberFrom, setSurveyInviteeMemberFrom] = useState("");
  const [totalNoOfSurveyInvites, setTotalNoOfSurveyInvites] = useState("");
  const [totalNoOfGivenSurveys, setTotalNoOfGivenSurveys] = useState("");
  const [surveyInviterImage, setSurveyInviterImage] = useState("");
  const [surveyInviterName, setSurveyInviterName] = useState("");
  const [responseListLoading, setResponseListLoading] = useState(false);
  const [surveyResponseList, setSurveyResponseList] = useState([]);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  const [surveyId, setsurveyId] = useState(null);
  const [surveyInviteeEmail, setSurveyInviteeEmail] = useState("");
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyQuestion, setSurveyQuestion] = useState(null);
  const [totalNoOfQuestions, settotalNoOfQuestions] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestionModal, setshowQuestionModal] = useState(false);

  const [isSending, setIsSending] = useState(false);

  //get invite details
  const getSurveyInviteDetails = async () => {
    setSurveyInviteDetailsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_SURVEY_INVITE_DETAILS + `/${params.id}`;

      // console.log(requestURL);

      const response = await getData_v2(requestURL);

      // console.log(response);

      setSurveyInviteDetailsLoading(false);

      if (response.status) {
        setsurveyId(response.data.surveyid);
        setSurveyTitle(response.data.name);
        setSurveyInviteeImage(response.data.surveyinviteeimage);
        setSurveyInviteeName(response.data.surveyinviteename);
        setSurveyInviteeMemberFrom(response.data.surveyinviteememberfrom);
        setTotalNoOfSurveyInvites(response.data.totalnoofsurveyinvites);
        setTotalNoOfGivenSurveys(response.data.totalgivensurveyresponses);
        setSurveyInviterImage(response.data.surveyinviterimage);
        setSurveyInviterName(response.data.surveyinvitername);
        setSurveyInviteeEmail(response.data.invitee.email);
        getAllSurveyResponses(response.data.invitee.email);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all responses by invitee
  const getAllSurveyResponses = async (inviteeEmail) => {
    try {
      setResponseListLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_SURVEY_RESPONSES +
        `?filtersurveyuseremail=${inviteeEmail}`;

      console.log(requestURL);

      const response = await getData_v2(requestURL);

      console.log(response);

      setResponseListLoading(false);

      if (response.status) {
        if (response.data.length === 0) {
          setShowEmptyMessage(true);
        } else {
          setShowEmptyMessage(false);
        }
        setSurveyResponseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get survey questions
  const getSurveyQuestions = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_SURVEY_INVITE_DETAILS + `/${params.id}`;

      console.log(requestURL, currentQuestionIndex);

      const response = await getData_v2(requestURL);

      if (response.status) {
        if (response.data.questionlist.length > 0) {
          settotalNoOfQuestions(response.data.questionlist.length);
          const getUnansweredQuestions = response.data.questionlist;

          setSurveyQuestion(getUnansweredQuestions[currentQuestionIndex]);

          //show otpconfirm modal
          let myModal = new bootstrap.Modal(
            document.getElementById("save_survey")
          );
          myModal.show();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save answer of survey
  const saveAnswerOfSurvey = async (questionid, answer) => {
    try {
      let sureveyResponseData = {
        ecosystemidslug: ecosystemSlug,
        surveyid: surveyId,
        surveyinviteid: params.id,
        questionid,
        response: answer,
      };

      console.log(sureveyResponseData);

      let requestURL = url.API_BASE_URL + url.API_SAVE_RESPONSE_SURVEY;

      const response = await postData_v2(requestURL, sureveyResponseData);

      console.log(response);

      setIsSending(false);

      //hide modal
      let surveyModal = document.querySelector("#save_survey");
      let modal = bootstrap.Modal.getInstance(surveyModal);
      modal.hide();

      console.log(response);

      if (response.status) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setshowQuestionModal(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //fetch previous answer and show modal
  const previousModalhandler = () => {
    setshowQuestionModal(true);
  };

  //function for after success modal close
  const closeModalHandler = (isSubmit) => {
    setCurrentQuestionIndex(0);
    if (isSubmit) {
      getAllSurveyResponses(surveyInviteeEmail);
    }
  };

  /**
   * use effect for display next question in survey modal.
   * If survey has finsihed then success modal will show.
   */
  useEffect(() => {
    if (showQuestionModal) {
      if (currentQuestionIndex + 1 <= totalNoOfQuestions) {
        getSurveyQuestions();
      } else {
        //show success modal
        let myModal = new bootstrap.Modal(
          document.getElementById("survey_success_mdl")
        );
        myModal.show();
      }

      setshowQuestionModal(false);
    }
  }, [showQuestionModal]);

  /**
   * Get survey invite details
   */
  useEffect(() => {
    if (params.id) {
      getSurveyInviteDetails();
    }
  }, [params.id]);

  return (
    <section className="my_survey_outer py-5">
      <div className="container">
        <div className="right_survey bg-white px-5">
          {surveyInviteDetailsLoading ? (
            <div className="right_rvw_inr">
              <div className="medium_container">
                <div className="process_form_area">
                  <div className="process_hdng text-center">
                    <span className="d-block mb-3">
                      <span
                        className="placeholder"
                        style={{ width: "100px", height: "100px" }}
                      ></span>
                    </span>
                    <span
                      className="placeholder d-table mx-auto"
                      style={{ width: "150px", height: "30px" }}
                    ></span>
                  </div>
                  <div className="survey_subheading text-center mb-4">
                    <span
                      className="placeholder d-table mx-auto mb-3"
                      style={{ width: "350px", height: "22px" }}
                    ></span>
                    <div className="kitchen_user_row d-flex align-items-center gap-1 justify-content-center">
                      <span
                        className="placeholder rounded-90"
                        style={{ width: "150px", height: "44px" }}
                      ></span>
                    </div>
                  </div>
                  <div className=" text-center">
                    <span
                      className="placeholder rounded-90"
                      style={{ width: "170px", height: "60px" }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right_rvw_inr text-center">
              <div className="medium_container">
                <div className="process_form_area">
                  <div className="process_hdng text-center">
                    <span className="d-block mb-3">
                      <img src="/images/hlw-wave.svg" alt="" />
                    </span>
                    <h3 className="fw-semibold mb-3">
                      Hello {surveyInviteeName}
                    </h3>
                  </div>
                  <div className="survey_subheading text-center mb-4">
                    <h4 className="text-black fs-xl mb-3">
                      Start Survey on {surveyTitle}
                    </h4>
                    <div className="kitchen_user_row d-flex align-items-center gap-1 justify-content-center">
                      <h6 className="fs-sm text-black-gray">Invited by</h6>
                      <div className="survey_user d-flex align-items-center gap-2 bg-light rounded-90 px-3 py-2 justify-content-center">
                        <span className="d-block survey_usr_pic overflow-hidden rounded-circle">
                          <img
                            src={
                              surveyInviterImage === ""
                                ? "/images/default-user.png"
                                : url.SERVER_URL + surveyInviterImage
                            }
                            alt=""
                          />
                        </span>
                        <h5 className="fs-sm text-black">
                          {surveyInviterName}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className=" text-center">
                    <Link
                      to="#"
                      className="basic_btn ylw_btn"
                      onClick={getSurveyQuestions}
                    >
                      Start Survey{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <!-- ========== Start survey modal component ========== --> */}
      <SaveSurveyModal
        closeModalHandler={closeModalHandler}
        isSending={isSending}
        setIsSending={setIsSending}
        previousModalhandler={previousModalhandler}
        surveyTitle={surveyTitle}
        surveyQuestion={surveyQuestion}
        currentQuestionIndex={currentQuestionIndex}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        totalNoOfQuestions={totalNoOfQuestions}
        saveAnswerOfSurvey={saveAnswerOfSurvey}
      />
      {/* <!-- ========== End survey modal component ========== --> */}

      {/* <!-- ========== Start save success modal component ========== --> */}
      <SaveSurveySuccessModal closeModalHandler={closeModalHandler} />
      {/* <!-- ========== End save success modal component ========== --> */}
    </section>
  );
};

export default FeedbackMainContainer;

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";
import { t } from "i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import SimilarGroups from "../common/SimilarGroups";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import file download function
import downloadFileHandler from "helpers/downloadfile";

//import images
import PDF from "assets/EventsAssets/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

import blankVideo from "assets/CourseDetailsAssests/images/course-vdo-right-blank.jpg";
import brochurePdf from "assets/CourseDetailsAssests/images/PDF.jpg";
import brochureDoc from "assets/CourseDetailsAssests/images/DOC.jpg";
import brochurePpt from "assets/CourseDetailsAssests/images/PPT.jpg";
import brochureXls from "assets/CourseDetailsAssests/images/XLS.jpg";

const About = () => {
  // responsive property for carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 590, min: 0 },
      items: 1,
    },
  };

  const brochureBgStyle = {
    backgroundImage: "url(/images/brochure-bg.png)",
    maxWidth: "25%",
  };

  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const glowCount = [1, 2, 3, 4];

  // initials for group details
  const [groupAboutData, setGroupAboutData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); //for glow placeholder

  const getGroupAboutData = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL + url.API_GROUP_ABOUT + `/${params.id}?token=${token}`;

    try {
      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          setGroupAboutData(response.data);
        } else {
          setGroupAboutData(null);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (params.id) getGroupAboutData();
  }, [params.id]);

  return (
    <div className="tab-pane show active fade" id="evnt_about">
      {isLoading ? (
        <div className="training_outer">
          <div className="course_detailspnl">
            <div className="event_catagoryslide_Outer">
              <div className="evenmt_catagory_slider">
                <Carousel ssr responsive={responsive}>
                  {glowCount.map((count) => {
                    return (
                      <div className="catagory_slideItem" key={count}>
                        <div className="catagory_slideInnr">
                          <div className="catagory_slideHdng">
                            <span className="placeholder placeholder-md rounded-2 col-4"></span>
                          </div>
                          <div className="experts_skill">
                            <ul>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "95px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "110px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "65px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "93px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "115px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "98px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder d-block rounded-2"
                                  style={{ width: "87px", height: "30px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span className="placeholder placeholder-md rounded-3 col-1"></span>
            </div>
            <div className="results_para">
              <p>
                <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
              </p>
              <p>
                <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-2"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
              </p>
            </div>
          </div>
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span className="placeholder placeholder-md rounded-3 col-1"></span>
            </div>
            <div className="sponsor_cmpny">
              <ul>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "42px", height: "42px" }}
                      ></span>
                    </i>
                    <span className="placeholder placeholder-sm rounded-2 col-12"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "42px", height: "42px" }}
                      ></span>
                    </i>
                    <span className="placeholder placeholder-sm rounded-2 col-12"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "42px", height: "42px" }}
                      ></span>
                    </i>
                    <span className="placeholder placeholder-sm rounded-2 col-12"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span className="placeholder placeholder-md rounded-3 col-1"></span>
            </div>
            <div className="upload_area">
              <div className="upload_item">
                <span
                  className="placeholder"
                  style={{ width: "135px", height: "135px" }}
                ></span>
              </div>
              <div className="upload_item">
                <span
                  className="placeholder"
                  style={{ width: "135px", height: "135px" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="training_outer">
          {groupAboutData == null ||
          (groupAboutData.category?.length === 0 &&
            groupAboutData.searching?.length === 0 &&
            groupAboutData.interest.length === 0 &&
            groupAboutData.document.length === 0 &&
            !groupAboutData.description) ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("Details are not available at this moment.")}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="exprt_overviewOuter">
                  <div className="course_detailspnl">
                    <div className="event_catagoryslide_Outer">
                      <div className="evenmt_catagory_slider">
                        <Carousel ssr responsive={responsive}>
                          {/* ---------- Category Slider Card starts here ---------- */}
                          {groupAboutData.categorylist.length > 0 ? (
                            <div className="catagory_slideItem mb-4">
                              <div className="catagory_slideInnr">
                                <div className="catagory_slideHdng">
                                  <h3>{t("Category")}</h3>
                                </div>
                                {groupAboutData.categorylist.length > 0 ? (
                                  <div className="experts_skill">
                                    <ul>
                                      {groupAboutData.categorylist.map(
                                        (item, index) => {
                                          return (
                                            <li key={index}>
                                              <Link to="#">{item.label}</Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                          {/* ---------- Category Slider Card ends here ---------- */}

                          {/* ---------- Search Slider Card starts here ---------- */}
                          {groupAboutData.searchinglist.length > 0 ? (
                            <div className="catagory_slideItem mb-4">
                              <div className="catagory_slideInnr">
                                <div className="catagory_slideHdng">
                                  <h3>{t("Search")}</h3>
                                </div>
                                {groupAboutData.searchinglist.length > 0 ? (
                                  <div className="experts_skill">
                                    <ul>
                                      {groupAboutData.searchinglist.map(
                                        (item, index) => {
                                          return (
                                            <li key={index}>
                                              <Link to="#">{item.label}</Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                          {/* ---------- Search Slider Card ends here ---------- */}

                          {/* ---------- Offers Slider Card starts here ---------- */}
                          {/* {groupAboutData.offering.length > 0 ? (
                            <div className="catagory_slideItem mb-4">
                              <div className="catagory_slideInnr">
                                <div className="catagory_slideHdng">
                                  <h3>{t("Offers")}</h3>
                                </div>
                                {groupAboutData.offering.length > 0 ? (
                                  <div className="experts_skill">
                                    <ul>
                                      {groupAboutData.offering.map(
                                        (item, index) => {
                                          return (
                                            <li key={index}>
                                              <Link to="#">{item}</Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null} */}
                          {/* ---------- Offers Slider Card ends here ---------- */}

                          {/* ---------- Interest Slider Card starts here ---------- */}
                          {groupAboutData.interestlist.length > 0 ? (
                            <div className="catagory_slideItem mb-4">
                              <div className="catagory_slideInnr">
                                <div className="catagory_slideHdng">
                                  <h3>{t("Interest")}</h3>
                                </div>
                                {groupAboutData.interestlist.length > 0 ? (
                                  <div className="experts_skill">
                                    <ul>
                                      {groupAboutData.interestlist.map(
                                        (item, index) => {
                                          return (
                                            <li key={index}>
                                              <Link to="#">{item.label}</Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}

                          {/* ---------- Interest Slider Card ends here ---------- */}
                        </Carousel>
                      </div>

                      {/* <div className="group_catagory_slider">
                        <div className="catagory_slideItem">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>Catagory</h3>
                            </div>

                            {groupAboutData.category ? (
                              <div className="experts_skill">
                                {groupAboutData.category.length > 0 && (
                                  <ul>
                                    {groupAboutData.category.map(
                                      (item, index) => {
                                        return (
                                          <li key={index}>
                                            <Link to="#">{item}</Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="catagory_slideItem">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>Group</h3>
                            </div>
                            <div className="experts_skill">
                              <ul>
                                <li>
                                  <Link to="#">GameBoy</Link>
                                </li>
                                <li>
                                  <Link to="#">TechGuru</Link>
                                </li>
                                <li>
                                  <Link to="#">WhatisTech</Link>
                                </li>
                                <li>
                                  <Link to="#">Machaionly</Link>
                                </li>
                                <li>
                                  <Link to="#">Linus Tech Tips</Link>
                                </li>
                                <li>
                                  <Link to="#">TG Game</Link>
                                </li>
                                <li>
                                  <Link to="#">CarryMinati</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  {/* ---- summary section start ----- */}
                  {/* <div className="course_detailspnl">
                    <div className="contnt_hdng">
                      <h4>Summary</h4>
                    </div>
                    <div className="results_para">
                      <p>{groupAboutData.summary}</p>
                    </div>
                  </div> */}
                  {groupAboutData.description ? (
                    <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("Summary")}</h4>
                      </div>
                      <div
                        className="results_para"
                        dangerouslySetInnerHTML={{
                          __html: groupAboutData.description,
                        }}
                      ></div>
                    </div>
                  ) : null}
                  {/* ---- summary section end ----- */}

                  {/* ---- document start here ---- */}

                  <div className="course_detailspnl">
                    <div className="contnt_hdng">
                      <h4>{t("Documents")}</h4>
                    </div>
                    <div className="upload_area">
                      {groupAboutData.uploads &&
                      groupAboutData.uploads.length > 0
                        ? groupAboutData.uploads.map(
                            (promotionalDoc, index) => {
                              return (
                                <div
                                  className="brochure_bx p-4 me-2 "
                                  style={brochureBgStyle}
                                  key={index}
                                >
                                  <h2 className="mb-3">
                                    {promotionalDoc.title}
                                  </h2>

                                  <div className="brochure_pic mb-3">
                                    {promotionalDoc.filetype === "video/*" ||
                                    promotionalDoc.filetype === null ||
                                    promotionalDoc.filetype === "" ? (
                                      <div className="course_vdopnl">
                                        <div className="embed-responsive embed-responsive-16by9 course_vdoBx">
                                          <video
                                            className="embed-responsive-item"
                                            style={{ borderRadius: "5px" }}
                                            src={
                                              url.SERVER_URL +
                                              promotionalDoc.filepath
                                            }
                                            id="samplepreview"
                                            controls
                                            allowFullScreen
                                            width="100%"
                                            height="100%"
                                            fullscreen="true"
                                          ></video>
                                        </div>
                                      </div>
                                    ) : null}

                                    {promotionalDoc.filetype ===
                                    "application/pdf" ? (
                                      <img src={brochurePdf} alt="" />
                                    ) : (
                                      ""
                                    )}

                                    {promotionalDoc.filetype ===
                                    "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                      <img src={brochureDoc} alt="" />
                                    ) : (
                                      ""
                                    )}

                                    {promotionalDoc.filetype ===
                                    "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                      <img src={brochurePpt} alt="" />
                                    ) : (
                                      ""
                                    )}

                                    {promotionalDoc.filetype === "xls" ? (
                                      <img src={brochureXls} alt="" />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="brochure_text">
                                    <p className="mb-3">
                                      {promotionalDoc.description}
                                    </p>

                                    {promotionalDoc.filetype === "video/*" ||
                                    promotionalDoc.filetype === "" ||
                                    promotionalDoc.filetype === null ? null : (
                                      <div className="brochure_btn">
                                        <Link
                                          className="basic_btn ylw_btn gap-1 w-100"
                                          to="#"
                                          onClick={() => {
                                            const downloadedFile = {
                                              ...promotionalDoc,
                                              name: promotionalDoc.title,
                                              path:
                                                url.SERVER_URL +
                                                promotionalDoc.filepath,
                                            };
                                            downloadFileHandler(downloadedFile);
                                          }}
                                        >
                                          <i className="material-icons-outlined d-block">
                                            file_download
                                          </i>
                                          <span>{t("Download")} </span>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </div>

                  {/* ---- document end here ---- */}

                  {/* ----- Attached Brochure or promotianl items --------------- */}

                  {/* <div className="course_detailspnl">
                    <div className="contnt_hdng">
                      <h4>Sponsors</h4>
                    </div>
                    <div className="sponsor_cmpny">
                      <ul>
                        <li>
                          <Link to="#">
                            <i>
                              <img src="images/sponsor1.png" alt="" />
                            </i>
                            <span>Microsoft</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i>
                              <img src="images/sponsor2.png" alt="" />
                            </i>
                            <span>Dropbox</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i>
                              <img src="images/sponsor3.png" alt="" />
                            </i>
                            <span>Google</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default About;

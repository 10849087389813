import React from "react";

// importing custom components here
import Header from "common/Header";
import MainSearchComponent from "components/SearchComponents/MainSearchComponent";
import Footer from "common/Footer";

const Search = () => {
  return (
    <>
      <Header />
      <MainSearchComponent />
      <Footer />
    </>
  );
};

export default Search;

/* eslint-disable */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

const CookieConsentPopup = () => {
  const { setCookieReload } = useContext(GlobalIndraInfo);

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  let language = localStorage.getItem("language");

  let currentdate = new Date();
  let datetime =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  // Function to set the cookie value
  const setCookie = (e) => {
    e.preventDefault();

    let cookieValue = {
      cookieAgreed: 3,
      lastlogin: datetime,
      cookiesAccepted: true,
      cookies_preference: true,
      cookies_marketing: true,
      cookies_analytics: true,
      language,
    };

    const exdate = new Date();
    exdate.setHours(exdate.getHours() + 15 * 24);

    document.cookie = "indra_necessary" + "=" + JSON.stringify(cookieValue);
    +";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    document.cookie =
      "indra_preference" +
      "=" +
      true +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    document.cookie =
      "indra_marketing" +
      "=" +
      true +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";
    document.cookie =
      "indra_analytics" +
      "=" +
      true +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    let offCanvasPopup = document.querySelector("#acceptCookies");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    // let myModal = new bootstrap.Modal(
    //   document.getElementById("cookie_accept_mdl")
    // );
    // myModal.show();
    setCookieReload(true);
  };

  return (
    <div
      className="offcanvas offcanvas-bottom"
      tabIndex="-1"
      id="acceptCookies"
      aria-labelledby="acceptCookiesLabel"
      data-bs-keyboard="false"
    >
      <div className="offcanvas-body small d-flex flex-wrap flex-md-nowrap gap-4 align-items-center justify-content-between p-4">
        <p>
          {t(
            "Indra Sistemas S.A. uses its own and third-party cookies for the purpose of recording user browsing activities, quantifying the number of users and performing measurement and statistical analysis of the use made by users of the service offered.You can configure and/or accept the use of cookies through the boxes shown below. If you want more information you can visit our"
          )}
          <Link
            to="/blog/cookies-policy"
            target="_blank"
            className="text-white fst-italic text-decoration-underline"
          >
            {" "}
            {t("Extended Cookies Policy")}{" "}
          </Link>
        </p>
        <div className="action d-flex text-nowrap">
          <button
            className="basic_btn ylw_btn me-3"
            onClick={(e) => setCookie(e)}
          >
            {t("Accept Cookies")}
          </button>
          <button
            type="button"
            className="border_btn ylwbrdr_btn"
            data-bs-toggle="modal"
            data-bs-target="#cookie_config_mdl"
            onClick={() => {
              let offCanvasPopup = document.querySelector("#acceptCookies");
              let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
              offCanvas.hide();
            }}
          >
            {t("Configure")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentPopup;

import { useState } from "react";
import { Link } from "react-router-dom";

import * as url from "helpers/url_helper"; // import all URLs from url_helper

import EmptyMessage from "../Others/EmptyMessage";
import AppMenu from "../Others/AppMenu";
import ChatLeftPanelPlaceholder from "./Placeholder/ChatLeftPanelPlaceholder";

const ConversationLeftPanel = ({
  getAllUserChats = () => {},
  isChatPanelLoading = false,
  chatList = [],
  onSelectContact = () => {},
  onGroupSelection = () => {},
  selectedChatIndex,
  isClosingResponse,
  setSelectedChatIndex,
  removeRequireResponseHandler,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const glowPlaceholderCount = [1, 2, 3, 4, 5, 6, 7];

  // function to filter tags by search term
  const [searchTerm, setSearchTerm] = useState("");

  const [chatPopupClass, setChatPopupClass] = useState("chat_cmntBx");

  const showFilteredList =
    searchTerm.length > 0
      ? chatList.filter((chat) =>
          chat.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : chatList;

  //function for toggle chat popup
  const toggleChatPopup = () => {
    if (chatPopupClass === "chat_cmntBx") {
      setChatPopupClass("chat_cmntBx show_chatpopup");
    } else {
      setChatPopupClass("chat_cmntBx");
    }
  };

  return (
    <div className="chat_lftpnl">
      {/* <!-- ========== Start search contact Section ========== --> */}
      <div className="left_topPnl">
        <div className="profile_usrOuter">
          <div className="profile_row">
            {/* <!-- ========== Start trigger menu panel icon  ========== --> */}
            <div className="profile_bar">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.toggle("hamberger_menuOpen");
                }}
              >
                <i className="material-icons-round">menu </i>
              </Link>
            </div>
            {/* <!-- ========== End trigger menu panel icon  ========== --> */}

            {/* <!-- ========== Start main logo Section ========== --> */}
            <div className="profile_pic">
              <Link to="/">
                <img
                  src="/images/indra-logo.svg"
                  alt=""
                  style={{ maxWidth: "150px" }}
                />
              </Link>
            </div>
            {/* <!-- ========== End main logo Section ========== --> */}
          </div>

          <div className="profile_rfresh_outer">
            <div className="rfresh_icon">
              <Link
                to="#"
                onClick={() => {
                  getAllUserChats(true);
                }}
              >
                <span className="material-icons-outlined">refresh</span>
              </Link>
            </div>
            <div className="rfresh_profilePic">
              <Link to="#">
                <img
                  src={
                    userInfo.photoimage
                      ? userInfo.photoimage.path === ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + userInfo.photoimage.path
                      : "/images/default-user.png"
                  }
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>

        {/* <!-- ========== Start search by text for contact/group in bellow list  ========== --> */}
        <div className="usr_srchRow">
          <input
            type="text"
            className="form-control"
            placeholder="Search people, chats"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* <!-- ========== End search by text for contact/group in bellow list  ========== --> */}
      </div>
      {/* <!-- ========== End search contact Section ========== --> */}

      <div className="chat_usrPnl">
        {isChatPanelLoading ? (
          glowPlaceholderCount.map((count, index) => {
            return <ChatLeftPanelPlaceholder key={index} />;
          })
        ) : chatList.length > 0 ? (
          <div className="chat_innrScroll">
            {showFilteredList.map((chat, index) => {
              return (
                <div
                  className={`chat_usrRow ${
                    chat.moduleslug === "MOD_GROUP"
                      ? "group_msg_row"
                      : chat.lastmessagesender &&
                        chat.lastmessagesender.toString() ===
                          userInfo._id.toString()
                      ? "send_msg_row"
                      : "incoming_msg_row"
                  }`}
                  key={index}
                >
                  <Link
                    to="#"
                    onClick={() => {
                      if (chat.moduleslug === "MOD_CONTACT") {
                        onSelectContact(chat);
                      } else {
                        onGroupSelection(chat);
                      }
                    }}
                  >
                    <div className="usr_lftPnl">
                      <div className="chatting_user">
                        <img
                          className="rounded-circle"
                          src={
                            chat.image === ""
                              ? "/images/default-user.png"
                              : url.SERVER_URL + chat.image
                          }
                          alt=""
                        />
                      </div>
                      <div className="user_name">
                        <h3>{chat.name}</h3>
                        {chat.lastmessagesender ? (
                          <p>
                            {chat.lastmessagesender.toString() ==
                            userInfo._id.toString() ? (
                              <span>You : </span>
                            ) : null}{" "}
                            {chat.lastmessagetext.slice(0, 25)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="chat_option_right d-flex align-items-center gap-2">
                      <div className="msg_statusRight">
                        <span className="msg_time">
                          {chat.lastmessagetimestring}
                        </span>
                        {chat.lastmessagesender &&
                        chat.lastmessagesender.toString() ==
                          userInfo._id.toString() ? (
                          <span
                            className={`msg_status  ${
                              chat.moduleslug === "MOD_CONTACT"
                                ? chat.tracker === "2"
                                  ? "double_tick seen_tick"
                                  : chat.tracker === "1"
                                  ? "double_tick"
                                  : ""
                                : ""
                            } `}
                          >
                            <i className="material-icons-round">done_all</i>
                          </span>
                        ) : chat.unreadmessagecount === 0 ? null : (
                          <span className="pending_msg">
                            {chat.unreadmessagecount}
                          </span>
                        )}
                      </div>
                      {/* <div className="option_more dropdown">
                        <Link
                          to="#"
                          className="p-0 more_dots"
                          data-bs-toggle="dropdown"
                        >
                          <i className="material-icons-outlined">more_vert</i>
                        </Link>
                        <ul className="list_stye_none dropdown-menu">
                          {chat.moduleslug === "MOD_CONTACT" ? (
                            <li>
                              {selectedChatIndex === index &&
                              isClosingResponse ? (
                                <div
                                  className="mx-2 spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <Link
                                  to="#"
                                  className=""
                                  onClick={() => {
                                    setSelectedChatIndex(index);
                                    removeRequireResponseHandler(chat);
                                  }}
                                >
                                  Close Response
                                </Link>
                              )}
                            </li>
                          ) : null}
                        </ul>
                      </div> */}
                    </div>
                  </Link>
                </div>
              );
            })}

            {/* <div className="chat_usrRow send_msg_row">
              <Link to="#">
                <div className="usr_lftPnl">
                  <div className="chatting_user">
                    <img src="/images/user1.png" alt="" />
                  </div>
                  <div className="user_name">
                    <h3>Richa Holmowalk</h3>
                    <p>
                      <span>You:</span> I’m looking forward
                    </p>
                  </div>
                </div>
                <div className="msg_statusRight">
                  <span className="msg_time">25min</span>
                  <span className="msg_status">
                    <i className="material-icons-round"> done</i>
                  </span>
                </div>
              </Link>
            </div>
            <div className="chat_usrRow incoming_msg_row">
              <Link to="#">
                <div className="usr_lftPnl">
                  <div className="chatting_user">
                    <span className="material-icons-round">person</span>
                  </div>
                  <div className="user_name">
                    <h3>David Anderson</h3>
                    <p>I’m looking forward</p>
                  </div>
                </div>
                <div className="msg_statusRight">
                  <span className="msg_time">1hr 25mn</span>
                  <span className="pending_msg">2</span>
                </div>
              </Link>
            </div>
            <div className="chat_usrRow group_msg_row">
              <Link to="#">
                <div className="usr_lftPnl">
                  <div className="chatting_user">
                    <figure className="group_usr">
                      <img src="/images/gpic1.png" alt="" />
                    </figure>
                    <figure className="group_usr">
                      <img src="/images/gpic1.png" alt="" />
                    </figure>
                    <figure className="group_usr">
                      <img src="/images/gpic1.png" alt="" />
                    </figure>
                    <span className="group_usr plus_usr">
                      5 <em>+</em>
                    </span>
                  </div>
                  <div className="user_name">
                    <h3>UX Team - DEL</h3>
                    <p>
                      <span>Monalisa:</span> Yes um comming
                    </p>
                  </div>
                </div>
                <div className="msg_statusRight">
                  <span className="msg_time">38min</span>
                  <span className="msg_status double_tick seen_tick">
                    <i className="material-icons-round"> done_all</i>
                  </span>
                </div>
              </Link>
            </div> */}
          </div>
        ) : (
          <EmptyMessage />
        )}

        {/* <!-- ========== Start start new chat/group button ========== --> */}
        <div className={chatPopupClass}>
          <Link to="#" className="chat_btn" onClick={toggleChatPopup}>
            <span className="material-icons-round">add_comment</span>
          </Link>
          <div className="lft_cht_popup">
            <ul>
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#start_newchat"
                  onClick={toggleChatPopup}
                >
                  <i className="material-icons-round">person</i>
                  <span>New Chat</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#group_modal"
                  onClick={toggleChatPopup}
                >
                  <i className="material-icons-round">people</i>
                  <span>New Group</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- ========== End start new chat/group button ========== --> */}
      </div>

      <AppMenu />
    </div>
  );
};

export default ConversationLeftPanel;

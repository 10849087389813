import React from "react";
import { Link } from "react-router-dom";

import * as url from "helpers/url_helper"; // import all URLs from url_helper
import { formatTimeAgo } from "helpers/Common/CommonHelper";

import ChatMessagePlaceholder from "../../PlaceHolder/ChatMessagePlaceholder";
import ChatMessageContent from "../../ChatMessageContent/ChatMessageContent";
import ChatMessageFlag from "../../ChatMessageFlag/ChatMessageFlag";
import downloadFileHandler from "helpers/downloadfile";

const MessagePanel = ({
  chatInputRef,
  isMessageLoading = false,
  image = "",
  conversationName = "",
  chatMessages = [],
  messagesEndRef,
  setreplyUserName,
  setreplyMessage,
  setparentMessageId,
  messageIndex,
  messageUpdating,
  setmessageIndex,
  updateChatMessageRequireResponse = () => {},
  deleteChatHandler,
  typingUsers = [],
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="msg_outer msg_shareicon_info">
      {chatMessages.map((chatData, index) => {
        return (
          <div
            className={`msg_row ${
              chatData.sender.toString() === userInfo._id.toString()
                ? "own_msgRow grp_ownmsg"
                : "group_usrmsg_row"
            }`}
            key={index}
          >
            {/* <!-- ========== Start time Section ========== --> */}
            {chatData.sender.toString() === userInfo._id.toString() ? null : (
              <div className="chat_infoLft">
                <div className="msg_usr">
                  <img
                    src={
                      chatData.senderimage === ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + chatData.senderimage
                    }
                    alt=""
                  />
                </div>
              </div>
            )}
            {/* <!-- ========== End time Section ========== --> */}

            <div className="chat_msgright">
              {/* <!-- ========== Start time Section ========== --> */}
              <div
                className={
                  chatData.sender.toString() === userInfo._id.toString()
                    ? "chat_info_timeOuter"
                    : "grp_usrname_time"
                }
              >
                {chatData.sender.toString() === userInfo._id.toString() ? (
                  <h5>
                    {formatTimeAgo(chatData.createdAt)}
                    {/* <span className="msg_status double_tick seen_tick">
                                <i className="material-icons-round">done_all</i>
                              </span> */}
                  </h5>
                ) : (
                  <h5>
                    <span className="grp_usr_prsn">{chatData.sendername}</span>
                    <span className="ms-2 grp_usrTime">
                      {formatTimeAgo(chatData.createdAt)}
                    </span>
                  </h5>
                )}
              </div>
              {/* <!-- ========== End time Section ========== --> */}

              {/* <!-- ========== Start message content Section ========== --> */}
              {chatData.sender.toString() === userInfo._id.toString() ? (
                <div className="owntext_row">
                  <div className="own_msgArea dropdown">
                    {/* <!-- ========== Start parent message Section ========== --> */}
                    {chatData.parent ? (
                      <div className="replied_msg_row">
                        <ChatMessageContent
                          message={chatData.parentchatmessage}
                        />
                        <span>By {chatData.parentchatsender}</span>
                      </div>
                    ) : null}
                    {/* <!-- ========== End parent message Section ========== --> */}

                    {/* <!-- ========== Start dropdown Section ========== --> */}
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className="chat_expand_arws owner_msg_dropdown"
                      aria-expanded="false"
                    >
                      <span className="material-icons-outlined">
                        {" "}
                        expand_more{" "}
                      </span>
                    </Link>
                    {/* <!-- ========== End dropdown Section ========== --> */}

                    {/* <!-- ========== Start dropdown menu Section ========== --> */}
                    <ul className="dropdown-menu">
                      {/* <li>
                                  {messageUpdating && messageIndex === index ? (
                                    <div
                                      className="mx-2 spinner-border spinner-border-sm"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setmessageIndex(index);
                                        updateChatMessageRequireResponse(
                                          chatData._id
                                        );
                                      }}
                                    >
                                      <span>Require Response</span>
                                    </Link>
                                  )}{" "}
                                </li> */}
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            chatInputRef.current.focus();
                            setreplyMessage(chatData.description);
                            setreplyUserName("You");
                            setparentMessageId(chatData._id);
                          }}
                          className="d-flex align-items-center gap-2"
                        >
                          <i className="material-symbols-outlined fs-md">
                            reply
                          </i>
                          <span>Reply</span>{" "}
                        </Link>{" "}
                      </li>
                      {chatData.sender.toString() ===
                      userInfo._id.toString() ? (
                        <li>
                          <Link
                            to="#"
                            onClick={() => {
                              deleteChatHandler(chatData._id);
                            }}
                            className="d-flex align-items-center gap-2"
                          >
                            <i className="material-symbols-outlined fs-md">
                              delete
                            </i>
                            <span>Delete</span>{" "}
                          </Link>{" "}
                        </li>
                      ) : null}
                    </ul>
                    {/* <!-- ========== End dropdown menu Section ========== --> */}

                    <div className="replied_msg_text">
                      <ChatMessageContent message={chatData.description} />

                      {chatData.flaggedlanguage &&
                      chatData.flaggedlanguage !== "" ? (
                        <div
                          className="replied_msg_row recever_translatebx d-flex gap-1 p-2 rounded-10 overflow-hidden mt-2"
                          style={{
                            borderLeft: "2px solid #3191FF",
                          }}
                        >
                          <span
                            className="d-block rounded-circle overflow-hidden"
                            style={{
                              height: "20px",
                              width: "20px",
                              minWidth: "20px",
                            }}
                          >
                            <ChatMessageFlag
                              language={chatData.flaggedlanguage}
                            />
                          </span>
                          <ChatMessageContent
                            message={chatData.flaggeddescription}
                          />
                        </div>
                      ) : null}
                    </div>

                    {chatData.uploadedchatfiles &&
                    chatData.uploadedchatfiles.length > 0 ? (
                      <div className="upload_innrBody msg_uploadbody mt-2">
                        <div className="upload_outer">
                          <div className="row">
                            {chatData.uploadedchatfiles
                              .slice(0, 6)
                              .map((file, index2) => {
                                const fileExtension = file.path
                                  .split(".")
                                  .pop();

                                const fileObj = {
                                  ...file,
                                  path: url.SERVER_URL + file.path,
                                };

                                return (
                                  <div
                                    className="col"
                                    key={index2}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      downloadFileHandler(fileObj);
                                    }}
                                  >
                                    <div className="chat_uploadItem position-relative">
                                      <div className="chat_upload_pic">
                                        {fileExtension === "jpg" ||
                                        fileExtension === "jpeg" ||
                                        fileExtension === "png" ? (
                                          <img
                                            src={url.SERVER_URL + file.path}
                                            alt=""
                                          />
                                        ) : fileExtension === "pdf" ? (
                                          <img src="/images/pdf.svg" alt="" />
                                        ) : fileExtension === "xlsx" ||
                                          fileExtension === "xlsm" ||
                                          fileExtension === "xlsb" ||
                                          fileExtension === "xltx" ||
                                          fileExtension === "xls" ? (
                                          <img src="/images/xls.svg" alt="" />
                                        ) : (
                                          <img src="/images/docs.svg" alt="" />
                                        )}
                                      </div>
                                      <div className="chat_upload_caption">
                                        <p>
                                          <span>{file.name}</span>
                                        </p>
                                      </div>
                                      {index2 === 5 &&
                                      chatData.uploadedchatfiles.length - 6 !==
                                        0 ? (
                                        <div className="overlay_text position-absolute">
                                          <Link
                                            to="#"
                                            className="d-flex h-100 w-100 align-items-center justify-content-center"
                                          >
                                            {chatData.uploadedchatfiles.length -
                                              6}
                                            +
                                          </Link>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="msg_text dropdown">
                  {/* <!-- ========== Start parent message Section ========== --> */}
                  {chatData.parent ? (
                    <div className="replied_msg_row">
                      <ChatMessageContent
                        message={chatData.parentchatmessage}
                      />
                      <span>By {chatData.parentchatsender}</span>
                    </div>
                  ) : null}
                  {/* <!-- ========== End parent message Section ========== --> */}

                  {/* <!-- ========== Start dropdown Section ========== --> */}
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="chat_expand_arws reciever_msg_dropdown"
                    aria-expanded="false"
                  >
                    <span className="material-icons-outlined">
                      {" "}
                      expand_more{" "}
                    </span>
                  </Link>
                  {/* <!-- ========== End dropdown Section ========== --> */}

                  {/* <!-- ========== Start dropdown menu Section ========== --> */}
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          chatInputRef.current.focus();

                          if (
                            chatData.flaggedlanguage &&
                            chatData.flaggedlanguage !== ""
                          ) {
                            setreplyMessage(chatData.flaggeddescription);
                          } else {
                            setreplyMessage(chatData.description);
                          }

                          setreplyUserName(chatData.sendername);
                          setparentMessageId(chatData._id);
                        }}
                        className="d-flex align-items-center gap-2"
                      >
                        <i className="material-symbols-outlined fs-md">reply</i>
                        <span>Reply</span>{" "}
                      </Link>{" "}
                    </li>
                  </ul>
                  {/* <!-- ========== End dropdown menu Section ========== --> */}

                  <div className="replied_msg_text">
                    {/* <!-- ========== Start message Section ========== --> */}
                    <ChatMessageContent message={chatData.description} />

                    {chatData.flaggedlanguage &&
                    chatData.flaggedlanguage !== "" ? (
                      <div
                        className="recever_translatebx d-flex gap-1 p-2 rounded-10 bg-white overflow-hidden mt-2"
                        style={{ borderLeft: "2px solid #3191FF" }}
                      >
                        <span
                          className="d-block rounded-circle overflow-hidden"
                          style={{
                            height: "20px",
                            width: "20px",
                            minWidth: "20px",
                          }}
                        >
                          <ChatMessageFlag
                            language={chatData.flaggedlanguage}
                          />
                        </span>
                        <ChatMessageContent
                          message={chatData.flaggeddescription}
                        />
                      </div>
                    ) : null}
                    {/* <!-- ========== End message Section ========== --> */}

                    {/* <!-- ========== Start file Section ========== --> */}
                    {chatData.uploadedchatfiles &&
                    chatData.uploadedchatfiles.length > 0 ? (
                      <div className="upload_innrBody msg_uploadbody mt-2">
                        <div className="upload_outer">
                          <div className="row">
                            {chatData.uploadedchatfiles
                              .slice(0, 6)
                              .map((file, index2) => {
                                const fileExtension = file.path
                                  .split(".")
                                  .pop();

                                const fileObj = {
                                  ...file,
                                  path: url.SERVER_URL + file.path,
                                };

                                return (
                                  <div
                                    className="col"
                                    key={index2}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      downloadFileHandler(fileObj);
                                    }}
                                  >
                                    <div className="chat_uploadItem position-relative">
                                      <div className="chat_upload_pic">
                                        {fileExtension === "jpg" ||
                                        fileExtension === "jpeg" ||
                                        fileExtension === "png" ? (
                                          <img
                                            src={url.SERVER_URL + file.path}
                                            alt=""
                                          />
                                        ) : fileExtension === "pdf" ? (
                                          <img src="/images/pdf.svg" alt="" />
                                        ) : fileExtension === "xlsx" ||
                                          fileExtension === "xlsm" ||
                                          fileExtension === "xlsb" ||
                                          fileExtension === "xltx" ||
                                          fileExtension === "xls" ? (
                                          <img src="/images/xls.svg" alt="" />
                                        ) : (
                                          <img src="/images/docs.svg" alt="" />
                                        )}
                                      </div>
                                      <div className="chat_upload_caption">
                                        <p>
                                          <span>{file.name}</span>
                                        </p>
                                      </div>
                                      {index2 === 5 &&
                                      chatData.uploadedchatfiles.length - 6 !==
                                        0 ? (
                                        <div className="overlay_text position-absolute">
                                          <Link
                                            to="#"
                                            className="d-flex h-100 w-100 align-items-center justify-content-center"
                                          >
                                            {chatData.uploadedchatfiles.length -
                                              6}
                                            +
                                          </Link>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* <!-- ========== End file Section ========== --> */}
                  </div>
                </div>
              )}
              {/* <!-- ========== End message content Section ========== --> */}

              {/* <!-- ========== Start tag Section ========== --> */}
              {/* <div className="ai_suggetions_area">
                      <ul className="d-flex align-items-center gap-1 justify-content-start flex-wrap">
                        <li>
                          <Link to="#" className="d-flex align-items-center">
                            Event
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="d-flex align-items-center">
                            Forum
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="d-flex align-items-center">
                            Group
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="d-flex align-items-center">
                            ODS
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="d-flex align-items-center">
                            Offer
                          </Link>
                        </li>
                      </ul>
                    </div> */}
              {/* <!-- ========== End tag Section ========== --> */}
            </div>
          </div>
        );
      })}

      <div ref={messagesEndRef} name="scrollRefDiv" />

      {/* when person is thinking or replying */}
      {typingUsers.length > 0 && (
        <div className="msg_row group_usrmsg_row">
          <div className="chat_infoLft">
            <div className="msg_usr"></div>
            <div className="chat_msgright me-1">
              <div className="grp_usrname_time">
                <h5>
                  <span className="grp_usr_prsn">
                    {typingUsers.map((user) => user.label).join(", ")}{" "}
                    {typingUsers.length === 1 ? "is" : "are"} typing
                  </span>
                </h5>
              </div>
            </div>
            <div className="chat_info_timeOuter">
              <div className="chat_dots">
                <img src="/images/dots-loading.gif" alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessagePanel;

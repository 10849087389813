/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AssignmentAcceptModal from "components/CourseDetailsComponents/Modals/AssignmentAcceptModal";
// importing images here

// importing context API here
import { GlobalIndraInfo } from "App";

import PDF from "assets/ConversationAssests/svg/pdf.svg";
import XLS from "assets/ConversationAssests/svg/xls.svg";
import DOCS from "assets/ConversationAssests/svg/docs.svg";
import VIDEO from "assets/ConversationAssests/svg/video.svg";
import ZIP from "assets/ConversationAssests/svg/zip.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import brochurePpt from "assets/CourseDetailsAssests/images/PPT.jpg";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import {
  getData,
  uploadMultipleFile,
  postFormdata,
  postData,
  putData,
  putData_v2,
} from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const LessonTask = (props) => {
  const { id: courseId } = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // importing useContext functions here
  const { isCourseUploader } = useContext(GlobalIndraInfo);

  // Initial useState to save the content recceived from the props.
  const [taskList, setTasksList] = useState([]);
  const [showTaskPane, setShowTaskPane] = useState(false);
  const [assignmentTaskId, setAssignmentTaskId] = useState(null);

  const [assignmentTaskDetails, setassignmentTaskDetails] = useState({});

  const [existingAssignmentTaskId, setExistingAssignmentTaskId] =
    useState(null);

  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [assignTaskId, setAssignTaskId] = useState(null);
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);
  // inintial state for condition
  const [isUploading, setIsUploading] = useState(false);
  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  const [fileIsBig, setFileIsBig] = useState(false);
  const [messageWarning, setMessageWarning] = useState(false);
  const [fileWarning, setFileWarning] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [commentList, setcommentList] = useState([]);
  const [comment, setComment] = useState("");
  const [commentWarning, setCommentWarning] = useState(false);
  const [reviewBlock, setReviewBlock] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // function for toggle assignment details
  const togglePost = (item = null) => {
    if (item) {
      setAssignmentTaskId(item._id);
    }

    setShowTaskPane(!showTaskPane);
    var x = document.getElementById("task_panel_assignment");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${assignmentTaskId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("task details", response);

      if (response.status) {
        setassignmentTaskDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for accept assignment
  const accpetAssignment = async () => {
    setIsAcceptLoading(true);

    let taskData = {
      uploads: [],
      previoustask: assignmentTaskId,
      lessonid: props.tasks._id,
      course: courseId,
      assigneduser: userInfo._id,
    };

    let requestURL =
      url.API_BASE_URL + url.API_CREATE_LESSON_ASSIGNMENT + `?token=${token}`;

    try {
      const response = await postData(requestURL, taskData);

      console.log(response);

      if (response.status) {
        getTaskDetails();
        getTaskAssignmentDetails();
        let myModal = new bootstrap.Modal(
          document.getElementById("assignmentaccept_success_modal")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for toggle review block
  const toggleSubmitBlock = () => {
    setReviewBlock(!reviewBlock);
  };

  // allow upload files, show file thumbnail, save files in const media
  const fileUploadHandler = async (e) => {
    setFileIsBig(false);
    setFileWarning(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        const targetFilesObject = [...targetFiles]; // store array of those files

        try {
          let requestURL =
            url.API_BASE_URL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

          const response = await uploadMultipleFile(requestURL, targetFiles);

          if (response.status) {
            let fileResponseId = response.data.map((data) => {
              return data._id;
            });

            setFileIds(fileResponseId);
            // check condition whether file array is empty or not
            if (media.length == 0) {
              targetFilesObject.map((file) => {
                return selectedFIles.push(file);
              });

              setMedia(selectedFIles); // changing state of files array
            } else {
              targetFilesObject.map((file) => {
                return selectedFIles.push(file);
              });

              // changing state of files array with previous file array values
              setMedia((media) => [...media, ...selectedFIles]);
            }
            setIsUploading(false);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setFileIsBig(true);
      }
    }
  };

  // uploads media files to server and get uploaded file id and setUploadedFileId
  const loadingCircle = [1, 2, 3, 4, 5, 6, 7];

  //get created task assignment details
  const getTaskAssignmentDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EXISTING_LESSON_TASK +
        `?token=${token}&filtercourse=${courseId}&lessonid=${props.tasks._id}&previoustask=${assignmentTaskId}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("task assignment details", response);

      if (response.status) {
        setExistingAssignmentTaskId(response.data._id);
      } else {
        setExistingAssignmentTaskId(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getAssignmentComments = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COURSE_TASK_ANSWERS +
        `?token=${token}&filtertask=${existingAssignmentTaskId}&filterlesson=${props.tasks._id}&filtercourse=${courseId}`;

      console.log("log list url----->", requestUrl);

      const response = await getData(requestUrl);

      console.log("log list----->", response);

      if (response.status) {
        setcommentList(response.data);
      }
      // else {
      //   setMessageType("error");
      //   setAlertMessage(response.message);
      //   setShowAlert(true);
      // }
    } catch (error) {
      console.log(error.message);
      // setMessageType("error");
      // setAlertMessage(error.message);
      // setShowAlert(true);
    }
  };

  // functions for submit assignment
  const submitAssignMentHandler = async (e) => {
    if (comment == "") {
      setCommentWarning(true);
    } else {
      setCommentWarning(false);
      setIsSubmitting(true);

      try {
        let taskData = {
          answer: comment,
          uploads: fileIds,
          previoustask: assignmentTaskId,
          lessonid: props.tasks._id,
          course: courseId,
          assigneduser: userInfo._id,
        };

        console.log(taskData);

        let requestURL =
          url.API_BASE_URL +
          url.API_SUBMIT_LESSON_TASK +
          `/${existingAssignmentTaskId}?token=${token}`;

        console.log(requestURL);

        const response = await putData(requestURL, taskData);

        console.log(response);

        setIsSubmitting(false);

        resetFile();
        setComment("");
        setMedia([]);

        if (response.status) {
          toggleSubmitBlock();
          setIsSubmit(true);
          let myModal = new bootstrap.Modal(
            document.getElementById("assignmentaccept_success_modal")
          );
          myModal.show();
        } else {
        }
      } catch (error) {}
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("inputGroupFile03");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // fuunction for reset submit after close modal
  const afterModalClose = () => {
    if (isSubmit) {
      setIsSubmit(false);
    }
    props.onConfirmLesson();
    togglePost();
    setIsAcceptLoading(false);
    getTaskDetails();
    getTaskAssignmentDetails();
    getAssignmentComments();
  };

  useEffect(() => {
    if (existingAssignmentTaskId && props.tasks._id) {
      getAssignmentComments();
    }
  }, [existingAssignmentTaskId, props.tasks._id]);

  useEffect(() => {
    if (assignmentTaskId) {
      getTaskDetails();
      getTaskAssignmentDetails();
    }
  }, [assignmentTaskId]);

  // UseEffect starts from here
  useEffect(() => {
    if (props.tasks) {
      // assigning the data to the useState
      setTasksList(props.tasks.tasklist);
    }
  }, [props.tasks.tasklist]);

  return (
    <div className="tab-pane fade active show" id="task">
      {taskList.length > 0 ? (
        <div className="task_panl lesson_pnl">
          {/*----- assignment lists start here ------*/}
          {!showTaskPane && (
            <div className="task_outer">
              <div className="row">
                {taskList.map((task, index) => {
                  return (
                    <div className="col-md-6" key={index}>
                      <div className="task_item">
                        <div className="time_rounded">
                          <i className="material-icons-outlined">schedule</i>
                          <span>
                            {task.duration} {t("mins")}
                          </span>
                        </div>
                        {task.approvaltaskstatus == "1" ? (
                          <div className="time_rounded text-success">
                            <i className="material-icons-outlined">done</i>
                            <span>{t("Approved")}</span>
                          </div>
                        ) : (
                          <>
                            {task.deliverytaskstatus == "1" ? (
                              <div className="time_rounded">
                                <i className="material-icons-outlined">done</i>
                                <span>{t("Submitted")}</span>
                              </div>
                            ) : (
                              <>
                                {task.assignedtaskstatus == "1" ? (
                                  <div className="time_rounded">
                                    <i className="material-icons-outlined">
                                      done
                                    </i>
                                    <span>{t("Assigned")}</span>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </>
                        )}

                        <div className="task_textPnl">
                          <h3>{task.title}</h3>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: task?.description,
                            }}
                          ></p>

                          <div className="task_btn">
                            <Link
                              to="#"
                              className="border_btn"
                              onClick={() => togglePost(task)}
                            >
                              {t("View Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/*----- assignment lists end here ------*/}

          {/*----- assignment details start here ------*/}
          <div className="task_detailspnl" id="task_panel_assignment">
            <div className="task_crossbtn">
              <Link to="#">
                <span className="material-icons-outlined" onClick={togglePost}>
                  close
                </span>
              </Link>
            </div>

            <div className="task_detailsHdng">
              {assignmentTaskId ? <h2>{assignmentTaskDetails.title}</h2> : null}

              {assignmentTaskId ? (
                <div className="time_rounded">
                  <i className="material-icons-outlined">schedule</i>
                  <span>
                    {assignmentTaskDetails.duration} {t("mins")}
                  </span>
                </div>
              ) : null}

              {assignmentTaskId ? (
                <>
                  {assignmentTaskDetails.approvaltaskstatus == "1" ? (
                    <div className="time_rounded text-success">
                      <i className="material-icons-outlined">done</i>
                      <span>{t("Approved")}</span>
                    </div>
                  ) : (
                    <>
                      {assignmentTaskDetails.deliverytaskstatus == "1" ? (
                        <div className="time_rounded">
                          <i className="material-icons-outlined">done</i>
                          <span>{t("Submitted")}</span>
                        </div>
                      ) : (
                        <>
                          {assignmentTaskDetails.assignedtaskstatus == "1" ? (
                            <div className="time_rounded">
                              <i className="material-icons-outlined">done</i>
                              <span>{t("Assigned")}</span>
                            </div>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </div>

            {/* ---- progress bar of task start ------ */}
            {assignmentTaskId ? (
              <div className="question_success_hdng">
                {userInfo._id?.toString() ==
                  props.tasks?.moderatorid?.toString() ||
                userInfo.role.slug === "SUPER_ADMIN" ||
                userInfo.role.slug === "ADMIN" ||
                isCourseUploader ? null : (
                  <div className="step_list">
                    <ul>
                      <li
                        className={
                          assignmentTaskDetails.assignedtaskstatus == "1"
                            ? "active"
                            : ""
                        }
                      >
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>{t("Start Task")}</em>
                      </li>
                      <li
                        className={
                          assignmentTaskDetails.deliveryassignedtaskstatus ==
                          "1"
                            ? "active"
                            : ""
                        }
                      >
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>{t("Submit on Completion")}</em>
                      </li>

                      {/* <li>
                          <span>
                            <i className="material-icons-outlined"> done</i>
                          </span>
                          <em>{t("Get score to get certified")}</em>
                        </li> */}

                      <li
                        className={
                          assignmentTaskDetails.approvalassignedtaskstatus ==
                          "1"
                            ? "active"
                            : ""
                        }
                      >
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>{t("Reviewed by Instructor")}</em>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ) : null}
            {/* ---- progress bar of task end ------ */}

            {/* --------- Assignment accept and cancel section starts here --------- */}
            {assignmentTaskId ? (
              <div className="mt-4">
                <div className="task_detailspara">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: assignmentTaskDetails.description,
                    }}
                  ></p>
                </div>

                {userInfo._id.toString() ===
                  props.tasks.moderatorid.toString() ||
                userInfo.role.slug === "SUPER_ADMIN" ||
                userInfo.role.slug === "ADMIN" ||
                isCourseUploader ? null : (
                  <div className="taskbtn_outer">
                    {existingAssignmentTaskId !== null ? (
                      <>
                        {assignmentTaskDetails.approvalassignedtaskstatus ===
                        "1" ? (
                          <Link to="#"></Link>
                        ) : (
                          <Link
                            to="#"
                            className="border_btn taskagain_btn"
                            onClick={toggleSubmitBlock}
                          >
                            {t("Submit task for review")}
                          </Link>
                        )}
                      </>
                    ) : (
                      <Link
                        to="#"
                        className="border_btn taskagain_btn"
                        onClick={accpetAssignment}
                        style={{
                          pointerEvents: isAcceptLoading ? "none" : "",
                        }}
                      >
                        {t("Accept")}
                        {isAcceptLoading && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </Link>
                    )}

                    <Link
                      to="#"
                      className="border_btn taskback_btn"
                      onClick={togglePost}
                    >
                      {t("Cancel")}
                    </Link>
                  </div>
                )}

                {reviewBlock && (
                  <div className="commnts_send_innr">
                    <div className="rating_hdng">
                      <h5>{t("Comments/Answer")}</h5>
                    </div>

                    <div className="commnts_sendArea">
                      <textarea
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder={t("Write your comments here")}
                      ></textarea>
                      {commentWarning && (
                        <p className="text-danger mb-1">
                          * {t("Please insert comment")}
                        </p>
                      )}
                    </div>

                    <div className="row">
                      {media.map((files, index) => {
                        return (
                          <div className="col-lg-3 col-md-4 col-6" key={index}>
                            <div className="chat_uploadItem">
                              <div className="chat_upload_pic">
                                {/* ---- creating file url for preview those files ----- */}
                                {files.type == "image/jpeg" ||
                                files.type == "image/png" ? (
                                  <img
                                    src={URL.createObjectURL(files)}
                                    alt="images"
                                  />
                                ) : null}
                                {files.type == "text/plain" ? (
                                  <img src={DOCS} alt="docs" />
                                ) : null}
                                {files.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                files.type == "application/msword" ||
                                files.type ==
                                  "application/vnd.oasis.opendocument.text" ||
                                files.type ==
                                  "application/vnd.ms-word.document.macroEnabled.12" ||
                                files.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                                files.type ==
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                  <img src={DOCS} alt="docs" />
                                ) : null}
                                {files.type == "application/pdf" ? (
                                  <img src={PDF} alt="pdf" />
                                ) : null}
                                {files.type ==
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                files.type == "application/vnd.ms-excel" ? (
                                  <img src={XLS} alt="docs" />
                                ) : null}
                                {files.type == "video/mp4" ? (
                                  <img src={VIDEO} alt="video" />
                                ) : null}
                                {files.type ==
                                "application/x-zip-compressed" ? (
                                  <img src={ZIP} alt="video" />
                                ) : null}
                              </div>
                              <div className="chat_upload_caption" key={index}>
                                <p>
                                  <span>{files.name}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/* ----- loading area for file uploading ----- */}
                    {isUploading && (
                      <div className="d-flex align-items-center justify-content-center mb-1">
                        <h6 className="text-dark fw-bold">
                          {t("Uploading Files")}
                        </h6>
                        {loadingCircle.map((count) => {
                          return (
                            <div
                              key={count}
                              className="spinner-grow mx-1 text-dark"
                              style={{ height: "6px", width: "6px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* ----- loading area for file uploading end----- */}

                    {/* ----- Uploading & Submit button starts here ----- */}
                    <div className="taskfile_area">
                      {/* ---------- Upload area ---------- */}
                      <div className="task_file_row">
                        <label>{t("Attach files")}</label>
                        <div className="attached_btn ylw_btn">
                          <input
                            type="file"
                            className="file_btn"
                            id="inputGroupFile03"
                            onChange={fileUploadHandler}
                            multiple
                          />
                          {t("Choose Files")}
                        </div>
                      </div>

                      {/* ---------- Submit button ---------- */}
                      <div
                        className="file_submitbtn"
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "",
                        }}
                      >
                        <Link
                          to="#"
                          className="border_btn taskagain_btn"
                          onClick={submitAssignMentHandler}
                          style={{
                            pointerEvents: isSubmitting ? "none" : "",
                          }}
                        >
                          {t("Submit")}
                        </Link>
                      </div>
                    </div>
                    {/* ----- Uploading & Submit button ends here ----- */}
                  </div>
                )}

                {existingAssignmentTaskId !== null ? (
                  <div className="task_status_outer">
                    <div className="taskstatus_item">
                      <h3>{t("Status")}</h3>
                      <h5>
                        {assignmentTaskDetails.approvaltaskstatus == 1 ? (
                          `${t("Approved")}`
                        ) : (
                          <span>
                            {assignmentTaskDetails.deliverytaskstatus == 1 ? (
                              `${t("Submitted")}`
                            ) : (
                              <span>
                                {assignmentTaskDetails.assignedtaskstatus == 1
                                  ? `${t("Assigned")}`
                                  : null}
                              </span>
                            )}
                          </span>
                        )}
                      </h5>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Started")}</h3>
                      <h4>
                        {assignmentTaskDetails.assignedtaskstatus == 1
                          ? assignmentTaskDetails.assignedtaskdate
                          : "-"}
                      </h4>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Submitted")}</h3>
                      <h4>
                        {assignmentTaskDetails.deliveryassignedtaskstatus == 1
                          ? assignmentTaskDetails.deliverttaskdate
                          : "-"}
                      </h4>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Reviewed")}</h3>
                      <h4>
                        {assignmentTaskDetails.approvalassignedtaskstatus == 1
                          ? assignmentTaskDetails.approvaltaskdate
                          : "-"}
                      </h4>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Audited By")}</h3>
                      <h4>{assignmentTaskDetails.approvaltaskauditor}</h4>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* --------- Assignment accept and cancel section ends here --------- */}
          </div>
          {/*----- assignment details end here ------*/}

          {assignmentTaskId ? (
            <>
              {assignmentTaskId != null ? (
                <>
                  {/* --------------- uploaded files attachments --------------- */}

                  {assignmentTaskId.taskattachments != null &&
                  assignmentTaskId.taskattachments.length > 0 ? (
                    <div className="attachment_area">
                      <div className="attach_hdng">
                        <h3>{t("Attachments")}</h3>
                      </div>
                      <div className="attachemnet_bxOuter">
                        <div className="row">
                          {assignmentTaskId.taskattachments.map(
                            (files, index) => {
                              return (
                                <div className="col-lg-3 col-6" key={index}>
                                  <div className="attachment_item">
                                    <div className="attahdmnt_text">
                                      <h5>
                                        {files.substring(
                                          files.lastIndexOf("/") + 1,
                                          files.length
                                        )}
                                      </h5>
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.open(files, "_blank");
                                        }}
                                      >
                                        <span className="material-icons-outlined">
                                          file_download
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* --------------- end of uploaded files attachments ------------- */}

                  {/* ---------------- comments --------------- */}
                  {commentList.length > 0 ? (
                    <div className="task_activity_area">
                      <div className="attach_hdng">
                        <h3>{t("Activity")}</h3>
                      </div>
                      <div className="task_tableInnr">
                        <table>
                          <thead>
                            <tr>
                              <th>{t("Comments")}</th>
                              <th>{t("Name")}</th>
                              <th>{t("Date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {commentList.map((comments, index) => {
                              return (
                                <tr key={index}>
                                  <td>{comments.description}</td>
                                  <td>
                                    <div className="task_tableuser">
                                      <div className="task_usr">
                                        <img
                                          src={
                                            comments.userimage !== ""
                                              ? url.SERVER_URL +
                                                comments.userimage
                                              : defaultUser
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="task_usr_text">
                                        <span>{comments.user}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{comments.startdate}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}

                  {/* ---------- end comments ------------------- */}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={blankLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>{t("Sorry....!")}</h2>
                <p>{t("Assignments are not available for this lesson.")}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <AssignmentAcceptModal
        isSubmit={isSubmit}
        afterModalClose={afterModalClose}
      />
    </div>
  );
};

export default LessonTask;

/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getData,
  postData,
  postData_v2,
  putData_v2,
  uploadMultipleFile,
  uploadMultipleFile_v2,
} from "utils/Gateway"; // import methods from gatway for API call
import * as url from "helpers/url_helper"; // import all URLs from url_helper

import MessagePanel from "./MessagePanel/MessagePanel";
import GroupInfoPanel from "./GroupInfoPanel/GroupInfoPanel";

import {
  generateRandomString,
  languageList,
} from "helpers/Common/CommonHelper";
import downloadFileHandler from "helpers/downloadfile";
import ChatMessagePlaceholder from "../PlaceHolder/ChatMessagePlaceholder";
import ChatHistoryComponent from "components/ConversationComponents/Modals/ChatHistoryModal";
import AddToGroupModal from "components/ConversationComponents/Modals/AddToGroupModal";

const GroupPanel = ({ coversationData }) => {
  const {
    selectedChatRoomId,
    selectedChatEmail,
    selectedConversationId,
    socket,
    setReloadChatPanelList,
    allUserContacts,
    setAfterLeaveGroup,
    setisUnreadActive,
  } = coversationData;

  const { t } = useTranslation();

  // assign useref hook for auto scrolling
  const messagesEndRef = useRef(null);
  const chatInputRef = useRef(null);
  const typingTimeoutRef = useRef(null);

  const mobileCloseChatHandler = () => {
    document.body.classList.toggle("chatmsg_open");
  };

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //search message requirements
  const [displaySearchPanel, setdisplaySearchPanel] = useState(false);
  const [searchMessage, setsearchMessage] = useState("");

  const [isGroupDeatilsLoading, setisGroupDeatilsLoading] = useState(false);

  const [groupTitle, setgroupTitle] = useState("");
  const [groupLogoImage, setgroupLogoImage] = useState("");
  const [groupMemberList, setgroupMemberList] = useState([]);
  const [groupFileList, setgroupFileList] = useState([]);
  const [groupMemberNames, setgroupMemberNames] = useState("");
  const [groupCreatedTime, setgroupCreatedTime] = useState("");
  const [groupModId, setgroupModId] = useState(null);
  const [groupModName, setgroupModName] = useState("");
  const [groupModImage, setgroupModImage] = useState("");

  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  const [selectedLanguageObj, setselectedLanguageObj] = useState(null);

  const [flaggedLanguage, setFlaggedLanguage] = useState("");
  const [messageText, setMessageText] = useState("");
  const [modalMessageText, setmodalMessageText] = useState(""); //for upload file modal

  const [messageIndex, setmessageIndex] = useState(null);
  const [messageUpdating, setmessageUpdating] = useState(false);

  const [parentMessageId, setparentMessageId] = useState(null);
  const [replyUserName, setreplyUserName] = useState("");
  const [replyMessage, setreplyMessage] = useState("");

  const [isUploading, setisUploading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [messageWarning, setmessageWarning] = useState(false);
  const [fileIsBig, setFileIsBig] = useState(false);

  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [uploadedFileIds, setuploadedFileIds] = useState([]);

  // start typing --
  const [isTyping, setIsTyping] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);

  const [showHistory, setShowHistory] = useState(false);

  const getGroupDetails = async () => {
    try {
      setisGroupDeatilsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${selectedChatRoomId}?token=${token}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log("response in group details ---->", response);

      setisGroupDeatilsLoading(false);

      if (response.status) {
        setgroupTitle(response.data.title);
        setgroupLogoImage(response.data.grouplogoimage);
        setgroupMemberList(response.data.groupmemberlist);
        setgroupFileList(response.data.uploads);
        setgroupMemberNames(response.data.groupmemberstring);
        setgroupCreatedTime(response.data.createdtimestring);
        setgroupModId(response.data.groupmodertorid);
        setgroupModName(response.data.moderatorname);
        setgroupModImage(response.data.moderatorimage);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all message
  const getAllChatMessages = async (isLoading = false) => {
    try {
      isLoading && setIsMessageLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHAT_MESSAGES +
        `?token=${token}&chatgroupid=${selectedConversationId}`;

      if (searchMessage !== "") {
        requestUrl += `&searchmessage=${searchMessage}`;
      }

      const response = await getData(requestUrl);

      setIsMessageLoading(false);

      console.log("response in chat panel ---->", response);

      if (response.status) {
        setChatMessages(response.data);
        setsearchMessage("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for scroll chat bottom behavior
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  //function upload multiple files
  const fileUploadHandler = async (e) => {
    try {
      setFileIsBig(false);

      setisUploading(true);

      const files = e.target.files;

      setUploadedFileCount(files.length);

      let fileProceed = false;

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let f = files[i];

          if (f.size <= 12500000) {
            fileProceed = true;
          } else {
            fileProceed = false;
          }
        }
      }

      if (fileProceed) {
        const requestUrl =
          url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

        const response = await uploadMultipleFile_v2(requestUrl, files);

        setisUploading(false);

        console.log(response);

        if (response.status) {
          //check old file exist or not if exist then new file will concat with old file
          if (uploadedFiles.length == 0) {
            setuploadedFiles(response.data);
          } else {
            let fileArr = uploadedFiles.concat(response.data);
            setuploadedFiles(fileArr);
          }
          /*----- assign uploaded files with path for display end ------*/

          /*----- assign uploaded file ids start ------*/
          const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

          //check old file ids are exist or not.If exist then new id will concat with old id
          if (uploadedFileIds.length == 0) {
            setuploadedFileIds(fileIds);
          } else {
            let fileArr = uploadedFileIds.concat(fileIds);
            setuploadedFileIds(fileArr);
          }
          /*----- assign uploaded file ids end ------*/
          resetFile();
        }
      } else {
        setFileIsBig(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    setUploadedFileCount(0);
    const file = document.getElementById("uploadConversationFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setuploadedFiles(closedFile);
    setuploadedFileIds(removedFileId);

    resetFile();
  };

  //function for send message
  const sendMessageHandler = async () => {
    if (selectedConversationId && messageText !== "") {
      setsearchMessage("");

      setmessageUpdating(true);

      setisUnreadActive(false);
      document.title = "Conversation"; // Reset to default title

      const groupMemberArr = groupMemberList.map(
        (member) => member.groupmemberid
      );

      const messageReceiverIds = [...groupMemberArr, groupModId];

      const filterMessageReceiverIds = messageReceiverIds.filter(
        (member) => member.toString() !== userInfo._id.toString()
      );

      const messageObj = {
        _id: null,
        group: selectedChatRoomId,
        sender: userInfo._id,
        receiver: null,
        parent: parentMessageId,
        sendermail: userInfo.email,
        receivermail: "",
        description: messageText,
        flaggeddescription: messageText,
        flaggedlanguage: flaggedLanguage,
        moduleslug: "MOD_GROUP",
        uploads: uploadedFileIds,
        uploadedchatfiles: uploadedFiles,
        component: "",
        componenttitle: "",
        sendername: `${userInfo.name ?? userInfo.email} ${
          userInfo.surname ?? ""
        }`,
        senderimage: userInfo.photoimage ? `${userInfo.photoimage?.path}` : "",
        updatedAt: Date.now(),
        createdAt: Date.now(),
        parentchatmessage: replyMessage,
        parentchatsender: replyUserName,
      };

      console.log(messageObj);

      setChatMessages((prevMessages) => [...prevMessages, messageObj]);

      try {
        let messageData = {
          group: selectedChatRoomId,
          sender: userInfo._id,
          receiver: null,
          parent: parentMessageId ?? null,
          sendermail: userInfo.email,
          receivermail: "",
          description: messageText,
          moduleslug: "MOD_GROUP",
          uploads: uploadedFileIds ?? [],
          component: "",
          componenttitle: "",
          sendername: `${userInfo.name ?? userInfo.email} ${
            userInfo.surname ?? ""
          }`,
          senderimage: userInfo.photoimage
            ? `${userInfo.photoimage?.path}`
            : "",
          flaggedlanguage: flaggedLanguage,
          parentchatmessage: replyMessage,
          parentchatsender: replyUserName,
          uploadedchatfiles: uploadedFiles,
        };

        // console.log(messageData);

        resetChatBox();

        let requestUrl =
          url.API_BASE_URL + url.API_SEND_CHAT_MESSAGE + `?token=${token}`;

        const response = await postData_v2(requestUrl, messageData);

        console.log(response);

        if (response.status) {
          setChatMessages((prevMessages) =>
            prevMessages.map((msg) => (msg._id === null ? response.data : msg))
          );

          setReloadChatPanelList(true);

          socket.emit("sendmessage", {
            chatroomid: selectedChatRoomId,
            messageData: response.data,
            receiverlist: filterMessageReceiverIds,
          });

          setIsTyping(false);

          socket.emit("stoptyping", {
            chatroomid: selectedChatRoomId,
            senderid: userInfo._id,
            senderlabel: userInfo.name ?? userInfo.email,
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //update chat
  const updateChatMessageRequireResponse = async (chatId = null) => {
    try {
      setmessageUpdating(true);

      let chatData = {
        requireresponse: true,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_UPDATE_CHAT + `/${chatId}?token=${token}`;

      const response = await putData_v2(requestUrl, chatData);

      console.log("response", response);

      setmessageUpdating(false);

      if (response.status) {
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //update chat status
  const updateChatStatusHandler = async () => {
    setisUnreadActive(false);
    document.title = "Conversation"; // Reset to default title

    try {
      const groupMemberArr = groupMemberList.map(
        (member) => member.groupmemberid
      );

      const messageReceiverIds = [...groupMemberArr, groupModId];

      const filterMessageReceiverIds = messageReceiverIds.filter(
        (member) => member.toString() !== userInfo._id.toString()
      );

      let chatStatusData = {
        group: selectedChatRoomId,
        contactid: null,
        personid: null,
      };

      // console.log(chatStatusData);

      let requestURL =
        url.API_BASE_URL + url.API_UPDATE_TRACKERS + `?token=${token}`;

      const response = await putData_v2(requestURL, chatStatusData);

      console.log(response);

      if (response.status) {
        socket.emit("refreshafterupdatestatus", {
          chatroomid: selectedChatRoomId,
          receiverlist: filterMessageReceiverIds,
        });
        setReloadChatPanelList(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //delete chat
  const deleteChatHandler = async (chatId = null) => {
    if (chatId) {
      try {
        const groupMemberArr = groupMemberList.map(
          (member) => member.groupmemberid
        );

        const messageReceiverIds = [...groupMemberArr, groupModId];

        const filterMessageReceiverIds = messageReceiverIds.filter(
          (member) => member.toString() !== userInfo._id.toString()
        );

        let messageData = {
          contactid: selectedChatRoomId,
          constactperson: selectedConversationId,
        };

        socket.emit("deletemessage", {
          chatroomid: selectedChatRoomId,
          messageId: chatId,
          receiverlist: filterMessageReceiverIds,
        });

        let requestUrl =
          url.API_BASE_URL + url.API_DELETE_CHAT + `/${chatId}?token=${token}`;

        const response = await putData_v2(requestUrl, messageData);

        setChatMessages((prevMessages) =>
          prevMessages.filter((msg) => msg._id !== chatId)
        );

        console.log(response);

        if (response.status) {
          setReloadChatPanelList(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // paste image from clipboard
  const pasteFileHandler = async (e) => {
    const items = e.clipboardData.items;

    // console.log("items", items);

    const files = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        if (file) {
          const newFileName = `IMG_${generateRandomString(2)}_${file.name}`;
          const renamedFile = new File([file], newFileName, {
            type: file.type,
          });
          files.push(renamedFile);
        }
      }
    }

    console.log("files", files);

    try {
      if (files.length > 0) {
        const requestUrl =
          url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

        const response = await uploadMultipleFile_v2(requestUrl, files);

        console.log("handle paste file response", response);

        if (response.status) {
          //check old file exist or not if exist then new file will concat with old file
          if (uploadedFiles.length == 0) {
            setuploadedFiles(response.data);
          } else {
            let fileArr = uploadedFiles.concat(response.data);
            setuploadedFiles(fileArr);
          }
          /*----- assign uploaded files with path for display end ------*/

          /*----- assign uploaded file ids start ------*/
          const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

          //check old file ids are exist or not.If exist then new id will concat with old id
          if (uploadedFileIds.length == 0) {
            setuploadedFileIds(fileIds);
          } else {
            let fileArr = uploadedFileIds.concat(fileIds);
            setuploadedFileIds(fileArr);
          }
          /*----- assign uploaded file ids end ------*/
          resetFile();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendMessageByEnter = (e) => {
    if (e.key === "Enter") {
      sendMessageHandler();
    }
  };

  //reset chat box
  const resetChatBox = () => {
    setMessageText("");
    setmessageIndex(null);
    setuploadedFileIds([]);
    setuploadedFiles([]);
    resetReplyHandler();
    resetFile();
    setFileIsBig(false);
  };

  const resetReplyHandler = () => {
    setreplyMessage("");
    setreplyUserName("");
    setparentMessageId(null);
  };

  // typing event
  const typingHandler = () => {
    const typingInfo = {
      chatroomid: selectedChatRoomId,
      senderid: userInfo._id,
      senderlabel: userInfo.name ?? userInfo.email,
    };

    if (!isTyping) {
      setIsTyping(true);
      socket.emit("typing", typingInfo);
    }

    clearTimeout(typingTimeoutRef.current);

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      socket.emit("stoptyping", typingInfo);
    }, 3000); // Adjust the delay as needed
  };

  //function for search messages
  const searchMessageHandler = () => {
    getAllChatMessages(true);
    setdisplaySearchPanel(false);
  };

  const searchMessageByEnter = (e) => {
    if (e.key === "Enter") {
      searchMessageHandler();
    }
  };

  useEffect(() => {
    if (selectedConversationId) {
      getGroupDetails();
      getAllChatMessages(true);
    }
  }, [selectedConversationId]);

  useEffect(() => {
    if (typingUsers) {
      scrollToBottom();
    }
  }, [typingUsers]);

  // after new chat message added scroll to bottom
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    // Join the chatroom
    socket.emit("joinchat", selectedChatRoomId); // Replace chatroomid with the actual room ID

    socket.on("incomingmessage", (data) => {
      if (data) {
        console.log(data);
        setChatMessages((prevMessages) => [...prevMessages, data]);
      }
    });

    socket.on("incomingreplacemessage", (data) => {
      if (data) {
        console.log(data);
        setChatMessages((prevMessages) =>
          prevMessages.filter((msg) => msg._id !== data)
        );
      }
    });

    socket.on("usertyping", ({ senderid, senderlabel }) => {
      setTypingUsers((prevUsers) => {
        if (!prevUsers.some((user) => user.id === senderid)) {
          return [...prevUsers, { id: senderid, label: senderlabel }];
        }
        return prevUsers;
      });
    });

    socket.on("userstoptyping", ({ senderid, senderlabel }) => {
      setTypingUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== senderid)
      );
    });

    return () => {
      if (selectedChatRoomId) {
        setmessageIndex(null);
        socket.off("usertyping");
        socket.off("userstoptyping");
        socket.emit("leavechat", selectedChatRoomId); // Leave the chatroom on component unmount
        socket.off("incomingmessage"); // Clean up event listener on unmount if needed
      }
    };
  }, [selectedChatRoomId, socket]);

  return (
    <div className="chat_rightpnl">
      <div className="chat_topPnl">
        {isGroupDeatilsLoading ? (
          <div className="chatusr_info">
            <Link
              to="#"
              className="chat_usrOuter profile_linkBtn placeholder-glow"
            >
              <div className="chat_usrPic">
                <img src="/images/default-user.png" alt="" />
              </div>
              <div
                className="chat_usrtext placeholder-glow"
                style={{ width: "100px" }}
              >
                <h3>
                  <span className="placeholder col-12"></span>
                </h3>
                <h5>
                  <span className="placeholder col-12"></span>
                </h5>
              </div>
            </Link>
          </div>
        ) : (
          <div className="chatusr_info">
            <div className="list_back">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.toggle("chatmsg_open");
                }}
              >
                <i className="material-icons-round"> arrow_back </i>
              </Link>
            </div>

            <Link
              to="#"
              className="chat_usrOuter profile_linkBtn"
              onClick={() => {
                document.body.classList.add("profile_menuPnl_open");
              }}
            >
              {groupLogoImage === "" ? (
                <div className="chatting_user">
                  {groupMemberList.slice(0, 2).map((member, index2) => {
                    return (
                      <figure className="group_usr" key={index2}>
                        <img
                          src={
                            member.groupmemberimage === ""
                              ? "images/default-user.png"
                              : url.SERVER_URL + member.groupmemberimage
                          }
                          alt=""
                        />
                      </figure>
                    );
                  })}

                  {groupMemberList.length > 2 ? (
                    <span className="group_usr plus_usr">
                      {groupMemberList.length - 2} <em>+</em>
                    </span>
                  ) : null}
                </div>
              ) : (
                <div className="chat_usrPic">
                  <img src={url.SERVER_URL + groupLogoImage} alt="" />
                </div>
              )}

              <div className="chat_usrtext">
                <h3>{groupTitle}</h3>
                <h5>{groupMemberNames}</h5>
              </div>
            </Link>
            <div className="info_icons">
              <ul>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      getAllChatMessages(true);
                    }}
                  >
                    <i className="material-icons-round">refresh</i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="srch_Btn_icon"
                    onClick={() => {
                      setdisplaySearchPanel(!displaySearchPanel);
                      setsearchMessage("");
                    }}
                  >
                    <i className="material-icons-round">search</i>
                  </Link>
                </li>
                {groupModId &&
                groupModId.toString() === userInfo._id.toString() ? (
                  <li>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#add_to_group_people_mdl"
                    >
                      <i className="material-icons-round">person_add_alt</i>
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#chathistorymodal"
                    onClick={() => setShowHistory(true)}
                  >
                    <i className="material-icons-round">history</i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div
          className="serch_pnl"
          style={{
            display: displaySearchPanel ? "block" : "none",
          }}
        >
          <div className="srch_innrFill">
            <input
              type="text"
              className="form-control"
              placeholder="Search for conversation"
              value={searchMessage}
              onChange={(e) => {
                setsearchMessage(e.target.value);
              }}
              onKeyDown={searchMessageByEnter}
            />
            <button
              className="srch_submitBtn"
              type="button"
              onClick={searchMessageHandler}
            >
              <i className="material-icons-round"> send</i>
            </button>
          </div>
        </div>
      </div>

      <div className="chating_mdlPnl grp_chat_Innr">
        <div className="chatting_innr pd_top_60">
          {isMessageLoading ? (
            <div className="msg_outer msg_shareicon_info">
              <ChatMessagePlaceholder />
            </div>
          ) : chatMessages.length > 0 ? (
            <MessagePanel
              chatInputRef={chatInputRef}
              isMessageLoading={isMessageLoading}
              image={groupLogoImage}
              conversationName={groupTitle}
              chatMessages={chatMessages}
              messagesEndRef={messagesEndRef}
              setreplyMessage={setreplyMessage}
              setreplyUserName={setreplyUserName}
              setparentMessageId={setparentMessageId}
              messageUpdating={messageUpdating}
              messageIndex={messageIndex}
              setmessageIndex={setmessageIndex}
              updateChatMessageRequireResponse={
                updateChatMessageRequireResponse
              }
              deleteChatHandler={deleteChatHandler}
              typingUsers={typingUsers}
            />
          ) : (
            <div className="nothing_pic">
              <figure>
                <img src="/images/nothing.png" alt="" />
              </figure>
              {searchMessage !== "" ? (
                <div className="nothing_caption">
                  <p>Oops! messages not found</p>
                </div>
              ) : (
                <div className="added_text">
                  <h5>{groupCreatedTime}</h5>
                  {groupMemberList.slice(0, 3).map((member, index) => {
                    return (
                      <p key={index}>
                        {groupModName} Added {member.groupmembername} to this
                        Group
                      </p>
                    );
                  })}
                  <p>....</p>
                </div>
              )}
            </div>
          )}
        </div>

        {parentMessageId || uploadedFiles.length > 0 || isUploading ? (
          <div className="reply_upload_wrapper">
            <div className="upload_files_wrapper p-3">
              {/* <!-- ========== Start loading Section ========== --> */}
              {isUploading ? (
                <div className="uploading_file_counting d-flex align-items-center gap-2">
                  <div className="upload_icon_text d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">
                      arrow_upload_ready
                    </i>
                    <span>Uploading...</span>
                  </div>
                  <h5>{uploadedFileCount} files</h5>
                </div>
              ) : null}
              {/* <!-- ========== End loading Section ========== --> */}

              {/* <!-- ========== Start uploaded file Section ========== --> */}
              {uploadedFiles.length > 0 ? (
                <div className="upload_innrBody">
                  <div className="upload_outer">
                    <div className="row">
                      {uploadedFiles.map((file, index) => {
                        const fileExtension = file.path.split(".").pop();

                        const fileObj = {
                          ...file,
                          path: url.SERVER_URL + file.path,
                        };

                        return (
                          <div className="col" key={index}>
                            <div className="chat_uploadItem">
                              <div
                                className="chat_upload_pic"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  downloadFileHandler(fileObj);
                                }}
                              >
                                {fileExtension === "jpg" ||
                                fileExtension === "jpeg" ||
                                fileExtension === "png" ? (
                                  <img src={fileObj.path} alt="" />
                                ) : fileExtension === "pdf" ? (
                                  <img src={"/images/pdf.svg"} alt="" />
                                ) : fileExtension === "xlsx" ||
                                  fileExtension === "xlsm" ||
                                  fileExtension === "xlsb" ||
                                  fileExtension === "xltx" ||
                                  fileExtension === "xls" ? (
                                  <img src={"/images/xls.svg"} alt="" />
                                ) : (
                                  <img src={"/images/docs.svg"} alt="" />
                                )}
                              </div>

                              <div className="chat_upload_caption">
                                <p>
                                  <span>{file.name}</span>
                                </p>
                                <Link
                                  to="#"
                                  className="upoad_cross"
                                  onClick={() => closeFileHandler(index)}
                                >
                                  <i className="material-icons-round">close</i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <!-- ========== End uploaded file Section ========== --> */}
            </div>

            {/* <!-- ========== Start reply Section ========== --> */}
            {parentMessageId ? (
              <div className="reply_popup_area">
                <div className="reply_popupmsg">
                  <h5>{replyUserName}</h5>
                  <p>{replyMessage}</p>
                </div>
                <div className="reply_cross">
                  <Link to="#" onClick={resetReplyHandler}>
                    <i className="material-icons-round">close</i>
                  </Link>
                </div>
              </div>
            ) : null}
            {/* <!-- ========== End reply Section ========== --> */}
          </div>
        ) : null}
      </div>

      {/* <!-- ========== Start send message Section ========== --> */}

      <div className="chat_sendPnl">
        <div className="emoji_iconArea">
          <ul className="gap-2">
            <li>
              <a href="#" className="position-relative">
                <span className="material-icons-round">upload_file</span>
                <input
                  id="uploadConversationFile"
                  type="file"
                  className="upload_fileBtn"
                  multiple
                  onChange={fileUploadHandler}
                />
              </a>
            </li>
            <li className="dropdown">
              <Link
                to="#"
                className="d-flex align-items-center gap-1 dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                {selectedLanguageObj ? (
                  <i>
                    <img src={selectedLanguageObj.flagimageurl} alt="" />
                  </i>
                ) : (
                  <i className="material-icons-round fs-5">public</i>
                )}

                {selectedLanguageObj ? (
                  <span>{selectedLanguageObj.label}</span>
                ) : (
                  <span>LN</span>
                )}
              </Link>
              <ul className="dropdown-menu">
                {languageList.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1"
                        // data-bs-toggle="dropdown"
                        onClick={() => {
                          setselectedLanguageObj(item);
                          setFlaggedLanguage(item.value);
                        }}
                      >
                        <i>
                          <img src={item.flagimageurl} alt="" />
                        </i>
                        <span>{item.label}</span>
                      </Link>
                    </li>
                  );
                })}

                <li>
                  <Link
                    to="#"
                    className="d-flex align-items-center gap-1"
                    onClick={() => {
                      setselectedLanguageObj(null);
                      setFlaggedLanguage("");
                    }}
                  >
                    <i className="material-icons-round">close</i>
                    <span>Clear</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="send_textbx ms-3">
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              ref={chatInputRef}
              type="text"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              className="form-control"
              placeholder="Enter your message"
              onKeyDown={sendMessageByEnter}
              onClick={updateChatStatusHandler}
              onPaste={pasteFileHandler}
              onInput={typingHandler}
            />
            <button
              type="button"
              className="chat_send_btn"
              onClick={sendMessageHandler}
            >
              <span className="material-icons-round">send</span>
            </button>
          </form>
        </div>
      </div>

      {/* <!-- ========== End send message Section ========== --> */}

      <GroupInfoPanel
        selectedChatRoomId={selectedChatRoomId}
        conversationName={groupTitle}
        selectedChatLogo={groupLogoImage}
        groupModName={groupModName}
        groupModImage={groupModImage}
        groupMembers={groupMemberList}
        groupFiles={groupFileList}
        groupModId={groupModId}
        setAfterLeaveGroup={setAfterLeaveGroup}
      />

      <AddToGroupModal
        socket={socket}
        allUserContacts={allUserContacts}
        groupId={selectedChatRoomId}
        afterModalClose={getGroupDetails}
      />

      <ChatHistoryComponent
        showHistory={showHistory}
        isGroupChat={true}
        setShowHistory={setShowHistory}
        selectedChatRoomId={selectedConversationId} // here we send contact id instead of room id
        searchMessage={searchMessage}
      />
    </div>
  );
};

export default GroupPanel;

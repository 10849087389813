import React, { useEffect } from "react";

// importing custom components here
import Header from "common/Header";
import Breadcrumb from "components/BlogDetailsComponents/Breadcrumb";
import BlogContent from "components/BlogDetailsComponents/BlogContent";
import Footer from "common/Footer";

const BlogDetails = () => {
  return (
    <>
      <Header />
      <Breadcrumb />
      <section id="blog_details_wrapper" className="py-5">
        <div className="container">
          <BlogContent />
          {/* <BlogComment /> */}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogDetails;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const Details = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState("");
  const [jobSkills, setJobSkills] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL + url.API_VIEW_JOB_DETAILS + `/${id}?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.description) {
            setJobDetailsData(response.data.description);
          } else {
            setJobDetailsData("");
          }
          if (response.data.requireskillvalues) {
            setJobSkills(response.data.requireskillvalues);
          } else {
            setJobSkills([]);
          }
        } else {
          setJobDetailsData("");
          setJobSkills([]);
        }
        setIsLoading(false);
      } else {
        setJobDetailsData("");
        setJobSkills([]);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="course_detailspnl">
          <div className="results_innr">
            <div className="results_item">
              <div className="results_para">
                <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
              </div>
            </div>
            <div className="results_item">
              <span
                className="placeholder d-block rounded-2 col-1 my-3"
                style={{ height: "20px" }}
              ></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
            </div>
            <div className="results_item">
              <span
                className="placeholder d-block rounded-2 col-1 my-3"
                style={{ height: "20px" }}
              ></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
            </div>
          </div>
        </div>
      ) : (
        <div className="course_detailspnl">
          <div className="results_innr">
            {jobSkills.length === 0 && jobDetailsData == "" ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("Details are not available for this moment.")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="results_item">
                <div className="course_detailspnl">
                  <div className="contnt_hdng">
                    {jobSkills.length > 0 && <h4>{t("Skills")}</h4>}
                  </div>

                  {jobSkills.length === 0 ? null : (
                    <div className="experts_skill">
                      <ul>
                        {jobSkills.map((skill, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{skill.label}</Link>
                            </li>
                          );
                        })}
                        {/* {jobSkills.length > 5 ? (
                          <li className="d-flex align-items-center">
                            {" "}
                            + {jobSkills.length - 5} more
                          </li>
                        ) : null} */}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="course_detailspnl">
                  <div className="contnt_hdng">
                    {jobDetailsData && <h4>{t("Details")}</h4>}
                  </div>
                  <div
                    className="results_para"
                    dangerouslySetInnerHTML={{
                      __html: jobDetailsData,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Details;

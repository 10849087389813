/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GlobalIndraInfo } from "App";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import blog1 from "assets/BlogAssets/images/blog1.jpg";
import blog2 from "assets/BlogAssets/images/blog2.jpg";
import blog3 from "assets/BlogAssets/images/blog3.jpg";
import blog4 from "assets/BlogAssets/images/blog4.jpg";
import defaultUser from "assets/HomeAssets/Images/default-user.png";
import blankImage from "assets/BlogAssets/images/blog-2.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

import { useTranslation } from "react-i18next";
import { getAllCategories } from "helpers/Common/CommonHelper";

const BlogListArea = () => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const blogListRef = useRef(null);

  // array for counting glow rows
  const glowCount = [1, 2, 3];

  // for react multi carousel resposive properties
  const blogResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [categories, setCategories] = useState([]);
  const [blogLists, setBlogLists] = useState([]);
  const [page, setPage] = useState(0);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [categorySearch, setCategorySearch] = useState("");

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for blogref toggle
  const blogRefToggle = () => {
    blogListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // function for get all category for blog
  const getCategoryLists = async () => {
    try {
      const parentSlug = "CMS_BLOG";

      const response = await getAllCategories(parentSlug);

      if (response) {
        setCategories(response);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for get all blog list
  const getBlogLists = async () => {
    try {
      setIsLoading(true);
      let requestURL;

      requestURL =
        url.API_BASE_URL +
        url.API_BLOG_LISTS +
        `?modulefrom=frontend&start=${page * 10}&limit=10`;

      if (search != "") {
        setQuery(search);
        requestURL = requestURL + `&blogtitle=${search}`;
        setSearch("");
      }

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setBlogLists(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/
        } else {
          setBlogLists([]);
        }
      } else {
        setBlogLists([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const searchBlogByCategory = async (id) => {
    setSearch("");
    setQuery("");
    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_BLOG_LISTS +
        `?modulefrom=frontend&start=${page * 10}&limit=10`;

      if (id) {
        requestURL = requestURL + `&category=${id}`;
      }

      const response = await getData(requestURL);

      if (response.status) {
        setBlogLists(response.data);
        // set last page no from API
        if (response.totalpages != 1) {
          setLastPageNo(response.totalpages);
        }

        /*--- Creating dynamic array for pagination ----*/
        let pageArray = [];

        for (let i = 1; i <= response.totalpages; i++) {
          pageArray.push(i);
        }
        setTotalPages(pageArray);
        /*--- Creating dynamic array for pagination end ----*/
      } else {
        setBlogLists([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getBlogLists();
    getCategoryLists();
  }, [page]);

  return (
    <section className="blogs_area">
      <div ref={blogListRef} name="scrollBlogRefDiv" />
      <div className="container">
        {/* ----- search seaction start ----- */}
        <div className="blog_srch">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            placeholder={t("Search Blog...")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCategorySearch("");
                getBlogLists();
              }
            }}
          />
          <button
            type="submit"
            className="blog_srchbtn"
            onClick={() => {
              setCategorySearch("");
              getBlogLists();
            }}
          >
            <span className="material-icons-outlined">search</span>
          </button>
        </div>
        {/* ----- search section end ----- */}

        {/* ----- slick section start ----- */}
        <div className="blog_menuslideOuter">
          <div className="blog_menu_slider">
            <Carousel ssr responsive={blogResponsive} showDots={false}>
              {categories.map((item, index) => {
                return (
                  <div
                    className="blog_menu_item mb-1"
                    key={index}
                    onClick={() => {
                      searchBlogByCategory(item._id);
                      setCategorySearch(item.name);
                    }}
                  >
                    <Link to="#">{item.name}</Link>
                  </div>
                );
              })}
            </Carousel>
          </div>

          {/* <div className="inbtns"></div> */}
        </div>
        {/* ----- slick section end ----- */}

        {/* ----- title section start ----- */}
        {/* <div className="blog_hdngOuter">
            <div className="blog_hdng">
              <h2>Latest Blogs</h2>
            </div>
            <div className="blog_more_link">
              <Link to="#" className="border_btn">
                See All
              </Link>
            </div>
          </div> */}
        {/* ----- title section end ----- */}

        <div id="filter_result" className="mb-4">
          <div className="mt-3">
            <div className="d-flex flex-wrap gap-2">
              {/* ---------- Search Title Section starts here ---------- */}

              {query == "" ? null : (
                <div className="col-auto px-0">
                  <div className="result-box d-flex align-items-center gap-3 p-2 border">
                    <label htmlFor="name" className="fw-bold">
                      Query
                    </label>
                    {/* <input
                  type="text"
                  // value="Victor Herrero"
                  className="form-control shadow-none rounded-0"
                /> */}
                    <label>{query}</label>
                    <Link
                      to="#"
                      className="cancel"
                      onClick={() => {
                        setSearch("");
                        setQuery("");
                        getBlogLists();
                      }}
                    >
                      <i className="d-block material-icons">clear</i>
                    </Link>
                  </div>
                </div>
              )}

              {categorySearch == "" ? null : (
                <div className="col-auto px-0">
                  <div className="result-box d-flex align-items-center gap-3 p-2 border">
                    <label htmlFor="name" className="fw-bold">
                      Category
                    </label>
                    {/* <input
                  type="text"
                  // value="Victor Herrero"
                  className="form-control shadow-none rounded-0"
                /> */}
                    <label>{categorySearch}</label>
                    <Link
                      to="#"
                      className="cancel"
                      onClick={() => {
                        setCategorySearch("");
                        getBlogLists();
                      }}
                    >
                      <i className="d-block material-icons">clear</i>
                    </Link>
                  </div>
                </div>
              )}

              {/* <div className="col-auto px-0">
                  <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                    <label htmlFor="name" className="fw-bold">
                      Category
                    </label>
                    <ul className="badges d-flex flex-wrap gap-2">
                      <li className="d-flex gap-2 border">
                        Static
                        <Link to="#">
                          <i className="d-block material-icons">clear</i>
                        </Link>
                      </li>
                    </ul>
                    <Link to="#" className="cancel">
                      <i className="d-block material-icons">clear</i>
                    </Link>
                  </div>
                </div> */}
            </div>
          </div>
        </div>

        {/* ---- blog list start ---- */}
        {isLoading ? (
          <div className="blog_listArea">
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-4 col-sm-6" key={count}>
                    <div className="events_itemBx">
                      <div className="champions_pic">
                        <span
                          className="placeholder col-12"
                          style={{ height: "197px" }}
                        ></span>
                      </div>
                      <div className="champions_textpnl">
                        {/* <div className="events_dateBx">
                          <span className="placeholder col-12 h-100 mt-1"></span>
                        </div> */}
                        <div className="events_hdngauthor">
                          <span className="placeholder placeholder-md rounded-2 col-3"></span>
                          <span className="placeholder placeholder-md rounded-2 col-3"></span>
                        </div>
                        <div className="leftchampion_text">
                          <span
                            className="placeholder rounded-2 col-6 mt-2"
                            style={{ height: "20px" }}
                          ></span>
                        </div>
                        <div className="experts_skill w-100">
                          <ul>
                            <li>
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </li>
                            <li>
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </li>
                            <li>
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </li>
                          </ul>
                        </div>
                        <div className="details_btn w-100">
                          <span
                            className="placeholder rounded-3 col-12"
                            style={{ height: "50px" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="blog_listArea">
            <div>
              {/* ----- blog list section start ----- */}
              <div className="blogs-container">
                {blogLists.length == 0 ? (
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="empty_lessonsArea">
                        <div className="empty_lessonpic">
                          <img src={blankLesson} alt="blanklesson" />
                        </div>
                        <div className="empty_lessons_text">
                          <h2>{t("Sorry....!")}</h2>
                          <p>{t("Blogs are not available for this moment.")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {blogLists.map((blog, index) => {
                      return (
                        <div
                          className={
                            index === 3 || index === 4
                              ? // ||
                                // index === 8 ||
                                // index === 9
                                "col-md-6 mb-4 two-col"
                              : "col-lg-4 mb-4 three-col"
                          }
                          key={index}
                        >
                          <div className="blog p-3">
                            <div className="img-container mb-4">
                              <Link to="#">
                                <img
                                  src={
                                    blog.bannerimage != ""
                                      ? url.SERVER_URL + blog.bannerimage
                                      : blankImage
                                  }
                                  alt="blog_image"
                                  className="h-100 w-100"
                                />
                              </Link>
                            </div>
                            <div className="desc">
                              <h5 className="title fw-bold mb-3">
                                {blog.name !== ""
                                  ? blog.name.slice(0, 50)
                                  : "Undisclosed"}
                              </h5>

                              {blog.categorylist.length == 0 ? null : (
                                <ul className="tags d-flex flex-wrap gap-2 mb-3">
                                  {blog.categorylist.map((cat, index2) => {
                                    return (
                                      <li key={index2}>
                                        <Link to="#">{cat.name}</Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}

                              {blog.summary == "" ? null : (
                                <p>{blog.summary.slice(0, 150)}</p>
                              )}
                              <ul className="blog-meta d-flex align-items-center mt-4 mb-3">
                                <li>
                                  <Link
                                    to="#"
                                    className="author d-flex align-items-center"
                                  >
                                    <img
                                      src={
                                        blog.authorimage !== ""
                                          ? url.SERVER_URL + blog.authorimage
                                          : defaultUser
                                      }
                                      alt=""
                                      className="img-fluid rounded-circle me-1"
                                    />
                                    <span className="name">
                                      {blog.authorname}
                                    </span>
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to="#"
                                    className="date d-flex align-items-center"
                                  >
                                    <i className="material-icons d-block me-1">
                                      date_range
                                    </i>{" "}
                                    {blog.blogdate}
                                  </Link>
                                </li>
                              </ul>
                              <Link
                                to={`/blog/${blog.slug}`}
                                className="basic_btn ylw_btn"
                              >
                                {t("Read More")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* ------ pagination start ------ */}
                    <div className="pagination_list">
                      <ul>
                        <li>
                          <Link
                            to="#"
                            onClick={() => {
                              if (page !== 0) {
                                nextPage(page - 1);
                                blogRefToggle();
                              }
                            }}
                          >
                            <i className="material-icons-outlined">
                              arrow_back
                            </i>
                          </Link>
                        </li>
                        {totalPages.map((pageNo) => {
                          return (
                            <li
                              className={page + 1 === pageNo ? "active" : ""}
                              key={pageNo}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  nextPage(pageNo - 1);
                                  blogRefToggle();
                                }}
                              >
                                {pageNo}
                              </Link>
                            </li>
                          );
                        })}

                        <li>
                          <Link
                            to="#"
                            onClick={() => {
                              if (page !== lastPageNo - 1) {
                                nextPage(page + 1);
                                blogRefToggle();
                              }
                            }}
                          >
                            <i className="material-icons-outlined">
                              arrow_forward
                            </i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* ------ pagination end ------ */}
                  </div>
                )}
              </div>
              {/* ----- blog list section end ----- */}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogListArea;

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

import defaultUser from "assets/ActivityAssests/images/blank-champion.jpg";

const HomeChampionLists = () => {
  const token = localStorage.getItem("token");

  const dummyOneData = [
    {
      address: "",
      city: "",
      email: "",
      userimage: "",
      _id: "60d3b41abd7e4d9b9f3b8d58",
      position: "",
      image: "",
      name: "",
      surname: "",
      rating: "0",
      hideButton: true,
    },
    {
      address: "",
      city: "",
      email: "",
      userimage: "",
      _id: "60d3b41abd7e4d9b9f3b8d59",
      position: "",
      image: "",
      name: "",
      surname: "",
      rating: "0",
      hideButton: true,
    },
    {
      address: "",
      city: "",
      email: "",
      userimage: "",
      _id: "60d3b41abd7e4d9b9f3b8d5a",
      position: "",
      image: "",
      name: "",
      surname: "",
      rating: "0",
      hideButton: true,
    },
    {
      address: "",
      city: "",
      email: "",
      userimage: "",
      _id: "60d3b41abd7e4d9b9f3b8d5b",
      position: "",
      image: "",
      name: "",
      surname: "",
      rating: "0",
      hideButton: true,
    },
  ];

  const { t } = useTranslation();

  const champResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // for routing
  const history = useHistory();

  // array for counting glow rows
  const glowCount = [1, 2, 3, 4];

  //initial for champions lists
  const [championsLists, setChampionsLists] = useState([]);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  // function for all champion lists
  const getChampionsLists = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_CHAMPIONS +
      `?token=${token}&modulefrom=frontend&start=0&limit=10`;

    try {
      const response = await getData(requestURL);

      setIsLoading(false);

      console.log(response);

      if (response.status) {
        if (response.data) {
          if (response.data.length <= 3) {
            setChampionsLists([...response.data, ...dummyOneData]);
          } else {
            setChampionsLists(response.data.slice(0, 10));
          }
        } else {
          setChampionsLists([]);
        }
      } else {
        setChampionsLists([]);
      }
    } catch (error) {}
  };

  // function to go to the details page
  const goToChampionsDetails = (e, id) => {
    e.preventDefault();
    history.push(`/champions/details/${id}`);
  };

  // useEffect for page number
  useEffect(() => {
    getChampionsLists();
  }, []);

  return (
    <>
      <section className="champions_sec pd_30">
        <div className="container">
          {/* ----------- Heading section starts here ----------- */}
          <div className="slideHdng_outer">
            <div className="page_hdng">
              <h2>{t("Best")} Champions</h2>
            </div>
            <div className="see_moreBtn">
              <Link to="/champions" className="border_btn ylwbrdr_btn">
                {t("See All")}
              </Link>
            </div>
          </div>
          {/* ----------- Heading section starts here ----------- */}

          {/* ----------- Carousel section starts here ----------- */}
          {isLoading ? (
            <div className="champions_outer slide_Outer">
              {/* ------------ Glow placeholder section starts here ------------ */}
              <div className="champions_slider slider_innr">
                <Carousel ssr responsive={champResponsive}>
                  {glowCount.map((count) => {
                    return (
                      <div
                        className={
                          count == 1 ? "slide_Item mb-4" : "slide_Item"
                        }
                        key={count}
                      >
                        <div className="course_item">
                          <div className="champions_item">
                            <div className="champions_pic">
                              <span
                                className="placeholder d-block"
                                style={{ width: "290px", height: "253px" }}
                              ></span>
                            </div>
                            <div className="champions_textpnl">
                              <div className="champions_txtOuter">
                                <div className="leftchampion_text">
                                  <span
                                    className="placeholder rounded-2 mb-2"
                                    style={{ width: "134px", height: "20px" }}
                                  ></span>
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "94px", height: "16px" }}
                                  ></span>
                                </div>
                                <div className="chappions_rating">
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "40px", height: "24px" }}
                                  ></span>
                                </div>
                              </div>
                              <div className="details_btn">
                                <span
                                  className="placeholder d-block rounded-2 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              {/* ------------ Glow placeholder section ends here ------------ */}
            </div>
          ) : (
            <div className="champions_outer slide_Outer">
              {championsLists.length > 0 && (
                <div className="champions_slider slider_innr">
                  <Carousel ssr showDots responsive={champResponsive}>
                    {championsLists.map((champion, index) => {
                      return (
                        <div
                          className={
                            index == 0 ? "slide_Item mb-4" : "slide_Item"
                          }
                          key={index}
                        >
                          <div className="champions_item">
                            {champion.hideButton ? (
                              <div className="champions_pic">
                                <span
                                  className="placeholder d-block"
                                  style={{ width: "290px", height: "253px" }}
                                ></span>
                              </div>
                            ) : (
                              <div className="champions_pic">
                                <img
                                  src={
                                    champion.userimage === ""
                                      ? defaultUser
                                      : url.SERVER_URL + champion.userimage
                                  }
                                  alt="championPic"
                                />
                              </div>
                            )}
                            <div className="champions_textpnl">
                              <div className="champions_txtOuter">
                                {champion.hideButton ? (
                                  <div className="leftchampion_text">
                                    <span
                                      className="placeholder rounded-2 mb-5"
                                      style={{
                                        width: "134px",
                                        height: "20px",
                                      }}
                                    ></span>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "94px",
                                        height: "16px",
                                      }}
                                    ></span>
                                  </div>
                                ) : (
                                  <div className="leftchampion_text">
                                    <h3>
                                      {champion.name} {champion.surname}
                                    </h3>
                                    <h5>{champion.position}</h5>
                                  </div>
                                )}
                                {champion.hideButton ? (
                                  <div className="chappions_rating">
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "40px",
                                        height: "24px",
                                      }}
                                    ></span>
                                  </div>
                                ) : (
                                  <div className="chappions_rating">
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        star
                                      </i>
                                      <span>{champion.totalratings}</span>
                                    </Link>
                                  </div>
                                )}
                              </div>
                              {champion.hideButton ? (
                                <div className="details_btn">
                                  <span
                                    className="placeholder d-block rounded-2 col-12"
                                    style={{ height: "50px" }}
                                  ></span>
                                </div>
                              ) : (
                                <div className="details_btn">
                                  <Link
                                    to={`/champions/details/${champion._id}`}
                                    className="basic_btn ylw_btn"
                                  >
                                    {t("See Details")}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              )}
            </div>
          )}
          {/* ----------- Carousel section starts here ----------- */}
        </div>
      </section>
    </>
  );
};

export default HomeChampionLists;

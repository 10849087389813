import React from "react";
import Welcome from "./Welcome/Welcome";
import ContactPanel from "./ContactPanel/ContactPanel";
import GroupPanel from "./GroupPanel/GroupPanel";

const ConversationRightPanel = (props) => {
  const { defaultScreen, ...coversationData } = props;

  if (defaultScreen === "welcome") {
    return <Welcome />;
  } else if (defaultScreen === "contact") {
    return <ContactPanel coversationData={coversationData} />;
  } else if (defaultScreen === "group") {
    return <GroupPanel coversationData={coversationData} />;
  } else {
    return null;
  }
};

export default ConversationRightPanel;

import React from "react";

const ShowFaqImageModal = ({ displayImagePath, setdisplayImagePath }) => {
  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id="imageModal"
      tabindex="-1"
      aria-labelledby="imageModalLabel"
      data-bs-backdrop="static"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header p-4 pb-0">
            <h1 className="modal-title fs-5" id="imageModalLabel">
              Full Screen Image
            </h1>
            <button
              type="button"
              className="btn-close m-0 shadow-none"
              aria-label="Close"
              data-bs-dismiss="modal"
              onClick={() => {
                setdisplayImagePath("");
              }}
            ></button>
          </div>
          <div className="modal-body p-4">
            <img
              src={displayImagePath}
              alt="Full screen view"
              className="w-100"
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFaqImageModal;

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Gateway for API fetch call
import {
  getData,
  postData,
  postActivityFormData,
  uploadMultipleFile,
  postFormdata,
  postData_v2,
  putData_v2,
} from "utils/Gateway";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// importing context API here
import { GlobalIndraInfo } from "App";

const Forum = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const createPostPermission = userInfo.moduleaccessdata
    ? userInfo.moduleaccessdata.includes("PRV_ACTIVITY_CREATE")
    : false;

  const { isApproved, reloadForumTab, setReloadForumTab } =
    useContext(GlobalIndraInfo);

  const params = useParams();
  const isPrivate = true;
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(0);
  //initial for validation
  const [titleError, setTitleError] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [queryLists, setQueryLists] = useState([]);
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  // initial state for posting load
  const [isSubmiting, setIsSubmiting] = useState(false);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);

  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);
  const [forumReplyMessage, setForumReplyMessage] = useState("");
  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentIndex, setCommentIndex] = useState(null);

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BASE_URL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);

          setIsUploading(false);

          resetFile();

          if (response.status) {
            const uploadedFileValues = response.data.map(
              ({ name, path, _id, ...other }) => ({
                name,
                path: url.SERVER_URL + path,
                _id,
              })
            );

            const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

            //check old file exist or not if exist then new file will concat with old file
            if (media.length === 0) {
              setMedia(uploadedFileValues);
              setFileIds(fileIds);
            } else {
              setMedia([...media, ...uploadedFileValues]);
              setFileIds([...fileIds, ...fileIds]);
            }
          }
        } catch (error) {}
      } else {
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("eventForumFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // reset function for reset all fields
  const resetQuery = () => {
    setTitle("");
    setQuery("");
    resetFile();
    setFileIds([]);
    setMedia([]);
  };

  //---------------------- Function to submit the question --------------------
  const saveQuery = async () => {
    if (title == "" || query == "") {
      if (title == "") {
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (query == "") {
        setQueryError(true);
      } else {
        setQueryError(false);
      }
    } else {
      setTitleError(false);
      setQueryError(false);
      setIsSubmiting(true);

      let queryData = {
        isprivate: isPrivate,
        eventids: [params.id],
        title,
        description: `<div>${query}</div>`,
        uploads: fileIds,
      };

      let requestURL =
        url.API_BASE_URL + url.API_ACTIVITY_CREATE_POST + `?token=${token}`;

      try {
        const response = await postData_v2(requestURL, queryData);

        setIsSubmiting(false);

        console.log(response);

        if (response.status) {
          resetQuery();
          getQuery();
        }
      } catch (error) {}
    }
  };

  // function for get all forum lists
  const getQuery = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITIES_LIST +
      `?token=${token}&filterevents=${params.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setQueryLists(response.data);
        } else {
          setQueryLists([]);
        }
      } else {
        setQueryLists([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  // function for display question panel
  const toggleComment = (index) => {
    let x = document.getElementById(`forumeventcomment${index}`);
    if (x.style.display === "block") {
      x.style.display = "none";
      setCommentIndex(null);
    } else {
      x.style.display = "block";
      setCommentIndex(index);
    }
    setShowEmptyMessage(false);
    setForumReplyMessage("");
  };

  // function for like
  const forumLikeHandler = async (postId) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITY_LIKE_POST +
      `/${postId}?token=${token}`;

    try {
      const response = await putData_v2(requestURL);

      console.log(response);

      if (response.status) {
        getQuery();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `/${commentId}?token=${token}`;

    try {
      const response = await putData_v2(requestURL);

      if (response.status) {
        getQuery();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for post comment
  const replyToForum = async (postId, index) => {
    if (forumReplyMessage == "") {
      // alert("Comment message shouldn`t be empty");
      setShowEmptyMessage(true);
      return;
    } else {
      setShowEmptyMessage(false);

      let commentUrl =
        url.API_BASE_URL + url.API_ACTIVITY_POST_ANSWER + `?token=${token}`;

      let messageData = {
        forumid: postId,
        description: `<div>${forumReplyMessage}</div>`,
      };

      setForumReplyMessage("");

      try {
        const response = await postData_v2(commentUrl, messageData);

        if (response.status) {
          getQuery();
          toggleComment(index);
        }
      } catch (error) {}
    }
  };

  //reload this tab after join event
  useEffect(() => {
    if (reloadForumTab) {
      getQuery();
      setReloadForumTab(false);
    }
  }, [reloadForumTab]);

  useEffect(() => {
    if (params.id) {
      getQuery();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        // --------------------- Content starts here ---------------------
        <div className="course_detailspnl">
          <div className="contnt_hdng tab_Hdng">
            <span
              className="placeholder rounded-3 col-2"
              style={{ height: "22px" }}
            ></span>
          </div>
          <div className="form_commnts_area">
            <span
              className="placeholder rounded-3 col-12"
              style={{ height: "117px" }}
            ></span>
          </div>
          <div className="rply_bxArea">
            <div className="reply_forumBx forum_grayBx">
              <div className="forum_cmntsRow">
                <div className="share_btn forum_shreBtn">
                  <span
                    className="placeholder rounded-3"
                    style={{ width: "34px", height: "34px" }}
                  ></span>
                </div>
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-2 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm rounded-2 col-12 mb-2"></span>
                      <span className="placeholder placeholder-sm rounded-2 col-10 mb-2"></span>
                      <span className="placeholder placeholder-sm rounded-2 col-8 mb-2"></span>
                      <span className="placeholder placeholder-sm rounded-2 col-3"></span>
                    </div>
                    <div className="forumpic_outer">
                      <div className="forum_pic">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "130px", height: "86px" }}
                        ></span>
                      </div>
                      <div className="forum_pic">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "130px", height: "86px" }}
                        ></span>
                      </div>
                    </div>
                    <div className="form_share">
                      <span
                        className="placeholder rounded-3 me-3"
                        style={{ width: "97px", height: "38px" }}
                      ></span>
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "97px", height: "38px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="forum_cmntsRow">
                <div className="share_btn forum_shreBtn">
                  <span
                    className="placeholder rounded-3"
                    style={{ width: "34px", height: "34px" }}
                  ></span>
                </div>
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-2 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm rounded-2 col-12 mb-2"></span>
                      <span className="placeholder placeholder-sm rounded-2 col-4 mb-2"></span>
                    </div>
                    <div className="form_share">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "38px", height: "38px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // --------------------- Content starts here ---------------------
        <div className="course_detailspnl">
          {isApproved ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          userInfo.role.slug === "ADMIN" ? (
            <div>
              {createPostPermission ||
              userInfo.role.slug === "SUPER_ADMIN" ||
              userInfo.role.slug === "ADMIN" ? (
                <div className="forum-box">
                  {/* <div className="contnt_hdng tab_Hdng">
                    <h4>forum</h4>
                  </div> */}

                  <div className="activity_tagsRow">
                    <div className="reply_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Enter Title")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  {titleError && (
                    <p className="text-danger mb-2">
                      * {t("Please Fill up Title")}
                    </p>
                  )}

                  {/* ---------- Comments Textbox section starts here ---------- */}
                  <div className="form_commnts_area">
                    <textarea
                      className="form-control"
                      placeholder={t("Write your query here")}
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    ></textarea>
                  </div>
                  {queryError && (
                    <p className="text-danger mb-3">
                      * {t("Please Fill up Details")}
                    </p>
                  )}

                  <div className="activity_tagsRow d-flex mt-4">
                    {/* ----- this style is for temporary purpose please change it in CSS   ----- */}
                    {media.map((file, index) => {
                      const fileExtension = file.name.split(".").pop();
                      return (
                        <div key={index} className="uploaded-media">
                          {fileExtension === "jpg" ||
                          fileExtension === "png" ||
                          fileExtension === "jpeg" ? (
                            <img
                              src={file.path}
                              alt="ImageFile"
                              className="rounded"
                              height="150"
                              width="150"
                            />
                          ) : null}
                          {fileExtension === "doc" ? (
                            <img
                              src={DOCS}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="150"
                            />
                          ) : null}

                          {fileExtension === "pdf" ? (
                            <img
                              src={PDF}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="150"
                            />
                          ) : null}
                          {fileExtension === "xlsx" ? (
                            <img
                              src={XLS}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="150"
                            />
                          ) : fileExtension == "mp4" ||
                            fileExtension == "webm" ||
                            fileExtension == "ogv" ? (
                            <video controls width="150" height="150">
                              <source
                                src={file.path}
                                type={`video/${fileExtension}`}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : null}
                          <p className="text-center">
                            <span>{file.name}</span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="activity_tagsRow">
                    {/* ----- loading area for file uploading ----- */}
                    {isUploading && (
                      <div
                        className="d-flex align-items-center"
                        style={{ backgroundColor: "#044954" }}
                      >
                        <h6 className="text-white fw-bold">
                          {t("Uploading Files")}
                        </h6>
                        {spinCount.map((item) => {
                          return (
                            <div
                              key={item}
                              className="spinner-grow mx-1 text-white"
                              style={{ height: "6px", width: "6px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* ----- loading area for file uploading end ----- */}
                  </div>
                  {/* ---- file preview section end ---- */}
                  {fileIsBig ? (
                    <div className="activity_tagsRow">
                      <h6 className="text-white bg-danger">
                        * {t("Uploaded File Size must be lower than 10 mb")}
                      </h6>
                    </div>
                  ) : null}

                  {/* ----- file upload ------ */}
                  <div className="activity_tagsRow">
                    {/* <h4>{t("Attachments")}</h4> */}
                    <div className="reply_fill">
                      <input
                        type="file"
                        className="form-control"
                        id="eventForumFile"
                        onChange={multipleFileUploadHandler}
                        multiple
                      />
                    </div>
                  </div>

                  {isSubmiting && (
                    <div className="rounded mt-2">
                      <div className="alert alert-info">
                        {t("Submiting")}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {t("Your Question")}{" "}
                        </span>
                        {t("please wait")} ....
                      </div>
                    </div>
                  )}

                  <div className="question_btnArea">
                    <button
                      type="submit"
                      className="basic_btn"
                      disabled={isSubmiting ? true : false}
                      style={{
                        cursor: isSubmiting ? "not-allowed" : "pointer",
                      }}
                      onClick={saveQuery}
                    >
                      {t("Submit your query")}
                      {isSubmiting && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* ---------- Comments Textbox section ends here ---------- */}
          {/* ---------- Replies section starts here ---------- */}

          {!isApproved || queryLists.length === 0 ? (
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={blankLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>{t("Sorry....!")}</h2>
                {!isApproved ? (
                  <p>{t("You don't have privilege")}</p>
                ) : (
                  <p>
                    {queryLists.length === 0 && (
                      <span>{t("No forum post added yet for discussion")}</span>
                    )}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="rply_bxArea">
              <div className="reply_forumBx forum_grayBx">
                {queryLists.map((forum, index) => {
                  return (
                    <div className="forum_cmntsRow" key={index}>
                      <div className="team_userOuter forum_usrOuter">
                        <div className="team_usr_pic">
                          <img
                            src={
                              forum.moderatorimage !== ""
                                ? url.SERVER_URL + forum.moderatorimage
                                : defaultUser
                            }
                            alt="user"
                          />
                        </div>
                        <div className="team_usrTxt">
                          <h3>{forum.moderatorname}</h3>

                          <h5>
                            {forum.forumdate} {forum.forumtime}
                          </h5>
                          <div className="forum_para">
                            <h3>{forum.title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: forum.description,
                              }}
                            ></p>
                          </div>

                          <div className="forumpic_outer">
                            {forum.uploads.map((file, index2) => {
                              const fileExtension = file.name.split(".").pop();

                              return (
                                <div key={index2} className="forum_pic">
                                  {fileExtension === "jpg" ||
                                  fileExtension === "png" ||
                                  fileExtension === "jpeg" ? (
                                    <img
                                      src={url.SERVER_URL + file.path}
                                      alt="ImageFile"
                                      className="rounded"
                                    />
                                  ) : null}
                                  {fileExtension === "doc" ? (
                                    <img
                                      src={DOCS}
                                      alt="docs"
                                      className="rounded"
                                    />
                                  ) : null}

                                  {fileExtension === "pdf" ? (
                                    <img
                                      src={PDF}
                                      alt="docs"
                                      className="rounded"
                                    />
                                  ) : null}
                                  {fileExtension === "xlsx" ? (
                                    <img
                                      src={XLS}
                                      alt="docs"
                                      className="rounded"
                                    />
                                  ) : fileExtension == "mp4" ||
                                    fileExtension == "webm" ||
                                    fileExtension == "ogv" ? (
                                    <video controls width="150" height="150">
                                      <source
                                        src={url.SERVER_URL + file.path}
                                        type={`video/${fileExtension}`}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>

                          {/* ----- like comment area start --- */}
                          <div className="form_share">
                            <Link
                              to="#"
                              role="button"
                              onClick={() => forumLikeHandler(forum._id)}
                            >
                              <i className="material-icons-outlined">
                                thumb_up
                              </i>
                              <em>{t("Likes")}</em>
                              <span>{forum.likescount}</span>
                            </Link>
                            <Link
                              to="#"
                              className="recmnts_btn"
                              role="button"
                              onClick={() => {
                                toggleComment(index);
                              }}
                            >
                              <i className="material-icons-outlined">chat</i>
                              <em>{t("Comment")}</em>
                              <span>{forum.commentscount}</span>
                            </Link>
                          </div>
                          {/* ----- like comment area end --- */}

                          {/* ---- below comments area ---- */}
                          <div
                            className="recomments_area"
                            id={`forumeventcomment${index}`}
                          >
                            <div className="commnts_send_innr">
                              <div className="rating_hdng">
                                <h5>{t("Comment Now")}</h5>
                              </div>
                              <div className="commnts_sendArea">
                                <textarea
                                  className="form-control"
                                  placeholder={t("Write your query here")}
                                  value={forumReplyMessage}
                                  onChange={(e) =>
                                    setForumReplyMessage(e.target.value)
                                  }
                                ></textarea>
                                <div className="furmcmnt_btn">
                                  {/* <button className="cmnt_btn">
                                    <span className="material-icons-outlined">
                                      image
                                    </span>
                                  </button> */}
                                  <button
                                    className="cmnt_btn"
                                    onClick={() => {
                                      replyToForum(forum._id, index);
                                    }}
                                  >
                                    <span className="material-icons-outlined">
                                      send
                                    </span>
                                  </button>
                                </div>
                              </div>
                              {commentIndex == index ? (
                                <p className="text-danger mt-1 ">
                                  {" "}
                                  {showEmptyMessage && (
                                    <>* {t("Please add a comment")}</>
                                  )}
                                </p>
                              ) : null}
                            </div>

                            {/* ----- comments list section ---- */}
                            {forum.commentlist.map(
                              (commentElements, index2) => {
                                return (
                                  <div className="recomments_row" key={index2}>
                                    <div className="cmnts_user">
                                      <img
                                        src={
                                          commentElements.commentownerimage ===
                                          ""
                                            ? defaultUser
                                            : url.SERVER_URL +
                                              commentElements.commentownerimage
                                        }
                                        alt=""
                                        className="rounded-circle"
                                      />
                                    </div>
                                    <div className="cmnts_user_textpnl">
                                      <h3>
                                        {commentElements.commentownername}
                                      </h3>

                                      <h5>
                                        {commentElements.commentdate}{" "}
                                        {commentElements.commenttime}
                                      </h5>

                                      <div className="forum_para">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: commentElements.description,
                                          }}
                                        ></p>
                                      </div>
                                      <div className="form_share">
                                        <Link
                                          to="#"
                                          role="button"
                                          onClick={() =>
                                            likeCommentHandler(
                                              commentElements._id
                                            )
                                          }
                                        >
                                          <i className="material-icons-outlined">
                                            thumb_up
                                          </i>
                                          <em>{t("Likes")}</em>
                                          <span>
                                            {commentElements.likeids.length}
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}

                            {/* ----- comments list section end ---- */}
                          </div>
                          {/* ---- end of below comments ---- */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Forum;

import React, { useEffect } from "react";

// importing custom components here
import Header from "common/Header";
import Breadcrumb from "components/LegalComponents/Breadcrumb";
import LegalContent from "components/LegalComponents/LegalContent";
import Footer from "common/Footer";

const Legal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Breadcrumb />
      <section id="blog_details_wrapper" className="py-5">
        <div className="container">
          <LegalContent />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Legal;

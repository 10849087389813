import React from "react";
import { useHistory } from "react-router-dom";
import FaqDetailsComponent from "components/FaqComponents/FaqDetails/FaqDetails";
import { Link } from "react-router-dom";

import Header from "common/Header";
import Footer from "common/Footer";

const FaqDetails = () => {
  const history = useHistory();

  return (
    <>
      {/*----header ----*/}
      <Header />

      <section className="faq-details-wrapper py-5">
        <div className="container">
          <Link
            to="/faq"
            className="back d-inline-flex align-items-center gap-2 mb-4"
          >
            <span className="d-block material-icons-outlined">arrow_back</span>
            <span className="d-block">Back</span>
          </Link>

          <FaqDetailsComponent />
        </div>
      </section>

      {/* ---- footer ---- */}
      <Footer />
    </>
  );
};

export default FaqDetails;
